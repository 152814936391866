import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-size-h5 text-primary" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString($props.number), 1),
    _createElementVNode("span", { title: $props.fullName }, _toDisplayString($props.name) + " - " + _toDisplayString($props.fullName), 9, _hoisted_2)
  ], 64))
}