const MENU = {
  General: "عام",
  TextbookManagement: "ادارة الكتب الدراسية",
  Books: "الكتب",
  MANAGEMENTANDDISTRIBUTION: "الإدارة والتوزيع",
  DASHBOARD: "الرئيسية",
  Mail: "البريد",
  Chat: "شات",
  HumanResourceManagement: "ادارة الموارد البشرية",
  Employeesandmanagement: "الموظفين والإدارة",
  EmployeesArchives: "أرشيف الموظفين",
  Teachers: "المدرسين",
  TeachersArchives: "أرشيف المدرسين",
  Ticket: "الدعم الفني",
  StudentAffairsAdministration: "ادارة شئون الطلاب",
  Students: " الطلاب",
  Busmanagement: " ادارة الحافلات",
  lines: "الخطوط",
  Subscription: "الاشتراك",
  SchoolBooks: "الكتب الدراسية",
  library: "المكتبة",
  Booksections: "اقسام الكتب",
  Borrowbooks: "استعارة الكتب",
  Contentmanagement: " ادارة المحتوي",
  EducationalAdministration: "الادارة التعليمية",
  EducationaLevel: "المرحلة التعليمية",
  EducationalSubjects: "المناهج الدراسية",
  ClassSchedule: "جدول الحصص",
  Schedule: "الجدول",
  BalanceSheet: "الميزانية العمومية",
  SettingsSchedule: "اعدادت الجدول",
  MaterialSettings: "خطة المـواد الدراسـية",
  Teacherdistribution: "خطة توزيع المدرسين",
  ClassroomorLevels: "الفصول او الشعبة",
  Classroom: " الفصول او الشعب",
  ClassroomSettings: " اعدادات الفصول",
  Visits: "الزيارات",
  Accounts: "الحسابات",
  AccountsTree: "شجرة الحسابات",
  Ledger: "دفتر الاستاذ",
  TrialBalance: "ميزان المراجعة",
  IncomeAndExpenses: "ألايرادات والمصروفات",
  FinancialCenterList: "قائمة المركز المالي ",
  Budget: "الميزانية",
  Closingthefiscalyear: "اقفال السنة المالية",
  FixedAssets: "الاصول الثابتة",
  IncomeList: "قائمة الدخل",

  CurrentAssets: "الاصول المتداولة",
  CurrentLiabilities: "الخصوم المتداولة",
  Ownership: "حقوق الملكية",

  StudentMaterial: "المواد",

  FollowUp: "المتابعة",

  TotalGainsAndLossesRetandy: "اجمالى الارباح و الخسائر",

  Assets: "الاصول",
  AssetCategory: "تصنيف الأصول",
  AssetManagement: "ادارة الاصول ",
  Balanceoffixedassets: "رصيد الاصول الثابتة",
  Transaction: "القيود اليومية",
  Debentures: "السندات",
  PublicDebentures: "السندات العامة",
  FixedassetDebentures: "سندات الاصول الثابتة",
  Settings: "الاعدادات",
  Basicinformation: "البيانات الاساسية",
  Schooldata: "بيانات المدرسة",
  Educationalyear: "السنة التعليمية",
  Jobs: "الوظائف",
  TuitionFees: "المصاريف الدراسية",
  TuitionFeesSettings: "اعدادات المصاريف",
  Subjects: "المواد الدراسية",
  Privilegesandresponsibilities: "الصلاحيات والمسؤليات",
  Privileges: "الصلاحيات",
  Administration: "الادارة",
  GeneralSettings: "الاعدادات العامة",
  Filestoragelocations: "اماكن حفظ الملفات",
  Screensettings: "اعدادات الشاشة",
  TuitionFeesDistribution: "توزيع المصروفات",
  System: "النظام",
  Bugs: "الاخطاء ",
  Abouttherelease: "حول الاصدار",
  Developer: "المطور ",
  School: "المدرسة",
  PurchaseDate: "تاريخ الشراء",

  Quantity: "الكمية",
  UnitPrice: "سعر الوحدة",
  ConsumptionDuration: "مدة الاهلاك",
  MonthlyValue: "الاهلاك الشهرى",
  PhoneNumbers: "ارقام الهواتف",
  CallLogs: "سجل المكالمات",
  Addedrequests: "طلبات الاشتراك",
  Administrativeregion: "الاستقبال",
  Assetclassification: "تصنيف الاصول",
  Educationallevelsofstudyclasses: "المراحل التعليمية بالصفوف الدراسية",
  PAGES: "Pages",
  FEATURES: "Features",
  APPS: "Apps",
  Reports: "التقارير والنماذج",
  TeachersStatistics: "احصائيات المدرسين",
  StudentsStatistics: "احصائيات الطلاب",
  EmployeesStatistics: "احصائيات الموظفين",

  Invoices: "الفواتير",
  Pricing: "الاسعار والباقات",
  Subscriptions: "الاشتركات",
  SubscriptionsAndInvoices: "الاشتركات والفواتير",
  Upgrade: "رفع الاشتراك",
};
export default MENU;
