
import { defineComponent, computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "PageForm",
  props: {
    dynamicForm: Object,
    modelName: {
      type: String,
      required: true,
    },
    editName: {
      type: String,
      required: true,
    },
    // not used anymore
    afterSave: {
      type: String,
    },
    label: {
      type: String,
      required: false,
    },
    backTo: {
      type: String,
    },
    backAction: {
      type: Boolean,
    },
    CustomSubmit: {
      type: Boolean,
    },
    ObjectPath: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const ModelName = ref(props.modelName);
    const root = ModelName.value;
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      emit("onMountedBefore", model);
      // console.log(model.value);
      if (route.name == props.editName) {
        await GetEdit(route.params.Id);
      } else {
        await store.commit(`${root}/SetModel`, {});
      }
      emit("onMounted", model);
    });

    const loading = computed(() => store.getters[`${root}/GetLoading`]);

    const model = computed(() => store.getters[`${root}/GetModel`]);
    const GetEdit = async (val) => await store.dispatch(`${root}/GetEdit`, val);
    const AddOrEdit = async (value) =>
      await store.dispatch(`${root}/AddOrEdit`, value);

    async function onSubmitForm() {
      if (!props.CustomSubmit) {
        let res = await AddOrEdit(model.value);
        if (res != null && res.isValid) {
          await store.commit(`${root}/SetModel`, {});
          if (props.backTo) router.push(props.backTo);
        }
      } else {
        return emit("onSubmit");
      }
    }

    return {
      onSubmitForm,
      ModelName,
      loading,
      model,
    };
  },
});
