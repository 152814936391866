import { RouteRecordRaw } from "vue-router";

const employeeSales: RouteRecordRaw = {
  path: "employeeSales",
  component: () => import("@/views/reports/index.vue"),
  meta: {
    title: "       مبيعات الموظفين  ",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-employeeSales",
      component: () => import("@/views/reports/employeeSales/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "      مبيعات الموظفين ",
      },
    },
  ],
};

export default employeeSales;
