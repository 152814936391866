import { RouteRecordRaw } from "vue-router";
const AssetBalance: RouteRecordRaw = {
  path: "Balance",
  component: () => import("@/views/accounts/index.vue"),
  children: [
    {
      path: "",
      alias: "index",
      name: "Balance",
      component: () =>
        import("@/views/accounts/assets/assetsBalance/pages/index.vue"),
      meta: {
        pageId: 17020300,
        role: "view",
        title: "Balanceoffixedassets",
      },
    },
  ],
};

export default AssetBalance;
