import { App } from "vue";

const requireComponent = require.context(
  "@/core/directives/global",
  true,
  /.*\.ts$/
);

const register = (app: App<Element>): void => {
  requireComponent.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = requireComponent(fileName);
    // Get component name
    const componentName = fileName
      .split("/")
      .pop()
      ?.replace(/\.\w+$/, "") as string;

    // console.log("register component Name =>", componentName,fileName);
    app.directive(componentName, componentConfig.default || componentConfig);
  });
};

export default {
  register,
};
