<template>
  <div class="col-md-12 p-0">
    <div
      :class="[
        'card card-custom card-stretch gutter-b',
        isDeleted ? 'bg-danger-o-40' : '',
      ]"
    >
      <div class="card-body pt-5">
        <div class="row">
          <slot />
        </div>
      </div>

      <p
        v-if="isDeleted"
        class="mb-0 bg-danger text-white py-2 px-4 text-center"
      >
        تم حذف العنصر
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isDeleted: Boolean,
    default: () => false,
  },
};
</script>
