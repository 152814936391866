<template>
  <div>
    <span class="text-muted font-weight-bold">{{ title }}</span>
    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
      {{ value }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    value: String,
  },
};
</script>
