
export default {
  name: "wDateView",
  inheritAttrs: false,
  props: {
    time: [String, Number],
    format: {
      type: String,
      default: "yyyy-MM-dd hh:mm:ss",
    },
  },
};
