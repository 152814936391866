<template>
  <el-skeleton class="col-xl-3 col-md-3 col-sm-6 pb-10" animated>
    <template #template>
      <el-skeleton-item variant="image" style="width: 100%; height: 240px" />
      <div style="padding: 14px">
        <el-skeleton-item variant="p" style="width: 50%" />
        <div
          style="
            display: flex;
            align-items: center;
            justify-items: space-between;
          "
        >
          <el-skeleton-item variant="text" style="margin-right: 16px" />
          <el-skeleton-item variant="text" style="width: 30%" />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>
