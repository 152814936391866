
import { ref } from "vue";
export default {
  name: "wDateTimeFromTo",
  props: {
    value: {
      type: String,
      default: () => "",
    },
    className: {
      type: String,
      default: () => "mt-1 fw-bold fs-7 text-muted pt-1 pb-0",
    },
  },
  setup(props) {
    const dateFrom = ref(parseInt(props.value?.split(",")[0]));
    const dateTo = ref(parseInt(props.value?.split(",")[1]));
    return { dateFrom, dateTo };
  },
};
