
export default {
  name: "MoneyFormate",
  inheritAttrs: false,
  props: {
    model: {
      type: [Number, String],
    },
  },
};
