<template>
  <q-select
    filled
    v-model="modelInput"
    label="الفصول"
    :options="Myoptions"
    @filter="filterFn"
    option-name="classroomId"
    option-label="name"
    options-selected-class="text-deep-orange"
  >
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section>
          <q-item-label v-html="scope.opt.name" />
          <q-item-label caption
            >{{ scope.opt.gradeName }} | {{ scope.opt.levelName }}</q-item-label
          >
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> No results </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script>
export default {
  created() {
    this.Myoptions = this.options;
  },
  data() {
    return {
      Myoptions: null,
    };
  },
  methods: {
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.Myoptions = this.options;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.Myoptions = this.options.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
  computed: {
    modelInput: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    value: Object,
    options: null,
  },
};
</script>
