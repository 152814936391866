import router from "@/router";
import { mapState, mapActions } from "vuex";
import { Config } from "@/core/services/config";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { uuid } from "vue-uuid";

export default {
  mounted() {
    //console.log("mounted", this);
  },
  data() {
    return {
      formSetting: {
        formSize: "medium",
        LabelPosition: "top",
      },
      Rules: {
        Req: [(val) => (val && val.length > 0) || "مطلوب ادخال بيانات"],
        ReqOnly: [(val) => (val && val.length > 0) || ""],
        int: [(val) => (val && val > 0) || "برجاء الاختيار"],
        NotNull: [(val) => (val && !val) || "برجاء الاختيار"],
      },
      TypeHereToSearch: "اكتب هنا للبحث",
      PleaseSelect: "الرجاء اختيار",
      TypeHereToSearchFor: "اكتب هنا للبحث عن ",
    };
  },
  computed: {
    ...mapState({
      UserRoles: (state) => state.Identity.roles,
      IsAuthenticated: (state) => state.Identity.isAuthenticated,
      Culture: (state) => state.Identity.culture,
      User: (state) => state.Identity.user,
      CitiesOptions: (state) => state.LookUps.cities,
      JobsOptions: (state) => state.LookUps.jobs,
      ownPhoneNumberOptions: (state) => state.LookUps.ownPhoneNumbers,
      RolesOptions: (state) => state.LookUps.roles,
      CategoriesOptions: (state) => state.LookUps.categories,
      AdditionsOptions: (state) => state.LookUps.additions,
      BranchesOptions: (state) => state.LookUps.branches,
      CustomersOptions: (state) => state.LookUps.customers,
      Guid: () => {
        return uuid;
      },
    }),
    CurrentRoute: {
      get: function () {
        return router.currentRoute.value;
      },
    },
  },
  methods: {
    ...mapActions({
      getAccountTree: "Identity/IsAuthorize",
      Info: "Alert/Info",
      Success: "Alert/Info",
      Error: "Alert/Info",
      Warning: "Alert/Info",
      Alert_Api: "Alert/Api",
      Alert_Error: "alert/Error",
    }),
    CheckUserScreen(Role, pageIdCheck) {
      const pageId =
        pageIdCheck == null
          ? router.currentRoute.value.meta["pageId"]
          : pageIdCheck;

      const s = this.UserRoles?.filter((v) => v.screenId == pageId)[0];
      if (s) {
        switch (Role) {
          case "add":
            return s.isAdd;
          case "edit":
            return s.isEdit;
          case "view":
            return s.isView;
          case "statistics":
            return s.isStatistics;
          case "print":
            return s.isPrint;
          case "delete":
            return s.isDelete;
          default:
            return false;
        }
      } else {
        return false;
      }
    },
    UploadUrl() {
      return `${Config.API_URL}up/`;
    },
    IsAdd(pageId) {
      return this.CheckUserScreen("add", pageId);
    },
    IsEdit(pageId) {
      return this.CheckUserScreen("edit", pageId);
    },
    IsView(pageId) {
      return this.CheckUserScreen("view", pageId);
    },
    IsStatistics(pageId) {
      return this.CheckUserScreen("statistics", pageId);
    },
    IsPrint(pageId) {
      return this.CheckUserScreen("print", pageId);
    },
    IsDelete(pageId) {
      return this.CheckUserScreen("delete", pageId);
    },
    UrlCulture(Url) {
      return `/${this.Culture}/${Url}`;
    },
    SetCulture(to) {
      return `${"/" + this.Culture + to}`;
    },
    Cul(to) {
      return `${"/" + this.Culture + to}`;
    },
    IsSuperAdmin() {
      return this.User.userTypeId == 0;
    },
    IsStudent() {
      return this.User.userTypeId == 1;
    },

    UserTypeName() {
      if (this.User.userTypeId == 1) return "طالب";
      if (this.User.userTypeId == 2) return "مدرس";
      if (this.User.userTypeId == 4) return "مدير نظام";
      if (this.User.userTypeId == 5) return "مدير مدرسة";
      return "super admin";
    },
    IsTeacher() {
      // console.log(this.User)
      return this.User.userTypeId == 2;
    },
    IsLoggedIn() {
      return window.localStorage.getItem("token") != null;
    },
    IsSupervisor() {
      this.User.userTypeId == 1;
      return false;
    },
    DateFormat(date) {
      if (date == 0) return "";
      return moment(date).format("YYYY/MM/DD");
    },
  },
};
