import { Module } from "vuex-module-decorators";
import Filter from "./models/filter";
import FormModule from "@/core/base/FormModule";

@Module({ namespaced: true })
export default class Debentures extends FormModule {
  override swalFireMode = {
    title: "هل انت متأكد",
    text: "سيتك حذف السند ولن تكون قادر علي استرجاعه مره اخري",
    confirmButtonText: "نعم احذف",
    afterDeleteTitle: "تم الحذف",
    afterDeleteText: "تم حذف السند",
  }
  override endPoint = "Debentures";
  override filter = new Filter();
}
