import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_tree_select = _resolveComponent("n-tree-select")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      type: 'number',
      required: $props.required,
      message: $props.message,
      trigger: ['blur', 'change'],
    },
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    label: $props.label,
    "show-label": $props.ShowLabel,
    "ignore-path-change": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_tree_select, {
        checkable: "",
        multiple: $props.multiple,
        filterable: "",
        "clear-filter-after-select": false,
        value: $setup.MyValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
        placeholder: $props.placeholder,
        options: $setup.Tree,
        clearable: "",
        "max-tag-count": $props.max,
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
      }, null, 8, ["multiple", "value", "placeholder", "options", "max-tag-count"])
    ]),
    _: 1
  }, 8, ["rule", "class", "path", "label", "show-label"]))
}