<template>
  <span v-if="value" class="badge badge-light-danger">محذوف</span>
  <span v-else class="badge badge-light-success">غير محذوف</span>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>
