<template>
  <div>
    <span v-if="status" class="label label-lg label-light-success label-inline"
      >نشط</span
    >
    <span v-else class="label label-lg label-light-danger label-inline"
      >غير نشط</span
    >
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    value: String,
    status: Boolean,
  },
};
</script>
