<template>
  <div class="relative-position">
    <template v-if="loading">
      <skeleton-block />
    </template>
    <slot v-else></slot>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
