
import { computed } from "vue";

export default {
  props: {
    value: Number,
    options: null,
    label: null,
    col: {
      type: [String, Number],
      required: false,
      default: "12",
    },
  },
  data() {
    return {
      SortData: [
        { value: 0, label: "تنازلي" },
        { value: 1, label: "تصاعدي" },
      ],
    };
  },
  setup(props, { emit }) {
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });

    return {
      MyValue,
    };
  },
};
