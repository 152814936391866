
export default {
  name: "wError",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowAction: {
      type: Boolean,
      default: false,
    },
  },
};
