import { RouteRecordRaw } from "vue-router";

const purchasesReport: RouteRecordRaw = {
  path: "purchasesReport",
  component: () => import("@/views/reports/index.vue"),
  meta: {
    title: "تقرير المشتريات",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-purchasesReport",
      component: () =>
        import("@/views/reports/purchasesReport/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "تقرير المشتريات",
      },
    },
  ],
};

export default purchasesReport;
