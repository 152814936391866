
import { computed } from "vue";

export default {
  props: {
    value: Number,
    options: null,
    label: null,
  },
  data() {
    return {
      SortData: [
        { value: 1, label: "Transfer" },
        { value: 2, label: "Cash" },
        { value: 3, label: "Check" },
        { value: 4, label: "Network" },
      ],
    };
  },
  setup(props, { emit }) {
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });

    return {
      MyValue,
    };
  },
};
