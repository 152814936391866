<template>
  <div class="row">
    <div class="col-md-6 col-lg-4 col-xl-3" v-for="(i, index) in files">
      <!--begin::Card-->
      <div class="card h-100">
        <!--begin::Card body-->
        <div
          class="card-body d-flex justify-content-center text-center flex-column p-8"
        >
          <!--begin::Name-->
          <a class="text-gray-800 text-hover-primary d-flex flex-column">
            <!--begin::Image-->
            <div class="symbol symbol-60px mb-5">
              <img
                src="/metronic8/demo1/assets/media/svg/files/doc.svg"
                class="theme-light-show"
                alt=""
              />
              <img
                src="/metronic8/demo1/assets/media/svg/files/doc-dark.svg"
                class="theme-dark-show"
                alt=""
              />
            </div>
            <!--end::Image-->
            <!--begin::Title-->
            <div class="fs-5 fw-bold mb-2">{{ i.fileName }}</div>
            <!--end::Title-->
          </a>
          <!--end::Name-->
          <!--begin::Description-->
          <div class="fs-7 fw-semibold text-gray-400">{{ i.fileName }}</div>
          <!--end::Description-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
  </div>
</template>
<script>
import { Config } from "@/core/services/config";
export default {
  mounted() {
    this.files.forEach((e) => {
      this.files.fileName = Config.POST_MEDIA + e.fileName;
    });
  },
  props: {
    file: {
      type: Object,
    },
  },
};
</script>
