import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, { show: $props.show }, {
    default: _withCtx(() => [
      _createVNode(_component_n_card, {
        style: {"width":"600px"},
        title: $props.title,
        bordered: $props.bordered,
        size: "huge",
        role: "dialog",
        "aria-modal": "true"
      }, {
        "header-extra": _withCtx(() => [
          _renderSlot(_ctx.$slots, "header-extra")
        ]),
        footer: _withCtx(() => [
          _renderSlot(_ctx.$slots, "footer")
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["title", "bordered"])
    ]),
    _: 3
  }, 8, ["show"]))
}