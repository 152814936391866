import { RouteRecordRaw } from "vue-router";
const BalanceSheet: RouteRecordRaw = {
  path: "balanceSheet",
  component: () => import("@/views/accounts/index.vue"),

  meta: {
    title: "Accounts",
  },
  children: [
    {
      path: "",
      name: "balanceSheet",
      component: () => import("@/views/accounts/balanceSheet/pages/index.vue"),
      meta: {
        pageId: 17010300,
        role: "view",
        title: "BalanceSheet",
      },
    },
  ],
};

export default BalanceSheet;
