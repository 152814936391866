<template>
  <ImageWidget v-if="mediaTypeFilter(1)" :files="files" />
  <Video v-else-if="mediaTypeFilter(2)" :file="files[0]" />
  <Pdf v-else-if="mediaTypeFilter(3)" :files="files" />
  <Docx v-else-if="mediaTypeFilter(4)" :files="files" />
</template>
<script>
import PostServices from "@/components/media/js/postServices.js";
import ImageWidget from "@/components/media/_image.vue";
import Pdf from "@/components/media/_pdf.vue";
import Video from "@/components/media/_video.vue";
import Docx from "@/components/media/_docx.vue";
import { Config } from "@/core/services/config";
export default {
  components: {
    ImageWidget,
    Pdf,
    Video,
    Docx,
  },
  methods: {
    mediaTypeFilter: function (mediatype) {
      let _postServices = new PostServices(this.files, mediatype);
      return _postServices.mediaTypeFilter();
    },
  },
  props: {
    files: {
      type: Array,
    },
    path: {
      type: String,
      default: () => Config.POST_MEDIA,
    },
  },
};
</script>
