import { defineRule, configure } from "vee-validate";
import * as rules from "@vee-validate/rules";

import { localize } from "@vee-validate/i18n";
import { locale as en } from "@/i18n/validate/en";
import { locale as ar } from "@/i18n/validate/ar";

// Make the validat aggressive to show case validators
configure({
  validateOnInput: true,
  generateMessage: localize({
    en,
    ar,
  }),
});

// Install all rules
Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
});

export function initVeeValidate() {
  // Updating default vee-validate configuration
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });
}
