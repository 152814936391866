
import { defineComponent, ref, computed } from "vue";
import { message } from "@/core/base/naive";
import type { UploadCustomRequestOptions } from "naive-ui";
import * as tus from "tus-js-client";
import JwtService from "@/core/services/JwtService";
import MediaRander from "@/components/media/_mediaRander.vue";
import AddMediaRander from "@/components/media/_addMediaRander.vue";
import { Config } from "@/core/services/config";
import type { UploadInst, UploadFileInfo } from "naive-ui";

export default defineComponent({
  components: {
    MediaRander,
    AddMediaRander,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    path: String,
    accept: {
      type: String,
      default: () => ".jpeg,.jpg,.png,image/jpeg",
    },
    mediaTitle: {
      type: String,
      default: () => "اختر صور",
    },
    postMediaTypeId: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props, { emit }) {
    let UploadFileName = ref("");
    let UploadedMedia: any = ref([]);
    const GetPath = () => {
      let _List: any = [];
      props.value.forEach((fileName) => {
        // if (UploadedMedia.value.includes(fileName)) {
        //   _List.push({
        //     name: fileName,
        //     link: `${Config.POST_MEDIA}${fileName}`,
        //   });
        // } else {
        //   _List.push({
        //     name: fileName,
        //     link: `${Config.POST_MEDIA_Temp}${fileName}`,
        //   });
        // }
        _List.push({
          name: fileName,
          link: `${Config.POST_MEDIA}${fileName}`,
        });
      });
      return _List;
    };

    const customRequest = ({
      file,
      onFinish,
      onError,
      onProgress,
    }: UploadCustomRequestOptions) => {
      console.log("File d", file.name, file.type);
      var upload = new tus.Upload(file.file as File, {
        endpoint: "https://localhost:44363/api/Cloud/upload",
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          name: file.name.toString(),
          contentType: file.type || "application/octet-stream",
          type: file.type?.split("/")[0] || "",
        },
        headers: {
          Authorization: "bearer " + JwtService.get(),
          extension: file.name.split(".").pop() || "",
          FileType: file.type ?? "",
        },
        onError: function (error) {
          message.error("ملف غير مقبول");
          onError();
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          onProgress({ percent: Math.ceil(parseInt(percentage)) });
        },
        onSuccess: function () {
          UploadFileName.value =
            upload.url?.split("/").pop() +
            "." +
            upload.file["name"].split(".").pop();
          onFinish();
          UploadedMedia.value.push(UploadFileName.value);
          props.value.push(UploadFileName.value);
          emit("update:value", props.value);
        },
      });

      upload.findPreviousUploads().then(function (previousUploads) {
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }
        upload.start();
      });
    };
    const DeleteImage = (fileName) => {
      const index = props.value.indexOf(fileName);
      if (index > -1) {
        emit("update", props.value.splice(index, 1));
      }
      const index2 = UploadedMedia.value.indexOf(fileName);
      if (index2 > -1) {
        UploadedMedia.value.splice(index, 1);
      }
    };
    return {
      customRequest,
      UploadedMedia,
      DeleteImage,
      GetPath,
    };
  },
});
