
import { useStore } from "vuex";
import { defineComponent, computed, ref, onMounted } from "vue";
export default defineComponent({
  name: "PageFilter",
  props: {
    modelName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      emit("onMounted", filter);
    });
    const store = useStore();
    const ModelName = ref(props.modelName);
    const root = ModelName.value;
    const filter = computed(() => store.getters[`${root}/GetFilter`]);


    return { filter };
  },
});
