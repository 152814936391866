import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_time = _resolveComponent("n-time")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_time, {
      time: $props.time,
      format: "hh:mm:ss ",
      type: "date",
      class: "f-12 text-muted"
    }, null, 8, ["time"]),
    _createVNode(_component_n_time, {
      time: $props.time,
      format: "yyyy-MM-dd",
      type: "date",
      class: "f-14"
    }, null, 8, ["time"])
  ], 64))
}