<template>
  <div sticky-container class="relative-position">
    <slot></slot>
    <q-inner-loading :showing="loading">
      <q-spinner color="primary" size="3em" :thickness="2" />
    </q-inner-loading>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
