import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "app-container container-xxl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_block = _resolveComponent("skeleton-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(12, (i, index) => {
          return _createVNode(_component_skeleton_block, { key: index })
        }), 64))
      : _renderSlot(_ctx.$slots, "default", {
          key: 1,
          model: _ctx.model
        })
  ]))
}