import { RouteRecordRaw } from "vue-router";

const accountClosuresReport: RouteRecordRaw = {
  path: "accountClosuresReport",
  component: () => import("@/views/reports/index.vue"),
  meta: {
    title: "      تقفيلة اليوم  ",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-accountClosuresReport",
      component: () =>
        import("@/views/reports/accountClosuresReport/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "       تقفيلة اليوم ",
      },
    },
  ],
};

export default accountClosuresReport;
