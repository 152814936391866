import { RouteRecordRaw } from "vue-router";
const AssetCategoryView: RouteRecordRaw = {
  path: "assets",
  meta: {
    title: "AssetManagement"
  },
  component: () => import("@/views/accounts/index.vue"),
  children: [
    {
      path: "view",
      props: true,
      name: "assets-id",
      component: () => import("@/views/accounts/assets/assets/pages/index.vue"),
      meta: {
        pageId: 17020200,
        role: "view",
        title: "Assets"
      },
    },

    {
      path: "add/:cId",
      name: "Add New asset",
      component: () => import("@/views/accounts/assets/assets/pages/add.vue"),
      meta: {
        pageId: 17020200,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "Edit asset",
      component: () => import("@/views/accounts/assets/assets/pages/add.vue"),
      meta: {
        pageId: 17020200,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default AssetCategoryView;
