import { createI18n } from "vue-i18n";
// Localisation language list
import { locale as en } from "@/i18n/en";
import { locale as ar } from "@/i18n/ar";
import LanguageService from "@/core/services/language";

const messages = {
  en,
  ar,
};
const i18n = createI18n({
  legacy: false,
  locale: LanguageService.get(),
  globalInjection: true,
  messages,
});

export default i18n;
