export const locale = {
  code: "ar",
  messages: {
    alpha: "{field} يجب ان يحتوي على حروف فقط",
    alpha_num: "{field} قد يحتوي فقط على حروف وارقام",
    alpha_dash: "{field} قد يحتوي على حروف او الرموز - و _",
    alpha_spaces: "{field} قد يحتوي فقط على حروف ومسافات",
    between: "قيمة {field} يجب ان تكون ما بين 0:{min} و 1:{max}",
    confirmed: "{field} لا يماثل التأكيد",
    digits:
      "{field} يجب ان تحتوي فقط على ارقام والا يزيد عددها عن 0:{length} رقم",
    dimensions: "{field} يجب ان تكون بمقاس 0:{width} بكسل في 1:{height} بكسل",
    email: "{field} يجب ان يكون بريدا اليكتروني صحيح",
    excluded: "الحقل {field} غير صحيح",
    ext: "نوع ملف {field} غير صحيح",
    image: "{field} يجب ان تكون صورة",
    integer: "الحقل {field} يجب ان يكون عدداً صحيحاً",
    length: "حقل {field} يجب الا يزيد عن 0:{length}",
    max_value: "قيمة الحقل {field} يجب ان تكون اصغر من 0:{min} او تساويها",
    max: "الحقل {field} يجب ان يحتوي على 0:{length} حروف على الأكثر",
    mimes: "نوع ملف {field} غير صحيح",
    min_value: "قيمة الحقل {field} يجب ان تكون اكبر من 0:{min} او تساويها",
    min: "الحقل {field} يجب ان يحتوي على 0:{length} حروف على الأقل",
    numeric: "{field} يمكن ان يحتوي فقط على ارقام",
    one_of: "الحقل {field} يجب ان يكون قيمة صحيحة",
    regex: "الحقل {field} غير صحيح",
    required: "{field} مطلوب",
    required_if: "حقل {field} مطلوب",
    size: "{field} يجب ان يكون اقل من 0:{size} كيلوبايت",
    url: "حقل {field} ليس رابطاً صحيحاً",
  },
};
