
import { ar } from "date-fns/locale";
export default {
  name: "DateFormate",
  inheritAttrs: false,
  props: {
    modelValue: [String, Date],
    fs: {
      type: [String, Number],
      default: "8",
    },
  },
  setup() {
    return { ar };
  },
};
