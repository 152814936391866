import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      type: 'any',
      required: $props.required,
      message: $props.message,
      trigger: ['blur', 'change', 'update'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    isRtl: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_select, {
        value: $setup.MyValue,
        "onUpdate:value": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
          $setup.UpdateValue
        ],
        multiple: $props.multiple,
        disabled: $props.disabled,
        placeholder: $props.placeholder,
        filterable: "",
        size: _ctx.formSetting.formSize,
        options: $setup.options,
        loading: $setup.loading,
        clearable: "",
        remote: "",
        "clear-filter-after-select": false,
        onSearch: $setup.handleSearch,
        "max-tag-count": $props.max
      }, null, 8, ["value", "multiple", "disabled", "placeholder", "size", "options", "loading", "onSearch", "max-tag-count", "onUpdate:value"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path"]))
}