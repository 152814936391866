<template>
  <div sticky-container class="relative-position mb-7">
    <div class="col-xl-12 PageHeader">
      <div
        :class="[
          'card card-custom gutter-b card-stretch',
          TagsListHasItems ? ' mb-0' : '',
        ]"
      >
        <div
          class="card-header border-0 pointer"
          v-on:click="ChangeOpenFilter(!OpenFilter)"
        >
          <div>
            <h3 class="card-title font-weight-bolder text-white mb-0 pt-4">
              {{ title }}
            </h3>
            <span style="color: #e5e5e5 !important; font-size: 10px">
              عدد العناصر {{ Pager.totalCount ?? models?.model?.length }}
            </span>
          </div>
          <div class="card-toolbar">
            <page-sort
              :options="SortList"
              v-if="SortList"
              v-model="SortValue"
              @ChangeSort="ChangeSort"
            ></page-sort>
            <i v-if="$slots.filter" class="fa-solid fa-filter-list"></i>

            <!-- <div class="btn text-white">
            sdfd
            <i class="fa-solid fa-magnifying-glass text-white"></i>
            {{ $t("Search") }}
          </div> -->
            <slot name="toolbar"></slot>
          </div>
        </div>
        <n-collapse-transition :show="OpenFilter">
          <div class="filter bg-white col-12 p-5">
            <div
              :label-position="formSetting.LabelPosition"
              ::size="formSetting.formSize"
              class="row"
            >
              <!-- {{ filterComponent }}
              <component :is="PageFilter" /> -->
              <slot v-if="Pager" name="filter" :filter="response.filter"></slot>
              <div class="separator separator-dashed mb-7"></div>

              <div class="d-flex flex-wrap py-2 mr-3">
                <a-button
                  text="بحث"
                  @click="OnFilterSubmit"
                  svg="/media/icons/duotune/general/gen031.svg"
                  color="primary"
                />
                <a-button
                  text="الغاء"
                  @click="OnClearSearch"
                  icon="fa-solid fa-xmark-large"
                  color="warning"
                />
              </div>
            </div>
          </div>
        </n-collapse-transition>
        <el-affix :offset="120">
          <div
            class="card-body d-flex flex-column p-0 ding"
            style="position: relative"
          >
            <div class="card-spacer bg-white flex-grow-1" v-loading="loading">
              <pagination
                @PageTo="OnPageTo"
                @ChangePageSize="OnChangePageSize"
                :Pager="Pager"
                :btnAddLable="$t('add')"
                :btnAddTo="AddTo"
                :disablePager="disablePager"
                @OnOpenModal="OnOpenModal"
                :ModalNew="ModalNew"
              >
                <router-link
                  :to="Cul(backTo)"
                  v-if="backTo"
                  class="btn btn-sm btn-flex btn-light btn-active-info fw-bolder mx-2"
                >
                  <span class="mx-3">{{ backLabel }}</span>
                  <inline-svg src="/media/icons/duotune/arrows/arr063.svg" />
                </router-link>

                <slot name="controles"></slot>
                <a-btn-sm
                  v-if="addFromExcel"
                  :to="addFromExcel"
                  text="اضافة اكسيل"
                  icon="fa fa-file-excel"
                  color="primary"
                />
                <a-print-btn
                  @click="toggleDrawer(true)"
                  v-if="IsPrint() && print"
                  color="primary"
                ></a-print-btn>
              </pagination>
            </div>
            <el-progress
              :percentage="30"
              :indeterminate="true"
              :show-text="false"
              :stroke-width="3"
              v-if="loading"
              dir="ltr"
            />
          </div>
        </el-affix>
      </div>
      <div
        v-if="TagsListHasItems"
        class="col-12 rounded-bottom bg-gray-100 p-3 mb-5"
      >
        <div
          v-on:click="OnClearSearch()"
          class="btn btn-icon btn-light-danger pulse pulse-success mr-3"
        >
          <i class="fad fa-trash-alt"></i>
        </div>
        <!--  <div
        v-for="(i, index) in TagsList"
        :key="index"
        class="btn btn-sm font-weight-bolder btn-light-success ml-3"
      >
        <i class="la la-filter"></i>
        {{ filterTags[i] }}
      </div> -->
      </div>
    </div>
    <sidebarPrint
      v-if="Reports"
      :model="Reports"
      @Print="OnPrintExcel"
      :drawer="drawer"
      @toggleDrawer="toggleDrawer"
    ></sidebarPrint>

    <div class="mt-10">
      <div class="row">
        <template v-if="loading">
          <skeleton-block v-for="(i, index) in 12" :key="index" />
        </template>
        <slot :items="models" :filter="Filter" :response="response" v-else />
      </div>
    </div>
    <w-empty v-if="Pager && Pager.totalCount == 0" />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "PageHeader",
  data() {
    return {
      SortValue: this.SortListValue,
    };
  },
  props: {
    print: {
      type: Boolean,
      default: () => false,
    },
    title: String,
    AddTo: null,
    backTo: null,
    addFromExcel: null,
    filterTags: null,
    SortList: Array,
    SortListValue: Number,
    disablePager: Boolean,
    ModalNew: Boolean,
    modelName: String,
    filterComponent: String,
    GetIndexParam: [Number, String],
    backLabel: {
      type: String,
      default: () => "رجوع",
    },
  },
  // components: {
  //   ModelItem: defineAsyncComponent(() => import(itemComponent)),
  // },
  setup(props, { emit, slots }) {
    // const emp = reactive(props.model);
    const store = useStore();
    const ModelName = ref(props.modelName);
    let drawer = ref(false);
    const toggleDrawer = (v) => (drawer.value = v);
    const root = ModelName.value;
    // const FilterTags = ref(props.filterTags);
    const OnOpenModal = () => emit("OnOpenModal", true);

    let OpenFilter = ref(false);
    const ChangeOpenFilter = (val) => {
      if (slots.filter) {
        OpenFilter.value = val;
      }
    };

    // const PageFilter = computed(() =>
    //   defineAsyncComponent(() => import(props.filterComponent))
    // );

    onMounted(async () => {
      await GetIndex().then(() => {
        emit("onMounted", models.value);
      });
    });

    const Pager = computed(() => store.getters[`${root}/GetPager`]);
    const loading = computed(() => store.getters[`${root}/GetLoading`]);
    const filterQuery = computed(() => store.getters[`${root}/GetFilterQuery`]);
    const Reports = computed(() => store.getters[`${root}/GetReports`]);
    const models = computed(() => store.getters[`${root}/GetModels`]);
    const response = computed(() => store.getters[`${root}/GetResponse`]);
    const Filter = computed(() => store.getters[`${root}/GetFilter`]);

    const GetIndex = () => {
      if (props.GetIndexParam != undefined) {
        return store.dispatch(`${root}/Index`, props.GetIndexParam);
      } else {
        return store.dispatch(`${root}/Index`);
      }
    };
    const OnFilterSubmit = () => {
      store.dispatch(`${root}/GetSearch`);
      OpenFilter.value = false;
    };
    const OnPageTo = (p) => store.dispatch(`${root}/PageTo`, p);
    const OnClearSearch = () => {
      store.dispatch(`${root}/ClearSearch`);
      OpenFilter.value = false;
    };
    const OnPrintExcel = (p) => {
      // console.log("OnPrintExcelp", p);
      store.dispatch(`${root}/Export`, p);
    };
    const OnChangePageSize = (Size) =>
      store.dispatch(`${root}/ChangePageSize`, Size);

    const OnChangeFilter = () => {
      OpenFilter.value = !OpenFilter.value;
    };

    const DeleteItem = (v) => {
      store.dispatch(`${root}/DeleteItem`, v);
    };

    // const TagsList = computed(() => {
    //   console.log("filterTags", FilterTags.value);
    //   if (FilterTags.value == null) return null;
    //   return Object.keys(FilterTags.value).filter((p) => p != "ps" && p != "p");
    // });

    const TagsListHasItems = computed(() => {
      return false;
      // console.log("filterTags", FilterTags.value);
      // if (this.TagsList == null) return false;
      // return Object.keys(this.TagsList).length > 0;
    });

    return {
      OnClearSearch,
      OnFilterSubmit,
      OnChangePageSize,
      OnPageTo,
      OnChangeFilter,
      DeleteItem,
      ModelName,
      TagsListHasItems,
      Reports,
      OnPrintExcel,
      Pager,
      filterQuery,
      loading,
      models,
      OpenFilter,
      ChangeOpenFilter,
      Filter,
      response,
      drawer,
      toggleDrawer,
      OnOpenModal,
    };
  },
});
</script>
