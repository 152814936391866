
export default {
  props: {
    to: String,
    icon: String,
    text: String,
    color: {
      type: String,
      required: false,
      default: "success",
    },
  },
};
