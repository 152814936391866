
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-explore",
  props: {
    name: String,
    title: String,
  },
});
