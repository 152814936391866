
import { Config } from "@/core/services/config";
import { defineComponent, computed, ref, onMounted } from "vue";

export default defineComponent({
  name: "PageItem",
  emits: ["GetPath", "DeleteImage"],
  props: {
    postMediaTypeId: {
      type: Number,
    },
    value: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const GetPath = (v) => {
      console.log("emit(   , v)", emit("GetPath", v));
      return emit("GetPath", v);
    };
    const DeleteImage = (v) => {
      return emit("DeleteImage", v);
    };
    return {
      GetPath,
      DeleteImage,
    };
  },
});
