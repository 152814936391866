import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import LanguageService from "@/core/services/language";
import Views from "@/views/router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/ar",
  },
  {
    path: "/:lang(ar|en)",
    component: () => import("@/views/shared/index.vue"),
    children: [Views],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const lang = to.path.toString().split("/")[1],
    localLanguage = LanguageService.get();

  if (lang == null || (lang != "ar" && lang != "en")) {
    next("/" + localLanguage + to.path.toString());
  } else {
    if (lang != localLanguage) {
      LanguageService.SetDirection(localLanguage);
    }

    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    store.dispatch("Identity/verifyAuth");

    next();
    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
});

export default router;
