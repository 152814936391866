import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "menu-item" }
const _hoisted_2 = { class: "menu-link" }
const _hoisted_3 = { class: "menu-icon" }
const _hoisted_4 = { class: "svg-icon svg-icon-6 svg-icon-success me-3" }
const _hoisted_5 = { class: "menu-title" }
const _hoisted_6 = {
  key: 0,
  class: "badge badge-light-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/abstract/abs009.svg" })
        ])
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString($props.title), 1),
      ($props.ShowCount)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.count), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}