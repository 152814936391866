const ID_TOKEN_KEY = "token" as string;

/**
 * @description get token form localStorage
 */
export const get = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const save = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroy = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { get, save, destroy };
