import { RouteRecordRaw } from "vue-router";
const AssetCategory: RouteRecordRaw = {
  path: "assetCategory",
  meta: {
    title: "Assets"
  },
  component: () => import("@/views/accounts/index.vue"),
  children: [
    {
      path: "",
      alias: "index",
      name: "assetCategory",
      component: () =>
        import("@/views/accounts/assets/assetCategory/pages/index.vue"),
      meta: {
        pageId: 17020100,
        role: "view",
        title: "Assets"
      },
    },
    {
      path: "Add",
      name: "Add New assetCategory",
      component: () =>
        import("@/views/accounts/assets/assetCategory/pages/add.vue"),
      meta: {
        pageId: 17020100,
        role: "add",
        title: "AddNew",
      },
    },

    {
      path: "Edit/:Id",
      props: true,
      name: "Edit assetCategory",
      component: () =>
        import("@/views/accounts/assets/assetCategory/pages/add.vue"),
      meta: {
        pageId: 17020100,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default AssetCategory;
