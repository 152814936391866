import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "jitsiContainer",
  style: {"overflow":"hidden","height":"100%","width":"100%"},
  height: "100%",
  width: "100%"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}