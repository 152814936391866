import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-kt-drawer-toggle", "data-kt-drawer-close"]
const _hoisted_2 = { class: "card shadow-none w-100" }
const _hoisted_3 = {
  class: "card-header",
  id: "kt_explore_header"
}
const _hoisted_4 = { class: "card-title fw-bolder text-gray-700" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-active-light-primary me-n5",
  id: "SideBarPrint_close"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = {
  class: "card-body",
  id: "kt_explore_body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.name,
    class: "bg-body",
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "explore",
    "data-kt-drawer-activate": "true",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'350px', 'lg': '475px'}",
    "data-kt-drawer-direction": "end",
    "data-kt-drawer-toggle": `#${_ctx.name}`,
    "data-kt-drawer-close": `#${_ctx.name}_close`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 8, _hoisted_1))
}