
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "UrlLink",
  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const IsActive = (url) => (url == route.path ? "active" : "");
    return { IsActive };
  },
});
