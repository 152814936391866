import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_header = _resolveComponent("edit-header")!
  const _component_n_form = _resolveComponent("n-form")!

  return ($props.dynamicForm)
    ? (_openBlock(), _createBlock(_component_n_form, {
        key: 0,
        ref: "formRef",
        model: $props.dynamicForm,
        size: _ctx.formSetting.formSize
      }, {
        default: _withCtx(() => [
          ($props.header)
            ? (_openBlock(), _createBlock(_component_edit_header, {
                key: 0,
                Reload: $props.Reload,
                onReloadPage: $setup.ReloadPage,
                label: $props.label,
                Save: $props.Save,
                backTo: $props.backTo,
                onHandleValidateClick: $setup.handleValidateClick,
                backAction: $props.backAction,
                onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
              }, null, 8, ["Reload", "onReloadPage", "label", "Save", "backTo", "onHandleValidateClick", "backAction"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["model", "size"]))
    : (_openBlock(), _createBlock(_component_n_form, {
        key: 1,
        ref: "formRef",
        model: $props.form,
        size: _ctx.formSetting.formSize
      }, {
        default: _withCtx(() => [
          ($props.header)
            ? (_openBlock(), _createBlock(_component_edit_header, {
                key: 0,
                Reload: $props.Reload,
                onReloadPage: $setup.ReloadPage,
                label: $props.label,
                Save: $props.Save,
                backTo: $props.backTo,
                onHandleValidateClick: $setup.handleValidateClick,
                backAction: $props.backAction,
                onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
              }, null, 8, ["Reload", "onReloadPage", "label", "Save", "backTo", "onHandleValidateClick", "backAction"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["model", "size"]))
}