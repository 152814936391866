<template>
  <btn class="mr-3" icon="fa-layer-plus">{{ name }}</btn>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "تحميل",
    },
  },
};
</script>
