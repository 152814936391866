import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.model && $props.model.length == 0)
    ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", {
          colspan: $props.colspan,
          class: "text-center p-8"
        }, "لا يوجد بيانات مسجلة", 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}