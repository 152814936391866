import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { Config } from "@/core/services/config";
import { string } from "yup";
import helper from "./helper.js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = Config.API_URL;
    // ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    this.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `bearer ${JwtService.get()}`;

    ApiService.vueInstance.axios.defaults.headers.common["Culture"] = "ar";
    //JwtService.getCulture();
    ApiService.vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = `application/json`;
    ApiService.vueInstance.axios.defaults.headers.common[
      "Accept"
    ] = `application/vnd.hal+json`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: object
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string, params?: object): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}`, { params: params })
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: object): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  public static form(resource: string, form: object): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(
      `${resource}`,
      helper.getFormData(form)
    );
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  public static download(
    resource: string,
    params: object
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios({
      method: "post",
      url: resource,
      responseType: "blob",
      params: params,
    });
  }
}

export default ApiService;
