import Base from "@/core/base/BaseFilter";
export default class Filter extends Base {
  TransactionId: 0;
  dt: "";
  df: "";
  ttid: 0;
  IsAuto: 0;
  Approval: 0;
  IsDeleted: 1;
  aid: null;
}
