
import { ref, ToRef } from "vue";
import { FormInst, useMessage } from "naive-ui";
import { messagesResponse, error, success } from "@/core/base/systemAlert";
import { SelectInst } from "naive-ui";
export default {
  emits: ["onSubmit"],
  name: "FromFrom",
  inheritAttrs: false,
  props: {
    form: { type: Object, required: false },
    dynamicForm: { type: Object, required: false },
    loading: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    backTo: {
      type: String,
      required: false,
    },
    backAction: {
      type: Boolean,
    },
    Save: {
      type: Boolean,
      required: false,
      default: true,
    },
    header: {
      type: Boolean,
      required: false,
      default: true,
    },

    Reload: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    let FromKey = ref(Math.round(Math.random() * 1000));
    const ReloadPage = () => (FromKey.value = Math.round(Math.random() * 1000));

    const formRef = ref<FormInst | null>(null);
    const divs = ref([]);

    function handleValidateClick(e: MouseEvent) {
      console.clear();
      e?.preventDefault();
      console.log("formRef.value()", formRef.value?.validate);
      formRef.value
        ?.validate()
        .then(() => {
          emit("onSubmitPageForm");
        })
        .catch((errors) => {
          error("الرجاء اكمال البيانات");
          console.log(ref<SelectInst | null>().value, errors[0][0].field);
        });
    }

    return { handleValidateClick, ReloadPage, FromKey, formRef };
  },
};
