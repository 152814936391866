import { RouteRecordRaw } from "vue-router";
const Settings: RouteRecordRaw = {
  path: "setting",
  component: () => import("@/views/public/index.vue"),
  meta: {
    title: "الاعدادت",
  },
  children: [
    {
      path: "version",
      name: "version",
      component: () => import("@/views/public/settings/pages/version.vue"),
      meta: {
        pageId: 19030200,
        role: "view",
        title: "الاصدار",
      },
    },
    {
      path: "email",
      name: "email",
      component: () => import("@/views/public/settings/pages/email.vue"),
      meta: {
        pageId: 18030100,
        role: "view",
        title: "الايميلات",
      },
    },
    {
      path: "tax",
      name: "tax",
      component: () => import("@/views/public/settings/pages/tax.vue"),
      meta: {
        pageId: 18030100,
        role: "view",
        title: "الضريبة",
      },
    },
    {
      path: "a-email",
      name: "email-admin",
      component: () => import("@/views/public/settings/pages/adminEmail.vue"),
      meta: {
        pageId: 19030100,
        role: "view",
        title: "الايميلات",
      },
    },

    {
      path: "emailHeaderAndFooter",
      name: "emailHeaderAndFooter",
      component: () =>
        import("@/views/public/settings/pages/emailHeaderAndFooter.vue"),
      meta: {
        pageId: 19030200,
        role: "view",
        title: "قوالب الايملات",
      },
    },
    {
      path: "emailTemplate",
      name: "emailTemplate",
      component: () =>
        import("@/views/public/settings/pages/emailTemplate.vue"),
      meta: {
        pageId: 18030100,
        role: "view",
        title: "قوالب الايملات",
      },
    },
    {
      path: "a-emailTemplate",
      name: "a-emailTemplate",
      component: () =>
        import("@/views/public/settings/pages/emailTemplate.vue"),
      meta: {
        pageId: 19030200,
        role: "view",
        title: "قوالب الايملات",
      },
    },

    {
      path: "a-mediaType",
      name: "a-mediaType",
      component: () => import("@/views/public/settings/pages/mediaType.vue"),
      meta: {
        pageId: 19030200,
        role: "view",
        title: "اعدادات  انواع الوسائط ",
      },
    },
  ],
};

export default Settings;
