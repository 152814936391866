import { RouteRecordRaw } from "vue-router";

const taxReport: RouteRecordRaw = {
  path: "taxReport",
  component: () => import("@/views/reports/index.vue"),
  meta: {
    title: "التقرير الضريبي ",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-taxReport",
      component: () => import("@/views/reports/taxReport/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "  التقرير الضريبي ",
      },
    },
  ],
};

export default taxReport;
