
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    Title: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    statusLabel: {
      type: String,
      default: "اضافة",
    },
    saveLabel: {
      type: String,
      default: () => "حفظ",
    },
    Save: {
      type: Boolean,
      default: () => false,
    },
    Reload: {
      type: Boolean,
      default: () => false,
    },
    View: {
      type: Boolean,
      default: () => false,
    },
    AddOrEdit: {
      type: Boolean,
      default: () => false,
    },
    backLabel: {
      type: String,
      default: () => "رجوع",
    },
    hide: {
      type: Boolean,
      default: () => false,
    },
    backTo: {
      type: String,
    },
    backAction: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const ReloadPage = () => emit("ReloadPage");

    return {
      ReloadPage,
    };
  },
});
