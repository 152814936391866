
export default {
  name: "wEmpty",
  inheritAttrs: false,
  props: {
    svg: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    count: {
      type: [Number, String],
      default: 0,
    },
    ShowCount: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
