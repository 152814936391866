const getFormData = (object) =>
  Object.entries(object).reduce((fd, [key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
    return fd;
  }, new FormData());

export default {
  getFormData,
};
