import Base from "@/core/base/BaseFilter";
export default class Filter extends Base {
  id: 0;
  df: "";
  dt: "";
  aif: 0;
  ait: 0;
  pti: 0;
  dti: 0;
}
