<template>
  <div class="col-xl-12 PageHeader">
    <div
      :class="[
        'card card-custom gutter-b card-stretch',
        TagsListHasItems ? ' mb-0' : '',
      ]"
    >
      <div
        class="card-header border-0 pointer"
        v-on:click="OpenFilter = !OpenFilter"
      >
        <div>
          <h3 class="card-title font-weight-bolder text-white mb-0 pt-4">
            {{ title }}
          </h3>
          <span style="color: #e5e5e5 !important; font-size: 10px">
            عدد العناصر {{ Pager.totalCount }} عدد الصفحات {{ Pager.pageSize }}
          </span>
        </div>

        <div class="card-toolbar" v-if="!disablePager">
          <page-sort
            :options="SortList"
            v-if="SortList"
            v-model="SortValue"
            @ChangeSort="ChangeSort"
          ></page-sort>
          <inline-svg
            class="text-white svg-icon"
            src="/media/svg/general/Thunder-move.svg"
          />

          <!-- <div class="btn text-white">
            sdfd
            <i class="fa-solid fa-magnifying-glass text-white"></i>
            {{ $t("Search") }}
          </div> -->
          <slot name="toolbar"></slot>
        </div>
      </div>
      <q-slide-transition>
        <div v-show="OpenFilter" class="filter bg-white col-12 p-5">
          <div
            :label-position="formSetting.LabelPosition"
            ::size="formSetting.formSize"
            class="row"
          >
            <slot name="filter"></slot>
            <div class="separator separator-dashed mb-7"></div>

            <div class="d-flex flex-wrap py-2 mr-3">
              <a-button
                text="بحث"
                @click="OnFilterSubmit"
                svg="/media/icons/duotune/general/gen031.svg"
                color="primary"
              />
              <a-button
                text="الغاء"
                @click="OnClearSearch"
                icon="fa-solid fa-xmark-large"
                color="warning"
              />
            </div>
          </div>
        </div>
      </q-slide-transition>
      <el-affix :offset="120">
        <div
          class="card-body d-flex flex-column p-0 ding"
          style="position: relative"
          v-loading="loading"
        >
          <div class="card-spacer bg-white flex-grow-1">
            <pagination
              @PageTo="OnPageTo"
              @ChangePageSize="OnChangePageSize"
              :Pager="Pager"
              :btnAddLable="$t('add')"
              :btnAddTo="AddTo"
            >
              <slot name="controles"></slot>
              <a-btn-sm
                v-if="addFromExcel"
                :to="addFromExcel"
                text="اضافة اكسيل"
                icon="fa fa-file-excel"
              />
              <a-print-btn
                v-if="IsPrint() && print"
                id="SidebarPrint"
              ></a-print-btn>
            </pagination>
          </div>
        </div>
      </el-affix>
    </div>
    <div
      v-if="TagsListHasItems"
      class="col-12 rounded-bottom bg-gray-100 p-3 mb-5"
    >
      <div
        v-on:click="OnClearSearch()"
        class="btn btn-icon btn-light-danger pulse pulse-success mr-3"
      >
        <i class="fad fa-trash-alt"></i>
      </div>
      <!--  <div
        v-for="(i, index) in TagsList"
        :key="index"
        class="btn btn-sm font-weight-bolder btn-light-success ml-3"
      >
        <i class="la la-filter"></i>
        {{ filterTags[i] }}
      </div> -->
    </div>
  </div>
  <sidebarPrint
    v-if="Reports"
    :model="Reports"
    @Print="OnPrintExcel"
  ></sidebarPrint>
</template>
<script>
import { defineComponent, computed, reactive, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "PageHeader",
  data() {
    return {
      OpenFilter: false,
      SortValue: this.SortListValue,
    };
  },
  props: {
    print: {
      type: Boolean,
      default: () => false,
    },
    title: String,
    AddTo: null,
    addFromExcel: null,
    filterTags: null,
    SortList: Array,
    SortListValue: Number,
    disablePager: Boolean,
    modelName: String,
  },
  setup(props) {
    // const emp = reactive(props.model);
    const store = useStore();
    const ModelName = ref(props.modelName);
    // const FilterTags = ref(props.filterTags);

    const Reports = computed(() => {
      return store.getters[`${ModelName.value}/GetReports`];
    });

    const Pager = computed(() => {
      return store.getters[`${ModelName.value}/GetPager`];
    });

    const loading = computed(() => {
      return store.getters[`${ModelName.value}/GetLoading`];
    });

    const filterQuery = computed(() => {
      return store.getters[`${ModelName.value}/GetFilterQuery`];
    });
    const OnChangeFilter = () => {
      this.OpenFilter = !this.OpenFilter;
    };

    const OnFilterSubmit = () => {
      store.dispatch(`${ModelName.value}/GetSearch`);
    };

    const OnPageTo = (p) => {
      store.dispatch(`${ModelName.value}/PageTo`, p);
    };

    const OnChangePageSize = (Size) => {
      // console.log("OnChangePageSize", Size);
      store.dispatch(`${ModelName.value}/ChangePageSize`, Size);
    };

    const OnClearSearch = () => {
      store.dispatch(`${ModelName.value}/ClearSearch`);
    };

    const OnPrintExcel = (p) => {
      store.dispatch(`${ModelName.value}/PrintExcel`, p);
    };
    // const TagsList = computed(() => {
    //   console.log("filterTags", FilterTags.value);
    //   if (FilterTags.value == null) return null;
    //   return Object.keys(FilterTags.value).filter((p) => p != "ps" && p != "p");
    // });

    const TagsListHasItems = computed(() => {
      return false;
      // console.log("filterTags", FilterTags.value);
      // if (this.TagsList == null) return false;
      // return Object.keys(this.TagsList).length > 0;
    });

    return {
      OnClearSearch,
      OnFilterSubmit,
      OnChangePageSize,
      OnPageTo,
      OnChangeFilter,
      ModelName,
      TagsListHasItems,
      Reports,
      OnPrintExcel,
      Pager,
      filterQuery,
      loading,
    };
  },
});
</script>
