import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_form = _resolveComponent("n-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, { show: $setup.open }, {
    default: _withCtx(() => [
      _createVNode(_component_n_card, {
        style: {"width":"600px"},
        title: $props.title,
        bordered: $props.bordered,
        size: "huge",
        role: "dialog",
        "aria-modal": "true"
      }, {
        "header-extra": _withCtx(() => [
          _renderSlot(_ctx.$slots, "header-extra")
        ]),
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => {
              $setup.onCancel();
              $props.show = false;
            })
            }, {
              default: _withCtx(() => [
                _createTextVNode("الغاء")
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $setup.handleValidateClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(" حفظ ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_n_form, {
            ref: "formRef",
            model: $props.form,
            size: _ctx.formSetting.formSize
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["model", "size"])
        ]),
        _: 3
      }, 8, ["title", "bordered"])
    ]),
    _: 3
  }, 8, ["show"]))
}