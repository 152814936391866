import { RouteRecordRaw } from "vue-router";
const Tree: RouteRecordRaw = {
  path: "tree",
  component: () => import("@/views/accounts/index.vue"),
  meta: {
    title: "Accounts",
  },

  children: [
    {
      path: "",
      alias: "index",
      name: "AccountsTree",
      component: () => import("@/views/accounts/tree/pages/index.vue"),
      meta: {
        pageId: 17010300,
        role: "view",
        title: "AccountsTree",
      },
    },
  ],
};

export default Tree;
