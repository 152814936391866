import { RouteRecordRaw } from "vue-router";
const AccountStatement: RouteRecordRaw = {
  path: "accountStatement",
  component: () => import("@/views/accounts/index.vue"),

  meta: {
    title: "Accounts",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "accountStatement",
      component: () =>
        import("@/views/accounts/accountStatement/pages/index.vue"),
      meta: {
        pageId: 17010200,
        role: "view",
        title: "Ledger",
      },
    },
  ],
};

export default AccountStatement;
