import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_select = _resolveComponent("w-select")!

  return (_openBlock(), _createBlock(_component_w_select, {
    value: $setup.MyValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
    options: $data.SortData,
    label: "نوع السداد",
    placeholder: "طريقة الدفع"
  }, null, 8, ["value", "options"]))
}