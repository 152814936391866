
interface IPagination {
  page: number;
  total: number;
}
import { ref, onMounted } from "vue";
export default {
  name: "FInput",
  inheritAttrs: false,
  props: {
    Pager: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    printModel: {
      type: Array,
      required: false,
    },
    btnAddLable: {
      type: String,
      required: false,
    },
    btnAddTo: {
      type: String,
      required: false,
    },
  },
  emits: ["PageTo", "ChangePageSize"],
  setup(props, { emit }) {
    const PageTo = (value) => emit("PageTo", value);
    const SetPageSize = (value) => emit("ChangePageSize", value);
    const pagination = ref<IPagination>({
      page: 1,
      total: 1,
    });

    onMounted(() => {
      pagination.value.page = props.Pager.pageNumber;
      pagination.value.total = props.Pager.totalCount;
    });

    return { PageTo, SetPageSize, pagination };
  },
};
