import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import PageModule from "@/core/base/PageModule";
import Filter from "./models/filter";

@Module({ namespaced: true })
export default class AssetsBalance extends PageModule {
  override endPoint = "Assets";
  override indexUrl = "/Balance";
  override filter = new Filter();
}
