<template>
  <span v-if="!value" class="badge badge-light-success">{{ title[0] }}</span>
  <span v-else class="badge badge-light-danger">{{ title[1] }} </span>
</template>
<script>
export default {
  props: {
    value: Boolean,
    title: {
      type: Object,
      default: () => {
        return { 1: "غير مفعل", 0: "مفعل" };
      },
    },
  },
};
</script>
