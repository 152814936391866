// import { ElMessage } from "element-plus";

import { message } from "@/core/base/naive";

const MsgOption = { duration: 5000, closable: false, keepAliveOnHover: true };

export const messagesResponse = (messages): void => {
  if (messages) {
    for (let i = 0; i < messages.length; i++) {
      alert(messages[i].message, messages[i].type.toLowerCase());
    }
  }
};

export const success = (value: string): void => {
  message.success(value, MsgOption);
};
export const info = (value: string): void => {
  message.info(value, MsgOption);
};
export const warning = (value: string): void => {
  message.warning(value, MsgOption);
};

export const error = (value: string): void => {
  message.error(value, MsgOption);
};

export const alert = (message: string, type): void => {
  switch (type) {
    case "Error":
      error(message);
      break;
    case "Success":
      success(message);
      break;
    case "Warning":
      warning(message);
      break;
    default:
      info(message);
      break;
  }
};

export default {
  alert,
  success,
  warning,
  info,
  error,
  messagesResponse,
};
