import { RouteRecordRaw } from "vue-router";

const categories: RouteRecordRaw = {
  path: "categories",
  component: () => import("@/views/public/index.vue"),
  meta: {
    title: "categories",
  },
  children: [
    {
      path: "",
      name: "category",
      component: () => import("@/views/public/categories/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "categories",

      },
    },
    {
      path: "index",
      name: "category index",
      component: () => import("@/views/public/categories/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "categories",
      },
    },
    {
      path: "add",
      name: "Add New category",
      component: () => import("@/views/public/categories/pages/add.vue"),
      meta: {
        pageId: 18010400,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "Edit category",
      component: () => import("@/views/public/categories/pages/add.vue"),
      meta: {
        pageId: 18010400,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default categories;
