const old_ar = {
  messages: {
    "validation.messages.required": `هذا الحقل مطلوب`,
    "validation.messages.email": `هذا الحقل يجب ان يكون ايميل صحيح.`,
    "validation.attributes.email": `البريد الاليكتروني`,
  },
  isImport: "استيراد",
  isExport: "تصدير",
  docsAndComponents: "docsAndComponents",
  questions: "اسئلة الاختبار",
  insuranceNumber: "الرقم التأميني",
  hasInsurances: "مسجل بالتأمينات",
  qualification: "المؤهل",
  qualificationYear: "سنة التخرج",
  hiringDate: "تاريخ التعين",
  Job: "الوظيفة",
  CareerHistory: "التاريخ الوظيفي",
  Suspensions: "الايقافات والتعليقات",
  Workingchildren: "ابناء العاملين",
  ReportsandForms: "تقارير ونماذج",
  UserandPermissions: "المستخدم والصلاحيات",
  Viewdata: "عرض البيانات",
  ministerialCode: "الكود الوزاري",
  level: "المرحلة",
  StudentDistribution: "توزيع الطلاب",
  grade: "الصف",
  units: "الوحدات",
  classroom: "الفصل",
  Siblings: "الاخوة",
  storeCategories: "أقسام الموقع",
  SubscribeNewsletter: "الإشتراك بالنشرة البريدية",
  SubscribeMsg: "اشترك لتصلك أروع التخفيضات على بريدك الالكتروني ",
  customersServices: "خدمة العملاء",
  HelpAndInstructions: "المساعدة والتعليمات",
  Privacy: "بيان الخصوصية",
  Listbrands: "الماركات",
  ContactUs: "أتصل بنا",
  maintenanceRequest: "طلب صيانة",
  ComplaintsAndProposals: "الشكاوى و المقترحات",
  vacanciesJobs: "وظائف شاغرة",
  FestivalTerms: "شروط و احكام المهرجان",
  SiteMap: "خريطة الموقع",
  TodayOffers: "عروض اليوم",
  Stores: "المعارض",
  MaintenanceRequest: "طلب صيانة",
  Catalogue: " مجلة العروض ",
  WelcomeVisitor: "مرحبا بك",
  Please: "يرجى",
  OR: "أو",

  Budget: "الميزانية",
  NetValue: "صافى القيمة",

  FixedAssets: "الاصول الثابتة",
  CurrentAssets: "الاصول المتداولة",

  CurrentLiabilities: "الخصوم المتداولة",
  Ownership: "حقوق الملكية",

  FinancialCenterList: "قائمة المركز المالى",

  TotalGainsAndLossesRetandy: "اجمالى الارباح و الخسائر",

  Scheduling: "الجدولة",

  QuizStartDate: "تاريخ بدء الاختبار",

  Toorder: "لامر",
  FromAccount: "من حساب",
  ToAccount: "الي حساب",
  PaymentMethod: "نوع الدفع",
  AttachFile: "ارفاق مستند",

  Transfare: "تحويل",
  Cash: "نقدى",
  Check: "شيك",
  Network: "شبكة",

  GeneralVoucher: "سند صرف عام",
  GeneralReceiptVoucher: "سند قبض عام",

  VoucherNumber: "رقم السند",

  Amount: "القيمة",

  BondType: "نوع السند",

  BuyFixedAsset: "شراء اصل",
  SellFixedAsset: "بيع اصل",
  Receipts: "سند قبض",
  Expenses: "سند صرف",
  Refund: "اعادة تمويل",
  PenaltyPayment: "عقوبة الغاء",
  ForeignCurrency: "سند صرف عملة اجنبية",
  FixedAssetTransactions: "معاملات الاصول الثابتة",

  PaySafely: "إدفع بأمان",
  withSadadPayementSystem: "مع خدمات الدفع الالكتروني سداد",
  HomeDelivery: "توصيل منزلي",
  ShoppingCart: "سلة التسوق",
  AddEmailMsg: "بريدك الالكتروني هنا ..",
  AddPhoneMsg: "رقم هاتفك هنا ..",
  subscription: "اشتراك",
  SortFor: "رتب حسب",

  Sorting: "الترتيب",

  Loading: "جار التحميل",
  Discount: "خصم",
  Default: "الافتراضي",
  PriceHighToLow: "السعر:من الأعلى الى الأقل",
  PriceLowToHigh: "السعر:من الأقل الى الأعلى",
  MostViewed: "الأكثر مشاهدة",
  SpecialOffers: "عروض خاصة",
  UserName: "أسم المستخدم",
  Password: "كلمة المرور",
  Entry: "دخول",
  RememberMe: "حفظ كلمة المرور?",
  RegisterNow: "سجل الآن",
  NoAccount: "لا تمتلك حساب",
  EnterUserName: "ادخل اسم المستخدم",
  EnterPassword: "Enter Password",
  OutOfStock: "نفدة الكمية",
  currency: "جنية",
  AddToCart: "إضافة للسلة",
  Compare: "مقارنة",
  AddToFavorites: "اضافة للمفضلة",
  PleaseTypeSomething: "يرجى كتابة شيء ما",
  PhoneNumber: "الموبيل",
  ConfirmPassword: "تاكيد كلمة المرور",
  Department: "قسم | الاقسام",
  Price: "السعر",
  PriceFrom: "السعر من ",
  To: "الى",
  Pay: "الدفع",
  ContinueShopping: "متابعة التسوق",
  UnitPrice: "سعر الوحده",
  ProductDetails: "معلومات المنتج",

  ViewAll: "عرض الكل",
  ProductsFound: "منتج موجود",
  City: "المحافظة",
  Area: "المنطقة",
  address: "العنوان",
  ProductName: "اسم المنتج",
  TOTAL: "الاجمالى",
  Subtotal: "الاجمالى الفرعى",
  ProceedToCheckout: "الدفع و انهاء الشراء",
  Quicklinks: "روابط سريعة",
  AboutUs: "معلومات عنا",
  TermsAndCondition: "الشروط والأحكام",
  ReturnPolicy: "الإسترجاع و الإستبدال",
  Login: "تسجيل الدخول",
  Register: "تسجيل جديد",
  BestSellerBrands: "أفضل الماركات مبيعا",
  Model: "الموديل",
  Brand: "الماركة",
  Color: "اللون",
  quantity: "الكمية",
  Description: "الوصف",
  Specification: "الخصائص",
  whatAreYouLookingFor: "ما الذي تبحث عنه؟",
  logout: "تسجيل الخروج",
  Welcome: "مرحبا ",
  BanatEg: "بنات ايجي",
  BuyNow: "شراء الان",
  IAcceptTheLicenseAndTerms: "أوافق على الشروط و الاحكام",
  Brands: "الماركات",
  BrandsOffers: "عروض الماركات",
  chat: "المحادثة",
  empAndAdmin: "الموظفين و الادارة",
  personalData: "البيانات الشخصية",
  employeePrivileges: "صلاحيات الموظف",
  employeeRating: "تقييم الموظف",
  StudentRating: "تقييم الطالب",
  contactData: "بيانات الاتصال",
  Employeenumber: "رقم الموظف",
  Name: "الاسم",
  NameEn: "الاسم انجليزي",
  sex: "النوع",
  Religion: "الديانة",
  NationalID: "الرقم القومي",
  Nationality: "الجنسية",
  DateofBirth: "تاريخ الميلاد",
  BirthGovernorate: "محافظة الميلاد",
  phoneNumber: "رقم الاتصال",
  Email: "البريد الالكترونى",
  Accountlogindata: "بيانات الدخول الحساب",
  username: "اسم المستخدم",
  ResetPassword: "إعادة تعيين كلمة المرور",
  privilegesavailable: "الصلاحيات المتاحة",
  toCancelPrivileges: "برجاء الضغط على علامة الالغاء لحذف الصلاحيات ",
  toAddPrivileges: "برجاء الضغط على علامة الاضافة لتضاف الصلاحية  ",
  employee: "موظف",
  add: "اضافة",
  data: "بيانات",
  print: "طباعة",
  employeeData: "بيانات الموظف",
  password: "رقم سري",
  cancel: "الغاء",
  save: "حفظ",
  createLoginInfo: "إنشاء معلومات تسجيل الدخول",
  empPhoto: "صورة الموظف ",
  bookPhoto: "صورة الكتاب ",

  teacherPhoto: "صورة المدرس ",
  GuardianName: "اسم ولي الامر",
  ParentEnglishname: "اسم ولي الامر انجليزي ",
  relationship: "صلة القرابة",
  GroundPhoneNumber: "رقم التليفون الارضى",
  Parentmobilenumber: "رقم المحمول لولي الامر",
  MothernameEnglish: "اسم الام انجليزي ",
  Mothername: "اسم الام  ",

  nationalnumbermother: "الرقم القومى للام",
  Groundphonemother: "رقم التليفون الارضى للام",
  MobilenumberMother: "رقم المحمول للام",
  Studentphoto: "صورة الطالب",
  NationalNumberGuardian: "الرقم القومى لولي الامر",
  Neighborhood: "الحى",

  father: "الاب",
  Grandpa: "الجد",
  FirstName: "ألاسم الاول",
  LastName: "أسم الاخير",

  father_en: "الاب انجليزي",
  Grandpa_en: "الجد انجليزي",
  FirstName_en: "ألاسم الاول انجليزي",
  LastName_en: "أسم الاخير انجليزي",

  Mother: "الام",
  Brother: "الاخ",
  Sister: "الاخت",
  Grandma: "الجده",
  Student: "طالب",
  Teacher: "مدرس",
  Employee: "موظف",

  Muslim: "مسلم",
  Christian: "مسيحي",
  Male: "ذكر",
  Female: "انثي",
  NationalIdPlace: "الرقم القومى من البطاقة او شهادة الميلاد الكمبيوتر",
  back: "رجوع",
  Numberofpages: "عدد الصفحات",
  for: "لعدد",
  Search: "بحث",
  EditThis: "تعديل علي ",
  AddNew: "اضافة جديد ",
  StudentInformation: "بيانات الطالب ",
  Teachernumber: "رقم المدرس",
  Studentnumber: "رقم الطالب",

  Clickupload: "اضغط هنا لرفع الصورة",
  Academicyearandmaterials: "السنة الدراسية و المواد",
  TeacherRating: "تقييم المعلم",
  TeacherData: "بيانات المدرس",
  Guardianandmother: "ولي الامر و الام",
  Studentdebts: "مديونيات الطالب",
  Printstudentreports: "طباعة تقارير للطالب",
  approval: "تعميد",
  Cancelapproval: "الغاء الموافقة",
  Quizzes: "الاختبارات",
  quizzesManagement: "ادارة الاختبارات",
  CorrectQuizzes: "تصحيح الاختبارات",

  YearName: "السنة",
  DistributionDate: "تاريخ التوزيع",
  DistributionStatus: "حالة التوزيع",
  DistributionEmployeeName: "موظف التوزيع",
  DeleteEmployeeName: "موظف الحذف",
  DeleteDate: "تاريخ الحذف",

  Status: "الحالة",

  CloseDate: "تاريخ الاقفال",
  CloseStatus: "حالة الاقفال",
  CloseEmployeeName: "موظف الاقفال",
  DeleteCloseEmployeeName: "موظف الالغاء",
  DeleteCloseDate: "تاريخ الالغاء",

  StatementAccount: "البيان / الحساب",
  Firstbalance: "رصيد اول",
  Accountbalance: "رصيد الحساب",
  Closingbalance: "رصيد الاقفال",
  Movementsduringtheperiod: "الحركات خلال الفترة",
  Type: "النوع",
  Jobname: "اسم الوظيفة",
  Debit: "مدين",
  Creditor: "دائن",
  AllTransaction: "قيد مجمع",

  TransactionNo: "رقم القيد ",

  TransactionType: "نوع القيد",

  Total: "الاجمالى",
  Account: "الحساب",
  Accountnumber: "رقم الحساب",
  Levels: "المستويات",
  ShortNameArabic: "اسم المختصر عربي",
  ShortNameEnglish: "اسم المختصر انجليزي",
  SubjectName: "اسم المادة",
  ManagementAndDistribution: "الادارة والتوزيع",
  ItemName: "الاسم",
  numberOfUsers: "عدد المستخدمين",
  deActivate: "ايقاف التفعيل",
  All: "الكل",
  View: "عرض",
  Edit: "تعديل",
  Add: "اضافة",
  Delete: "حذف",
  Statistics: "احصائيات",
  Educationallevels: "المراحل التعليمية",
  Activatingtheeducationallevelsoftheschool: "تفعيل المراحل التعلميمية للمدرسة",
  AddLevel: "اضافة المرحلة",
  DeleteLevel: "حذف المرحلة",
  Note: "الملاحظه",
  Author: "المؤلف",
  Category: "الفئة",
  publishingHouse: "دار النشر",
  ItemCode: "الكود",
  ItemCount: "العدد",
  CreationDate: "تاريخ الادخال",
  Book: "الكتاب",
  CategoryName: "اسم القسم",
  AuthorName: "اسم المؤلف",
  PublishingHouses: "دار النشر",
  ReportsName: "اسم التقرير",
  ReportsHeaderAr: "العنوان عربي ",
  ReportsHeaderEn: "العنوان انجليزي  ",
  ReportSort: "الترتيب",
  Screen: "الشاشة",

  TRANSLATOR: {
    SELECT: "Select your language",
  },

  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",

      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
};

export default old_ar;
