import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      type: 'any',
      required: $props.required,
      message: $props.message,
      trigger: ['input', 'blur'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_input, {
        clearable: "",
        value: $setup.MyValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
        type: "textarea",
        disabled: $props.disabled,
        placeholder: $props.placeholder,
        maxlength: $props.maxlength,
        autosize: { minRows: 1, maxRows: 3 },
        "show-count": $props.showCount
      }, null, 8, ["value", "disabled", "placeholder", "maxlength", "show-count"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}