import { RouteRecordRaw } from "vue-router";

const EmailSetting: RouteRecordRaw = {
  path: "emailSetting",
  component: () => import("@/views/public/index.vue"),
  meta: {
    title: "أيملات النظام",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "emailSetting index",
      component: () => import("@/views/public/emailSetting/pages/index.vue"),
      meta: {
        pageId: 18030500,
        role: "view",
        title: "أيملات النظام",
      },
    },
    {
      path: "add",
      name: "Add New emailSetting",
      component: () => import("@/views/public/emailSetting/pages/add.vue"),
      meta: {
        pageId: 18030500,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "Edit emailSetting",
      component: () => import("@/views/public/emailSetting/pages/add.vue"),
      meta: {
        pageId: 18030500,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default EmailSetting;
