import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "element" }
const _hoisted_2 = {
  key: 0,
  class: "py-2"
}
const _hoisted_3 = { class: "name" }
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.model)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'IconMaterial',
      $props.small ? 's' : 'm',
      $props.model.sessionCount > 1 ? 'fl' : '',
    ]),
        style: _normalizeStyle([
      'background-color:' + $props.model.materialBackColor + ';',
      'color:' + $props.model.materialForeColor + ';',
      'width: 100%;',
    ])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", {
            class: "labelSessionId",
            style: _normalizeStyle(['color:' + $props.model.materialForeColor + ';'])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.model.sessionCount, (se, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (index > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, " - "))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString($props.model.sessionId + index), 1)
              ], 64))
            }), 128))
          ], 4),
          _createElementVNode("div", _hoisted_3, _toDisplayString($props.small || $props.model.sessionCount == 1
            ? $props.model.material_ShortName
            : $props.model.material_Name), 1),
          _createElementVNode("p", {
            class: "labelClassRoomName",
            style: _normalizeStyle(['color:' + $props.model.materialForeColor + ';']),
            title: $props.model.levelName
          }, [
            ($props.teacher)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString($props.model.gradeName) + " ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.model.classRoomName), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString($props.model.teacher_Name), 1)
                ], 64))
          ], 12, _hoisted_4)
        ])
      ], 6))
    : _createCommentVNode("", true)
}