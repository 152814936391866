import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import FormModule from "@/core/base/FormModule";
import Filter from "./models/filter";

@Module({ namespaced: true })
export default class AssetCategory extends FormModule {
  override endPoint = "AssetsCategories";
  override filter = new Filter();
}
