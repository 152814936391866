<template>
  <q-select
    filled
    class="sort"
    v-model="modelInput"
    :options="options"
    option-value="value"
    option-label="text"
    stack-label
    label="التريب"
    :display-value="` ${modelInput ? modelInput.text : 'الافتراضى'}`"
    @input="ChangeSort($event)"
  >
    <template v-slot:append>
      <q-icon
        v-if="modelInput !== null"
        class="cursor-pointer"
        name="clear"
        @click.stop="modelInput = null"
      />
    </template>
  </q-select>
</template>
<script>
export default {
  computed: {
    modelInput: {
      get: function () {
        if (this.value == 0) {
          return { text: this.$t("Default"), value: 0 };
        }
        return this.options.filter((v) => v.value == this.value)[0];
      },
      set: function (v) {
        this.$emit("input", v ? v.value : 0);
      },
    },
  },
  props: {
    options: Array,
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    ChangeSort: function (v) {
      this.$emit("ChangeSort", v);
    },
  },
};
</script>
