
export default {
  props: {
    to: String,
    icon: String,
    svg: String,
    text: String,
    color: { default: "primary", type: String },
  },
};
