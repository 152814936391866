
import { ref, computed } from "vue";
import { FormInst, useMessage } from "naive-ui";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "",
    },
    show: Boolean,
    bordered: {
      type: Boolean,
      required: false,
    },
    form: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const formRef = ref<FormInst | null>(null);
    const message = useMessage();

    const onCancel = () => {
      emit("onCancel");
    };
    const open = computed({
      get() {
        return props.show;
      },
      set(newValue) {
        console.log("set", newValue);
        alert("set");
        emit("update:show", newValue);
      },
    });

    function handleValidateClick(e: MouseEvent) {
      e?.preventDefault();
      console.log(formRef, formRef.value);
      formRef.value?.validate((errors) => {
        if (props.form.password && props.form.confirmPassword) {
          if (props.form.password != props.form.confirmPassword) {
            message.error("كلمة المرور غير متطابقة");
          }
        }
        if (!errors) {
          emit("onSubmit");
        } else {
          console.log(errors);
        }
      });
    }

    return { handleValidateClick, formRef, open, onCancel };
  },
};
