import { RouteRecordRaw } from "vue-router";
const Reports: RouteRecordRaw = {
  path: "reports",
  component: () => import("@/views/public/index.vue"),
  meta: {
    title: "Reports",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "reports",
      component: () => import("@/views/public/system/reports/pages/index.vue"),
      meta: {
        pageId: 18050100,
        role: "view",
        title: "Reports",
      },
    },
    {
      path: "Add/:Id",
      props: true,
      name: "Add report from screen",
      component: () => import("@/views/public/system/reports/pages/add.vue"),
      meta: {
        pageId: 18050100,
        role: "edit",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "Edit report",
      component: () => import("@/views/public/system/reports/pages/add.vue"),
      meta: {
        pageId: 18050100,
        role: "edit",
        title: "Edit",
      },
    },
    {
      path: "AddTemp/:Id",
      props: true,
      name: "Add Templates from screen",
      component: () =>
        import("@/views/public/system/reports/pages/addTemplates.vue"),
      meta: {
        pageId: 18050100,
        role: "edit",
        title: "AddNew",
      },
    },
    {
      path: "EditTemp/:Id",
      props: true,
      name: "Edit-template",
      component: () =>
        import("@/views/public/system/reports/pages/addTemplates.vue"),
      meta: {
        pageId: 18050100,
        role: "edit",
        title: "Edit",
      },
    },

  ],
};

export default Reports;
