import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_auto_complete = _resolveComponent("n-auto-complete")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('InputSuggestArea col-md-' + $props.col + '  mb-' + $props.mb)
  }, [
    _createVNode(_component_n_form_item, {
      rule: {
        required: $props.required,
        message: $props.message,
        trigger: ['input', 'blur'],
      },
      label: $props.label,
      path: $props.path,
      "show-label": $props.ShowLabel,
      "require-mark-placement": "right-hanging",
      "show-require-mark": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_n_auto_complete, {
          clearable: "",
          value: $setup.MyValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
          size: "medium",
          disabled: $props.disabled,
          placeholder: $props.placeholder,
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
          ref: $props.path,
          "input-props": {
          autocomplete: 'disabled',
        },
          options: $setup.options,
          onSelect: $setup.onSelect
        }, null, 8, ["value", "disabled", "placeholder", "options", "onSelect"])
      ]),
      _: 1
    }, 8, ["rule", "label", "path", "show-label"])
  ], 2))
}