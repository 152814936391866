import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert alert-dismissible bg-light-info d-flex flex-column flex-sm-row w-100 p-5 mb-10" }
const _hoisted_2 = { class: "d-flex flex-column pe-0 pe-sm-10" }
const _hoisted_3 = { class: "fw-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass([
        'svg-icon  svg-icon-info me-4 mb-5 mb-sm-0',
        $props.large ? 'big-svg' : 'svg-icon-3hx',
      ])
    }, [
      _createVNode(_component_inline_svg, { src: $props.svg }, null, 8, ["src"])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString($props.title), 1),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}