export class Role {
  RoleId = 0;
  ScreenId = 0;
  IsAdd = false;
  IsEdit = false;
  IsView = false;
  IsDelete = false;
  IsPrint = false;
  IsStatistics = false;
  isExport = false;
  isImport = false;
}

export default {
  Role,
};
