import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-xl-stretch mb-5 mb-xl-8 blockData" }
const _hoisted_2 = { class: "card-title fw-bolder align-items-start flex-column m-0" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "mt-1 fw-bold fs-7 text-muted pt-1 pb-0" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "col-xl-12 row table-responsive" }
const _hoisted_7 = { class: "card-body py-6 row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('mb-5 col-md-' + $props.col)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.OpenBlock = !$setup.OpenBlock)),
        class: "card-header flex-start border-0 py-5 pointer"
      }, [
        _createElementVNode("i", {
          class: _normalizeClass($props.icon ?? 'fa-duotone fa-database'),
          style: {"color":"#0763c1 !important"}
        }, null, 2),
        _createElementVNode("h3", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($props.label), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString($props.info), 1),
          _renderSlot(_ctx.$slots, "info")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "toolbar")
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_7, [
        _renderSlot(_ctx.$slots, "default")
      ], 512), [
        [_vShow, $setup.OpenBlock]
      ])
    ])
  ], 2))
}