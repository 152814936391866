<template>
  <router-link
    :to="Cul(to)"
    :class="[
      'btn',
      `btn-icon btn-bg-light btn-active-color-${color}`,
      'btn-sm me-1',
    ]"
    icon="fa-layer-plus"
    v-if="IsAdd() && to"
  >
    <i class="fa-solid fa-plus"></i>
    {{ text }}
  </router-link>
  <div
    v-else
    :class="[
      'btn',
      `btn-icon btn-bg-light btn-active-color-${color}`,
      'btn-sm me-1',
    ]"
  >
    <span class="svg-icon svg-icon-3">
      <i class="fa-solid fa-plus"></i>
    </span>
  </div>
</template>
<script>
export default {
  name: "AAdd",
  props: {
    to: String,
    text: String,
    color: {
      type: String,
      default: "success",
    },
  },
};
</script>
