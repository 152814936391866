import { Module } from "vuex-module-decorators";
import Filter from "./models/filter";
import FormModule from "@/core/base/FormModule";
import { Action } from "vuex-module-decorators";
import { Role } from "./models/Role";

@Module({ namespaced: true })
export default class Roles extends FormModule {
  override endPoint = "Roles";
  override filter = new Filter();

  @Action({ rawError: true })
  async SaveRoleScreens(model) {
    const pData: Array<Role> = new Array<Role>();
    console.log("Role Model ", model);
    model.module.forEach((module) => {
      Object.keys(module.screens).forEach((i) => {
        const obj = new Role();
        (obj.RoleId = model.role.id),
          (obj.ScreenId = module.screens[i].screenId),
          (obj.IsAdd = module.screens[i].isAdd),
          (obj.IsEdit = module.screens[i].isEdit),
          (obj.IsView = module.screens[i].isView),
          (obj.IsDelete = module.screens[i].isDelete),
          (obj.IsPrint = module.screens[i].isPrint),
          (obj.IsStatistics = module.screens[i].isStatistics),
          (obj.isExport = module.screens[i].isExport),
          (obj.isImport = module.screens[i].isImport),
          pData.push(obj);

        Object.keys(module.screens[i].children).forEach((p) => {
          const obj = new Role();
          (obj.RoleId = model.role.id),
            (obj.ScreenId = module.screens[i].children[p].screenId),
            (obj.IsAdd = module.screens[i].children[p].isAdd),
            (obj.IsEdit = module.screens[i].children[p].isEdit),
            (obj.IsView = module.screens[i].children[p].isView),
            (obj.IsDelete = module.screens[i].children[p].isDelete),
            (obj.IsPrint = module.screens[i].children[p].isPrint),
            (obj.IsStatistics = module.screens[i].children[p].isStatistics),
            (obj.isExport = module.screens[i].children[p].isExport),
            (obj.isImport = module.screens[i].children[p].isImport),
            pData.push(obj);
        });
      });
    });

    const { context, endPoint } = this;
    let resValue = null;
    await context.dispatch("Post", {
      url: endPoint + "/SaveScreens",
      form: pData,
      func: (res) => {
        // console.log("Res ==>", res);
        resValue = res;
      },
    });
    return resValue;
  }
}

// import ApiService from "@/core/services/ApiService";
// import Filter from "./models/filter";
// import { app } from "@/core/services/_app";
// import Role from "@/views/public/system/roles/js/role.js";
// import router from "@/router";
// import { PushRouter, SetStartPageQueryString } from "@/core/base/app";

// const state = {
//   model: [],
//   roleScreen: [],
//   pager: [],
//   loading: false,
//   filter: new Filter(),
//   filterQuery: null,
//   role: new Role(),
// };

//   async EditRoleScreens({ dispatch, state }, model) {
//     state.loading = true;
//     await ApiService.post("/Roles/EditRoleScreens", model)
//       .then((response) => {
//         const res = response.data;
//         dispatch("GetRoleScreens", model.RoleId);
//         dispatch("alert/Api", res.messages, { root: true });
//         state.loading = false;
//       })
//       .catch(function (error) {
//         dispatch("alert/Error", error, { root: true });
//         state.loading = false;
//       });
//   },
//   async RemoveRole({ dispatch, state }, id) {
//     const model = { RoleId: id };
//     state.loading = true;

//     app.AlertDelete("سوف يتم حذف الصلاحية").then(async (result) => {
//       if (result.isConfirmed) {
//         await ApiService.post("/Roles/Delete", model)
//           .then((response) => {
//             const res = response.data;
//             dispatch("alert/Api", res.messages, { root: true });
//             dispatch("fetchData");
//             state.loading = false;
//           })
//           .catch(function (error) {
//             dispatch("alert/Error", error, { root: true });
//             state.loading = false;
//           });
//       } else {
//         state.loading = false;
//       }
//     });
//   },
// };

// const mutations = {
//   SetData(state, model) {
//     state.model = model.list;
//     state.pager = model.pager;
//   },
//   SetRole(state, role) {
//     state.role = role;
//   },
//   SetRoleScreen(state, role) {
//     state.roleScreen = role;
//   },
// };
// const getters = {};

// export const Roles = {
//   namespaced: true,
//   state,
//   actions,
//   getters,
//   mutations,
// };
