export const CreateMenu = (editor, menu, MenuItem): void => {
  if (menu) {
    var items: any = []
    menu.forEach((element) => {
      var itemsChildren: any = [];
      element.fields.forEach((field) => {
        if (field.children != null && field.children.length > 0) {
          var children: any = [];
          field.children.forEach(child => {
            children.push({
              type: 'menuitem',
              text: child.name,
              onAction: function () {
                editor.insertContent(
                  ` <span id='${child.value}' contenteditable='false'>${child.name}</span> `
                );
              }
            })
          });

          itemsChildren.push({
            type: 'nestedmenuitem',
            text: field.name,
            getSubmenuItems: function () {
              return children;
            }
          });
        } else {
          if (field.type == 2) {
            itemsChildren.push({
              type: "menuitem",
              text: field.name,
              onAction: function () {
                editor.insertContent(
                  ` <img  id='${field.value}' src='${field.value}' alt=''  width='200' height='200' contenteditable='false' />`
                );
              },
            });
          } else {
            itemsChildren.push({
              type: 'menuitem',
              text: field.name,
              onAction: function () {
                editor.insertContent(
                  ` <span id='${field.value}' contenteditable='false'>${field.name}</span> `
                );
              },
            });
          }
        }
      })
      items.push({
        type: 'nestedmenuitem',
        text: element.menuName,
        getSubmenuItems: function () { return itemsChildren }
      });
    })
    editor.ui.registry.addMenuButton('mybutton', {
      text: 'حقول التقرير',
      fetch: function (callback) {
        callback(items);
      }
    })
  }
};

export default {
  CreateMenu,
};
