import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($props.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.Cul($props.to),
        class: _normalizeClass(`btn btn-sm btn-light-${$props.color} pulse pulse-${$props.color} mx-2`),
        icon: "fa-layer-plus"
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass($props.icon)
          }, null, 2),
          _createTextVNode(" " + _toDisplayString($props.text), 1)
        ]),
        _: 1
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(`btn btn-sm btn-light-${$props.color} pulse pulse-${$props.color} mx-2`),
        icon: "fa-layer-plus"
      }, [
        _createElementVNode("i", {
          class: _normalizeClass($props.icon)
        }, null, 2),
        _createTextVNode(" " + _toDisplayString($props.text), 1)
      ], 2))
}