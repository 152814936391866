<template>
  <router-link
    v-if="to"
    :to="Cul(to)"
    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
  >
    <span class="svg-icon svg-icon-3">
      <inline-svg :src="src"></inline-svg>
    </span>
  </router-link>
  <span
    v-else
    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
  >
    <span class="svg-icon svg-icon-3">
      <inline-svg :src="src"></inline-svg>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    to: String,
    src: String,
  },
};
</script>
