import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import FormModule from "@/core/base/FormModule";
import Filter from "./models/filter";

@Module({ namespaced: true })
export default class Assets extends FormModule {
  override endPoint = "Assets";
  override filter = new Filter();

  @Mutation
  override SetModels(models) {
    this.models = models.managements.list;
    this.pager = models.managements.pager;
    this.reports = models.managements.reports;
  }

  @Mutation
  SetAssetsCategoryId(id) {
    this.filter.AssetsCategoryId = id;
  }

  @Action
  Destruction(model) {
    const { context, endPoint } = this;
    return context.dispatch("Post", {
      url: endPoint + "/Destruction",
      form: model,
      commitName: "SetModel",
    });
  }
}
