import { RouteRecordRaw } from "vue-router";

const companies: RouteRecordRaw = {
  path: "companies",
  component: () => import("@/views/companies/index.vue"),
  meta: {
    title: "الشركات",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-companies",
      component: () => import("@/views/companies/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "الشركات",
      },
    },
    {
      path: "add",
      name: "add-company",
      component: () => import("@/views/companies/pages/add.vue"),
      meta: {
        pageId: 18010400,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "edit-company",
      component: () => import("@/views/companies/pages/add.vue"),
      meta: {
        pageId: 18010400,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default companies;
