import { RouteRecordRaw } from "vue-router";
import EmailSetting from "@/views/public/emailSetting/router";
import Reports from "@/views/public/system/reports/router";
import Roles from "@/views/public/system/roles/router";
import Settings from "@/views/public/settings/router";
import Countries from "@/views/public/countries/router";
import cities from "@/views/public/cities/router";
import Jobs from "@/views/public/jobs/router";
import Categories from "@/views/public/categories/router";
import Additions from "@/views/public/additions/router";
import Products from "@/views/public/products/router";
import CompanyInfo from "@/views/public/companyInfo/router";

const Public: RouteRecordRaw = {
  path: "sys",
  component: () => import("@/views/public/index.vue"),
  children: [
    Reports,
    Roles,
    Settings,
    EmailSetting,
    Countries,
    cities,
    Jobs,
    Categories,
    Additions,
    Products,
    CompanyInfo,
  ],
  meta: {
    title: "General",
    module: "sys",
    svgIcon: "/media/icons/duotune/general/gen019.svg",
  },
};
export default Public;
