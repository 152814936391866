import { RouteRecordRaw } from "vue-router";

const products: RouteRecordRaw = {
  path: "products",
  component: () => import("@/views/reports/index.vue"),
  meta: {
    title: "تقرير المنتجات",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-products",
      component: () => import("@/views/reports/products/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "تقرير المنتجات",
      },
    },
  ],
};

export default products;
