
import { ref, h, VNodeChild, onMounted, watch, computed } from "vue";
import { NIcon, SelectOption, SelectGroupOption } from "naive-ui";
import { useStore } from "vuex";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    info: String,
    url: {
      type: [String],
    },
    storeName: {
      type: [String],
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    path: {
      type: String,
      default: "",
    },
    value: [Number, String, Array],
    ShowLabel: {
      type: Boolean,
      default: (props) => props.label != null,
    },
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: 1,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اختار " + (props.label == null ? "" : props.label),
    },
    message: {
      type: String,
      required: false,
      default: (props) => {
        if (props.label) {
          return "برجاء اختيار " + props.label + "  مطلوب";
        } else {
          return "برجاء الاختيار مطلوب";
        }
      },
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    filterable: {
      type: Boolean,
      required: false,
    },
    showArrow: {
      type: Boolean,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });
    const options = computed(
      () => store.getters[`${props.storeName}/lookupsFromServe`]
    );
    const renderLabel = (option: SelectOption): VNodeChild => {
      if (option.required) {
        return option.label + "*";
      }
      return option.label + "";
    };
    watch(
      () => props.url,
      async () => {
        await GetData();
      }
    );

    onMounted(async () => {
      if (props.multiple && props.value) {
        if (typeof props.value == typeof 0) {
          emit("update:value", [props.value]);
        }
      } else {
        MyValue.value = isNaN(parseFloat(props.value))
          ? null
          : parseFloat(props.value);
      }
      await GetData();
    });
    const GetData = async () => {
      await store.commit(`${props.storeName}/PartialLoading`);
      await store.dispatch(`${props.storeName}/GetFromServer`, props.url);
    };
    const UpdateValue = (value) => {
      if (value) {
        emit("update:value", value);
      } else {
        emit("update:value");
      }
    };
    return { MyValue, UpdateValue, renderLabel, options };
  },
};
