import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { NetworkError } from "@/core/plugins/notify";
import LanguageService from "@/core/services/language";
import { messagesResponse, error, success } from "@/core/base/systemAlert";

import router from "@/router";
export interface User {
  email: string;
  image: string;
  name: string;
  phoneNumber: string;
  userName: string;
  userTypeId: string;
}

export interface UserAuthInfo {
  user: User;
  access_Token: string | null;
  isAuthenticated: boolean;
  culture: string;
  schoolId: number;
}

export interface AuthorizePage {
  Role: AuthorizeType;
  PageId: number;
}

enum AuthorizeType {
  add = 1,
  edit = 2,
  view = 3,
  statistics = 4,
  print = 5,
  delete = 6,
}
import BaseModule from "@/core/base/BaseModule";
import { Config } from "@/core/services/config";
@Module({ namespaced: true })
export default class Identity extends BaseModule implements UserAuthInfo {
  endPoint = "Account";
  access_Token = JwtService.get();
  isAuthenticated = !!this.access_Token;
  culture = "ar";
  isOnline = false;
  user = {} as User;
  schoolId: number;
  get GetIsOnline() {
    return this.isOnline;
  }
  @Mutation
  SetIsOnline(val: boolean) {
    //console.log("fasdfdsa", val)
    this.isOnline = val;
  }

  // email = null;
  // image = null;
  // name = null;
  // phoneNumber = null;
  // schoolId = null;
  // userName = null;
  // typeId = null;

  roles = [];

  loading = false;
  messages = null;
  registerCompleted = false;
  Profile = null;
  CheckAccountModel = null;

  errors = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  // get UserId(): string {
  //   return this.user.UserId;
  // }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    console.log("isAuthenticated", this.isAuthenticated, !!this.access_Token);
    return this.isAuthenticated;
  }

  /**
   * Get getErrors errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  get GetTypeId() {
    return this.user.userTypeId;
  }

  get GetLoading() {
    return this.loading;
  }
  @Mutation
  SetLoading(value: boolean) {
    this.loading = value;
  }

  get GetRoles() {
    return this.roles;
  }

  @Mutation
  SetAccountData(Model) {
    this.user.email = Model.email;
    if (Model.image) {
      this.user.image = `${Config.API_URL}up/Profile/${Model.image}`;
    }
    this.culture = Model.languageId == 1 ? "ar" : "en";
    this.user.name = Model.name ?? Model.userName;;
    this.user.phoneNumber = Model.phoneNumber;
    this.schoolId = Model.schoolId;
    this.user.userName = Model.userName;
    this.user.userTypeId = Model.userTypeId;
  }

  @Mutation
  SetRoles(rolesModel) {
    this.roles = rolesModel;
  }

  @Mutation
  setAuth(Model) {
    this.errors = [];
    JwtService.save(Model.access_Token);
    LanguageService.SetDirection(Model.culture);
    this.access_Token = JwtService.get();
    this.isAuthenticated = true;
    this.CheckAccountModel = null
    console.log("isAuthenticated ", this.isAuthenticated);
  }

  @Mutation
  setUser(user) {
    this.user = user;
  }

  // @Mutation
  // setPassword(password) {
  //   this.user.password = password;
  // }

  @Mutation
  logOut() {
    this.errors = [];
    this.isAuthenticated = false;
    JwtService.destroy();
  }

  @Action
  async login(credentials) {
    const { context, endPoint } = this;
    let resValue = null;
    await context.dispatch("Post", {
      url: endPoint + "/LogIn",
      form: credentials,
      func: (res) => {
        resValue = res;
        if (res.isValid) {
          context.commit("setAuth", res.model);
          ApiService.setHeader();
        }
      },
    });
    return resValue;
  }

  @Action
  login2(credentials) {
    console.log("credentials", credentials);
    this.context.commit("SetLoading", true);
    return new Promise<void>((resolve, reject) => {
      ApiService.post("Account/LogIn", credentials)
        .then(({ data }) => {
          if (data.isValid) {
            this.context.commit("setAuth", data.model);
            ApiService.setHeader();
            this.context.commit("SetLoading", false);
            resolve();
          } else {
            console.log("Login Error ==> ", data.messages);
            this.context.commit(Mutations.SET_ERROR, data.messages);
            this.context.commit("SetLoading", false);
            reject();
          }
        })
        .catch(NetworkError);
    });
  }


  @Mutation
  SetCheckAccount(model) {
    this.CheckAccountModel = model;
    if (model) {
      JwtService.save(model.token);
      ApiService.setHeader();
    }
  }





  @Action
  CheckAccount(model) {
    this.context.commit("SetLoading", true);
    return new Promise<void>((resolve, reject) => {
      ApiService.post("Account/CheckAccount", model)
        .then(({ data }) => {
          if (data.isValid) {
            this.context.commit("SetLoading", false);
            resolve();
          } else {
            this.context.commit("SetLoading", false);
            reject();
          }
          this.context.commit("SetCheckAccount", data.model);
          messagesResponse(data.messages);
        })
        .catch(NetworkError);
    });
  }
  @Action
  RegisterAccount(model) {
    this.context.commit("SetLoading", true);
    return new Promise<void>((resolve, reject) => {
      ApiService.post("Account/RegisterAccount", model)
        .then(({ data }) => {
          if (data.isValid) {
            this.context.commit("SetLoading", false);
            resolve(data);
          } else {
            this.context.commit("SetLoading", false);
            reject(data);
          }
          messagesResponse(data.messages);
        })
        .catch(NetworkError);
    });
  }
  @Action
  logout() {
    this.context.commit("logOut");
  }

  @Action
  register(credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit("setAuth", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  forgotPassword(payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit("setAuth", data);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  verifyAuth() {
    if (!JwtService.get()) {
      console.log("logOut");
      this.context.commit("logOut");
    }
  }

  @Action
  updateUser(payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  IsAuthorize(model: AuthorizePage) {
    const pageId =
      model.PageId == null
        ? router.currentRoute.value.meta["pageId"]
        : model.PageId;

    const s = this.roles.filter((v) => v["screenId"] == pageId)[0];
    console.log("IsAuthorize =>", model, pageId, this.roles.values, s["isAdd"]);
    if (s) {
      switch (model.Role) {
        case AuthorizeType.add:
          return s["isAdd"];
        case AuthorizeType.edit:
          return s["isEdit"];
        case AuthorizeType.view:
          return s["isView"];
        case AuthorizeType.statistics:
          return s["isStatistics"];
        case AuthorizeType.print:
          return s["isPrint"];
        case AuthorizeType.delete:
          return s["isDelete"];
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}
