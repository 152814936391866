import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "loadingBarTargetRef",
  style: {"position":"absolute","inset":"0","border-radius":"var(--n-border-radius)","overflow":"hidden","pointer-events":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!

  return (_openBlock(), _createBlock(_component_n_loading_bar_provider, {
    to: _ctx.loadingBarTargetRef,
    "container-style": "position: absolute;"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, null, 512),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to"]))
}