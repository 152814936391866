
import { PropType } from "vue";
export default {
  name: "wItem",
  inheritAttrs: false,
  props: {
    svg: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    to: {
      type: Object,
    },
  },
};
