import { RouteRecordRaw } from "vue-router";
const Debentures: RouteRecordRaw = {
  path: "debenture",
  component: () => import("@/views/accounts/index.vue"),

  meta: {
    title: "Debentures",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "debenture",
      component: () => import("@/views/accounts/debentures/pages/index.vue"),
      meta: {
        pageId: 17010200,
        role: "view",
        title: "PublicDebentures",

      },
    },
    {
      path: "Add/:Id",
      name: "Add New debenture",
      component: () => import("@/views/accounts/debentures/pages/add.vue"),
      meta: {
        pageId: 17010200,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "Edit debenture",
      component: () => import("@/views/accounts/debentures/pages/add.vue"),
      meta: {
        pageId: 17010200,
        role: "edit",
        title: "Edit",
      },
    },

    {
      path: "view/:Id",
      props: true,
      name: "debenture-id",
      component: () => import("@/views/accounts/debentures/pages/view.vue"),
      meta: {
        pageId: 17010200,
        role: "view",
        title: "PublicDebentures",
      },
    },
  ],
};

export default Debentures;
