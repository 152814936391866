<template>
  <div class="rowid">
    <span class="text-muted rid">{{ rowId }}</span>
    <span class="text-dark-75 iid font-weight-bolder d-block font-size-lg">
      {{ itemId }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    rowId: Number,
    itemId: Number,
  },
};
</script>
