
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    multiple: {
      type: Boolean,
      required: false,
    },
    path: {
      type: String,
      default: "",
    },
    value: [String, Number, Array],
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    max: {
      type: Number,
      required: false,
      default: 1,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اختار " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => {
        if (props.label) {
          return "برجاء اختيار " + props.label + "  مطلوب";
        } else {
          return "برجاء الاختيار مطلوب";
        }
      },
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    root: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    filterable: {
      type: Boolean,
      required: false,
    },
    showArrow: {
      type: Boolean,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    let MyValue = ref();
    const path = props.root;
    if (props.value) MyValue.value = props.value.toString().split(",");
    const UpdateValue = (value) => emit("update:value", value.toString());

    const store = useStore();
    const options = computed(() => store.getters[`${path}/GetSearchData`]);

    onMounted(() => {
      if (props.multiple && props.value) {
        MyValue.value = props.value.split(",");
        MyValue.value = MyValue.value?.map((str) => parseInt(str));
      } else {
        MyValue.value = isNaN(parseFloat(props.value))
          ? null
          : parseFloat(props.value);
      }
      handleSearch(MyValue.value);
    });

    const GetOptions = async (query) => {
      await store.commit(`${props.root}/PartialLoading`);
      await store.dispatch(`${props.root}/RemoteSearch`, query);
    };

    const loading = ref(false);

    const handleSearch = async (query: string) => {
      if (query) {
        loading.value = true;
        await GetOptions(query);
        loading.value = false;
      }
    };

    return {
      MyValue,
      UpdateValue,
      selectedValues: ref(null),
      loading,
      options,
      handleSearch,
    };
  },
};
