
import { ar } from "date-fns/locale";
import { ArrowRight } from "@element-plus/icons-vue";
export default {
  name: "AccountViewName",
  inheritAttrs: false,
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { ar, ArrowRight };
  },
};
