import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import * as signalR from "@microsoft/signalr";
import { Config } from "@/core/services/config";
import JwtService from "@/core/services/JwtService";
@Module({ namespaced: true })
export default class LookUps extends VuexModule {
  errors = [];
  cities = [];
  jobs = [];
  branches = [];
  countries = [];
  categories = [];
  additions = [];
  language = "ar";
  taxSettings = {};
  roles = [];
  customers = [];
  @Action
  ChangeLanguage(lang: string) {
    this.context.commit("SetLanguage", lang);
  }

  @Mutation
  SetLanguage(lang) {
    this.language = lang;
  }
  get GetLanguage(): string {
    return this.language;
  }
  get GetJobs() {
    return this.cities;
  }

  get GetCities() {
    return this.cities;
  }

  get GetCountries() {
    return this.countries;
  }
  get GetCategories() {
    return this.categories;
  }

  get GetAdditions() {
    return this.additions;
  }
  get GetBranches() {
    return this.branches;
  }
  get GetCustomers() {
    return this.customers;
  }
  @Mutation
  SetLookUps(model) {
    if (model.cities) this.cities = model.cities;
    if (model.countries) this.countries = model.countries;
    if (model.jobs) this.jobs = model.jobs;
    if (model.roles) this.roles = model.roles;
    if (model.categories) this.categories = model.categories;
    if (model.additions) this.additions = model.additions;
    if (model.branches) this.branches = model.branches;
    if (model.customers) this.customers = model.customers;
    //this.taxSettings = model.lookups.taxSettings;
  }

  @Action
  fetchLookUps() {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("Home")
        .then(({ data }) => {
          console.log(JSON.stringify(data));
          if (data.isValid) {
            this.context.commit("SetLookUps", data.model);
            resolve();
          } else {
            reject();
          }
        })
        .catch(({ response }) => {
          console.log(response);
          reject();
        });
    });
  }
}
