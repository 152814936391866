export const Config = {
  API_URL: "/api",
  POST_MEDIA: "up/CloudStorage/",
  POST_MEDIA_Temp: "up/post/",
};

if (process.env.NODE_ENV === "development") {
  Config.API_URL = "https://sales.musa3ed.com/api/";
  // Config.API_URL = "https://localhost:44363/";
  Config.POST_MEDIA = Config.API_URL + "up/CloudStorage/";
  Config.POST_MEDIA_Temp = Config.API_URL + "up/post/";
}

export const UploadUrl = (): string | null => {
  return `${Config.API_URL}/up/`;
};

export default {
  Config,
  UploadUrl,
};
