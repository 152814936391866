import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import FormModule from "@/core/base/FormModule";

@Module({ namespaced: true })
export default class Companies extends FormModule {
  override endPoint = "companies";
  _registerModel = {};

  get registerModel() {
    return this._registerModel;
  }

  @Mutation
  SetRegisterModel(v) {
    this._registerModel = v;
  }

  @Action
  async RegisterNewCompany(model) {
    const { context, endPoint } = this;
    await context.dispatch("Post", {
      url: endPoint + "/RegisterNewCompany",
      form: model,
      func: (res) => {
        context.commit("SetRegisterModel", res);
      },
    });
  }
}
