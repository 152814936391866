import Notify from 'quasar/src/plugins/Notify.js';;

export const Info = (message: string): void => {
  console.log("Info", message);
  Notify.create({
    message: message,
    color: "indigo-6",
    position: "bottom-right",
  });
};
export const Success = (message: string): void => {
  console.log("Success", message);
  Notify.create({
    message: message,
    color: "primary",
    position: "bottom-right",
  });
};
export const Error = (message: string): void => {
  console.log("Error", message);
  console.log(message, Notify);

  //   Notify.create("Danger, Will Robinson! Danger!");
  //   // or with a config object:
  //   Notify.create({
  //     message: "Danger, Will Robinson! Danger!",
  //   });

  //   Notify.registerType("my-notif", {
  //     icon: "announcement",
  //     progress: true,
  //     color: "brown",
  //     textColor: "white",
  //     classes: "glossy",
  //   });

  //   Notify.create({
  //     message: message,
  //     color: "deep-purple-8",
  //     position: "bottom-right",
  //   });
};
export const Warning = (message: string): void => {
  console.log("Warning", message);
  Notify.create({
    message: message,
    color: "red",
    position: "bottom-right",
  });
};
export const Trace = (message: string): void => {
  console.log("Trace", message);
  Notify.create({
    message: message,
    timeout: 200,
    classes: "TraceNotify text-black",
    position: "bottom-left",
  });
};
// export const clear = (): void => {
//   state.type = null;
//   state.message = null;
// };

export const NetworkError = (response: any): void => {
  console.log("NetworkError", response);
  Error("خطا اثناء محاول الوصول الى السيرفر");
};

export default {
  Trace,
  Warning,
  Error,
  Success,
  Info,
  NetworkError,
};
