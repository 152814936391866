class Enums {
  GenderType = [
    { value: 1, label: "ذكر" },
    { value: 2, label: "انثي" },
  ];

  PaymentType = [
    { value: 1, label: "تحويل" },
    { value: 2, label: "نقدى" },
    { value: 3, label: "شيك" },
    { value: 4, label: "شبكة" },
  ];

  DebentureType = [
    { value: 1, label: "سند صرف عام" },
    { value: 2, label: "سند قبض عام" },
    { value: 3, label: "سند قبض" },
    { value: 4, label: "سند صرف" },
    { value: 5, label: "اعادة تمويل" },
    { value: 6, label: "عقوبة الغاء" },
    { value: 7, label: "سند صرف عملة اجنبية" },
    { value: 8, label: "معاملات الاصول الثابتة" },
  ];

  PaymentMethod = [
    { value: 1, label: "تحويل" },
    { value: 2, label: "نقدى" },
    { value: 3, label: "شيك" },
    { value: 4, label: "شبكة" },
  ];
  CompanyType = [
    { value: 1, label: "قطاع الأطعمة والمشروبات" },
    { value: 2, label: "القطاع العقاري" },
    { value: 3, label: "قطاع التجزئة" },
    { value: 4, label: " قطاع المقاولات" },
    { value: 5, label: "قطاع المحروقات" },
    { value: 6, label: "الضيافة والترفيه" },
    { value: 7, label: "قطاع التأجير" },
  ];

  getString(array, value) {
    return array.find((item) => item.value === value)?.label || "";
  }

  registerEnums() {
    for (const key in this) {
      if (Array.isArray(this[key])) {
        const methodName = key + "String";
        this[methodName] = (value: number) => this.getString(this[key], value);
      }
    }
  }
}

const enums = new Enums();
enums.registerEnums();

export default enums;
