<template>
  <router-link
    v-if="to"
    :to="Cul(to)"
    :class="`btn btn-light-${color} pulse pulse-${color} me-3  ms-3`"
    icon="fa-layer-plus"
  >
    <i :class="icon"></i>
    {{ text }}
  </router-link>
  <div
    v-else
    :class="`btn btn-light-${color} pulse pulse-${color}`"
    icon="fa-layer-plus"
  >
    <i :class="icon"></i>
    {{ text }}
  </div>
</template>
<script>
export default {
  props: {
    to: String,
    icon: String,
    text: String,
    color: {
      type: String,
      required: false,
      default: "success",
    },
  },
};
</script>
