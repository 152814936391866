<template>
  <div style="position: relative">
    <div class="file-input" v-if="value == null">
      <input
        type="file"
        id="file"
        class="file"
        accept="image/*"
        @change="handleSelects"
      />
      <p>{{ title }}</p>
      <label for="file">
        <div
          class="dropzone dropzone-default dropzone-muted dz-clickable"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(/media/svg/shapes/abstract-4.svg);
          "
        >
          <div class="dropzone-msg dz-message needsclick">
            <h3 class="dropzone-msg-title">
              {{ $t("Clickupload") }}
            </h3>
            <!-- <span class="dropzone-msg-desc">Upload up to 10 files</span> -->
          </div>
        </div>
      </label>
    </div>
    <div class="PreviewImage" v-else>
      <div
        class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
        v-on:click="RemoveImage"
      >
        <span class="svg-icon svg-icon-xl svg-icon-primary">
          <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
        </span>
      </div>
      <!-- 
      <div
        class="btn btn-icon btn-edit btn-dropdown btn-lg mr-1"
        v-on:click="openDialog = true"
      >
        <span class="svg-icon svg-icon-xl svg-icon-primary">
          <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
        </span>
      </div> -->
      <img :src="GetImageModel()" class="preview" />
      <p class="mt-5">{{ title }}</p>
      <!-- <a-cropper
        v-model="openDialog"
        :image="GetImageModel()"
        @SaveChange="GetSaveChangeFromEdit"
      ></a-cropper> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>
.file-input {
  .file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }
  label {
    width: 100%;
    .dropzone {
      min-width: 80%;
      max-width: 100%;
    }
  }
}
.PreviewImage {
  background-color: #f7f7f7;
  border: 1px solid #e6e6e6;
  padding: 5px;
  margin: 8px;
  img {
    width: 100%;
    height: 100%;
    min-width: 70%;
    max-width: 90%;

    min-height: 185px;
    max-height: 500px;
  }
  .btn-clean {
    position: absolute;
    right: 15px !important;
    left: inherit !important;
  }
  .btn-edit {
    position: absolute;
    right: 55px !important;
    left: inherit !important;
  }
}
</style>

<script>
export default {
  components: {
    // ACropper: () => import("@/components/cropper/_cropper")
  },
  data() {
    return {
      openDialog: false,
      images: null,
    };
  },
  methods: {
    handleSelects(e) {
      this.images = null;
      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach((f) => {
        if (!f.type.match("image.*")) {
          return;
        }

        let reader = new FileReader();
        let that = this;

        reader.onload = function (e) {
          that.images = e.target.result;
          that.$emit("input", that.images);
        };
        reader.readAsDataURL(f);
      });
    },
    GetImageModel() {
      if (this.value && this.images == null) {
        //  console.log("On VALue", this.value, `${this.UpPath}${this.value}`);
        return `${this.UpPath}${this.value}`;
      } else {
        // console.log("On VALue Else", this.value, this.images);
        return this.images;
      }
    },
    RemoveImage() {
      this.images = [];
      this.$emit("input", null);
    },
    GetSaveChangeFromEdit(image) {
      // console.log("Ge Save Change From Edit ==> ", image);
      this.images = image;
      this.$emit("input", image);
    },
  },
  props: {
    title: String,
    value: null,
    UpPath: String,
  },
};
</script>
