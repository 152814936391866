import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      required: $props.required,
      message: $props.message,
      trigger: ['focus'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_input, {
        clearable: "",
        type: "password",
        size: _ctx.formSetting.formSize,
        "show-password-on": "mousedown",
        value: $setup.MyValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
        disabled: $props.disabled,
        placeholder: $props.placeholder
      }, null, 8, ["size", "value", "disabled", "placeholder"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}