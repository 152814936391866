import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-12" }
const _hoisted_8 = { class: "col-md-12" }
const _hoisted_9 = { class: "col-md-12" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-12" }
const _hoisted_12 = { class: "col-md-12" }
const _hoisted_13 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_fromRowLable = _resolveComponent("a-fromRowLable")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_page_container = _resolveComponent("page-container")!
  const _component_n_drawer = _resolveComponent("n-drawer")!

  return (_openBlock(), _createBlock(_component_n_drawer, {
    show: _ctx.openDebenture,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openDebenture) = $event)),
    "with-header": false,
    "append-to-body": true,
    resizable: "",
    placement: "left",
    "default-width": 500
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_container, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_a_card, {
            col: "12",
            label: "بيانات السند"
          }, {
            default: _withCtx(() => [
              (_ctx.model)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        (_ctx.model)
                          ? (_openBlock(), _createBlock(_component_a_fromRowLable, {
                              key: 0,
                              title: _ctx.$t('VoucherNumber'),
                              value: _ctx.model.id
                            }, null, 8, ["title", "value"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('Toorder'),
                          value: _ctx.model.toTheOrder
                        }, null, 8, ["title", "value"])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('CreationDate'),
                          value: _ctx.DateFormat(_ctx.model.creationDate)
                        }, null, 8, ["title", "value"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('Amount'),
                          value: _ctx.model.amount
                        }, null, 8, ["title", "value"])
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('FromAccount'),
                          value: _ctx.model.accountFromName
                        }, null, 8, ["title", "value"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('ToAccount'),
                          value: _ctx.model.accountToName
                        }, null, 8, ["title", "value"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('Note'),
                          value: _ctx.model.notes
                        }, null, 8, ["title", "value"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('PaymentMethod'),
                          value: _ctx.PaymentMethodString(_ctx.model.debentureTypeId)
                        }, null, 8, ["title", "value"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_a_fromRowLable, {
                          title: _ctx.$t('BondType'),
                          value: _ctx.DebentureTypesString(_ctx.model.debentureTypeId)
                        }, null, 8, ["title", "value"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["show"]))
}