import { Action, Mutation } from "vuex-module-decorators";
import PageModule from "./PageModule";
export default class FormModule extends PageModule {
  model = {};

  @Mutation
  SetModel(model) {
    this.model = model;
  }
  get GetModel() {
    return this.model;
  }
  get GetFilter() {
    return this.filter;
  }
  @Action
  async AddOrEdit(model) {
    const { context, endPoint } = this;
    let resValue = null;
    for (const key in model) {
      if (model[key] == null) delete model[key];
    }
    console.log("AddOrEdit", model);
    await context.dispatch("Form", {
      url: endPoint + "/AddOrEdit",
      form: model,
      func: (res) => {
        resValue = res;
      },
    });
    return resValue;
  }
  @Action
  async ChangeActivation(id) {
    const { context, endPoint } = this;
    await context.dispatch("Get", {
      url: endPoint + "/ChangeActivation?Id=" + id,
    });
  }

  @Action
  async Clone(model) {
    const { context, endPoint } = this;
    const resValue = null;
    for (const key in model) {
      if (model[key] == null) delete model[key];
    }
    await context.dispatch("Post", {
      url: endPoint + "/Clone",
      form: model,
      func: (res) => {
        this.context.dispatch("Index");
      },
    });
    return resValue;
  }

  @Action
  async GetEdit(id: number) {
    const { context, endPoint } = this;
    await context.dispatch("Get", {
      url: endPoint + "/GetEdit?Id=" + id,
      func: (res) => {
        context.commit("SetModel", res.model);
      },
    });
  }

  @Action
  GetItem(id: number) {
    const { context, endPoint } = this;
    return context.dispatch("Get", {
      url: endPoint + "/Get?Id=" + id,
      commitName: "SetModel",
    });
  }

  @Action
  DeleteItem(id) {
    const { context, endPoint } = this;
    return context.dispatch("Delete", {
      url: endPoint + "/Delete?Id=" + id,
      func: () => {
        console.log("Fun dispatch Index");
        this.context.dispatch("Index");
      },
    });
  }
}
