import FormModule from "@/core/base/FormModule";
import { Module, Action, Mutation } from "vuex-module-decorators";
import store from "@/store/";


@Module({ namespaced: true })
export default class ReportTemplates extends FormModule {
  override endPoint = "ReportTemplates";
  AddModel = {}
  @Mutation
  SetGetAddModel(val) {
    this.AddModel = val
  }
  get GetAddModel() {
    return this.AddModel;
  }
  @Action
  async GetAdd(id: number) {
    const { context, endPoint } = this;
    await context.dispatch("Get", {
      url: endPoint + "/GetAdd?Id=" + id,
      func: (res) => {
        context.commit("SetGetAddModel", res.model);
      },
    });
  }

  @Action
  async AddModelFromTemplate(id: number) {
    const { context, endPoint } = this;
    await context.dispatch("Post", {
      url: endPoint + "/AddModelFromTemplate?Id=" + id,
      func: (res) => {
        store.dispatch("Reports/Index");
      },
    });
  }

  @Action
  Export(model) {
    return this.context.dispatch("Download", {
      url: this.endPoint + `/Export?ReportTemplateId=${model.reportTemplateId}&Id=${model.id}`,
      fileName: model.name,
    });
  }

  @Action
  override DeleteItem(id) {
    const { context, endPoint } = this;
    return context.dispatch("Delete", {
      url: endPoint + "/Delete?Id=" + id,
      func: () => {
        store.dispatch("Reports/Index");
      },
    });
  }
}
