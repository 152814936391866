import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      type: 'any',
      required: $props.required,
      trigger: ['blur', 'change'],
      message: $props.message,
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    isRtl: true,
    "show-label": $props.label != null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_select, {
        value: $setup.MyValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
        options: $setup.options,
        clearable: "",
        multiple: $props.multiple,
        disabled: $props.disabled,
        placeholder: $props.placeholder,
        size: _ctx.formSetting.formSize,
        filterable: $props.filterable,
        onChange: $setup.UpdateValue,
        "max-tag-count": $props.max,
        "render-label": $setup.renderLabel
      }, null, 8, ["value", "options", "multiple", "disabled", "placeholder", "size", "filterable", "onChange", "max-tag-count", "render-label"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}