
import { computed, h, VNodeChild, onMounted } from "vue";
import { NIcon, SelectOption, SelectGroupOption } from "naive-ui";

export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    multiple: {
      type: Boolean,
      required: false,
    },
    path: {
      type: String,
      default: "",
    },
    value: [Number, String, Array],
    ShowLabel: {
      type: Boolean,
      default: (props) => props.label != null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    // ShowLabel: {
    //   type: Boolean,
    //   required: false,
    //   default: (props) => props.label != null,
    // },
    max: {
      type: Number,
      required: false,
      default: 1,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اختار " + (props.label == null ? "" : props.label),
    },
    message: {
      type: String,
      required: false,
      default: (props) => {
        if (props.label) {
          return "برجاء اختيار " + props.label + "  مطلوب";
        } else {
          return "برجاء الاختيار مطلوب";
        }
      },
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    filterable: {
      type: Boolean,
      required: false,
    },
    showArrow: {
      type: Boolean,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const MyValue = computed({
      get() {
        console.log(props.value);
        return props.value;
      },
      set(newValue) {
        console.log(props.value);
        emit("update:value", newValue);
      },
    });

    const renderLabel = (option: SelectOption): VNodeChild => {
      if (option.required) {
        return option.label + "*";
      }
      return option.label + "";
    };

    onMounted(() => {
      if (props.multiple && props.value) {
        if (typeof props.value == typeof 0) {
          emit("update:value", [props.value]);
        }
      } else {
        emit(
          "update:value",
          Number.isNaN(parseFloat(props.value))
            ? props.value
              ? props.value
              : null
            : parseFloat(props.value)
        );
      }
    });
    const UpdateValue = (value) => {
      if (value) {
        console.log("Set Value =>", value);
        emit("update:value", value);
      } else {
        emit("update:value");
      }
    };
    return { UpdateValue, renderLabel, MyValue };
  },
};
