import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return ($props.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.Cul($props.to),
        class: _normalizeClass(['btn btn-sm   ms-3', 'btn-light-' + $props.color]),
        icon: "fa-layer-plus"
      }, {
        default: _withCtx(() => [
          ($props.svg)
            ? (_openBlock(), _createBlock(_component_inline_svg, {
                key: 0,
                class: "fs-5",
                src: $props.svg
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          ($props.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 1,
                class: _normalizeClass($props.icon)
              }, null, 2))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString($props.text), 1)
        ]),
        _: 1
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: _normalizeClass([
      'btn btn-sm   ms-3 bg-' + $props.color + ' btn-color-white',
      'btn-light-' + $props.color,
    ])
      }, [
        ($props.svg)
          ? (_openBlock(), _createBlock(_component_inline_svg, {
              key: 0,
              src: $props.svg
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        ($props.icon)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: _normalizeClass($props.icon)
            }, null, 2))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString($props.text), 1)
      ], 2))
}