import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "svg-icon svg-icon-3" }
const _hoisted_3 = {
  key: 1,
  class: "btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
}
const _hoisted_4 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_n_popover = _resolveComponent("n-popover")!

  return ($props.NoRule || _ctx.IsDelete())
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_n_popover, {
          trigger: "hover",
          "show-arrow": false
        }, {
          trigger: _withCtx(() => [
            ($props.to)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.Cul($props.to),
                  class: "btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                    ])
                  ]),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                  ])
                ]))
          ]),
          default: _withCtx(() => [
            _createTextVNode(" حذف ")
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}