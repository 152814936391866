
import { ref } from "vue";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "",
    },
    value: [String, Number, Array],
    options: {
      type: Array,
      default: () => [],
    },
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اكتب هنا " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => "مطلوب",
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    max: {
      type: Number,
      required: false,
      default: 1,
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
    tag: {
      type: Boolean,
      required: false,
    },
    filterable: {
      type: Boolean,
      required: false,
    },
    showArrow: {
      type: Boolean,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    let MyValue = ref();

    function isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }
    if (props.value) {
      MyValue.value = props.value
        .toString()
        .split(",")
        .map(function (item) {
          return isNumber(item) ? parseInt(item) : item;
        });
    }

    const UpdateValue = (value) => {
      emit("update:value", value.toString());
    };
    return { UpdateValue, MyValue };
  },
};
