<template>
  <div class="card card-custom item wave wave-primary mb-3 row">
    <div class="card-body">
      <div class="d-flex align-items-center p-2">
        <div class="d-contents">
          <div class="row card-body p-0">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isDeleted: Boolean,
    svg: null,
  },
};
</script>
