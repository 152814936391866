
import { ref } from "vue";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "",
    },
    value: String,
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اختار " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => {
        if (props.label) {
          return "برجاء اختيار " + props.label + "  مطلوب";
        } else {
          return "برجاء الاختيار مطلوب";
        }
      },
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "6" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    let RangeDate = ref<[number, number]>();
    if (props.value) {
      RangeDate = ref<[number, number]>([
        parseFloat(props.value.split(",")[0]),
        parseFloat(props.value.split(",")[1]),
      ]);
    }
    const UpdateValue = (value) => {
      if (value) {
        emit("update:value", value[0] + "," + value[1]);
      } else {
        emit("update:value");
      }
    };
    return { UpdateValue, RangeDate };
  },
};
