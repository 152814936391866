import { Module } from "vuex-module-decorators";
import PageModule from "@/core/base/PageModule";
import Filter from "./models/filter";

@Module({ namespaced: true })
export default class FinancialCenterList extends PageModule {
  override endPoint = "FinancialClosure";
  override indexUrl = "/BudgetPeriodic";
  override filter = new Filter();
}
