
import { PropType, computed, onMounted, ref } from "vue";
import { VSwatches } from "vue3-swatches";
import "vue3-swatches/dist/style.css";
export default {
  name: "wColor",
  inheritAttrs: false,
  components: {
    VSwatches,
  },
  props: {
    path: {
      type: String,
      default: "",
    },
    value: [String, Array] as PropType<null | string | [string, string]>,
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اكتب هنا " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => "مطلوب",
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    blackAndWhite: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });
    let swatches = ref([
      "#e76f51",
      "#f4a261",
      "#e9c46a",
      "#2a9d8f",
      "#264653",
      "#132a13",
      "#4f772d",
      "#ecf39e",
      "#386641",
      "#f2e8cf",
      "#bc4749",
      "#0081a7",
      "#081c15",
      "#3c096c",
      "#df2935",
      "#735d78",
    ]);

    onMounted(async () => {
      if (props.blackAndWhite) {
        swatches.value = ["#ffffff", "#101010"];
      }
    });

    return {
      MyValue,
      swatches,
    };
  },
};
