import { Action, Mutation } from "vuex-module-decorators";
import { PushRouter, SetStartPageQueryString } from "@/core/base/app";
import BaseFilter from "@/core/base/BaseFilter";
import BaseModule from "./BaseModule";

export default class PageModule extends BaseModule {
  response = [];
  models = [];
  pager = {};
  reports = [];
  // loading = false;

  messages = [];

  filter: any = {};
  filterQuery: object;

  searchData = [];
  get GetSearchData() {
    return this.searchData;
  }
  @Mutation
  SetSearchData(models) {
    if (models) {
      this.searchData = models;
    } else {
      this.searchData = [];
    }
  }

  @Mutation
  SetSearchOptions(models) {
    if (models) {
      this.searchData = models;
    } else {
      this.searchData = [];
    }
  }

  // get GetLoading() {
  //   return this.loading;
  // }

  get GetModels() {
    return this.models;
  }

  get GetResponse() {
    return this.response;
  }

  get GetReports() {
    return this.reports;
  }

  get GetPager() {
    return this.pager;
  }

  get GetFilter() {
    return this.filter;
  }

  get GetFilterQuery() {
    return this.filterQuery;
  }

  @Mutation
  SetModels(models) {
    if (models.reports) {
      this.reports = models.reports;
    }

    if (models.list != undefined) {
      this.response = models;
      this.models = models.list;
      this.pager = models.pager;
    } else {
      this.models = models;
    }

    try {
      if (models.filter.dateRange) this.filter.dr = models.filter.dateRange;
      if (models.filter.year) this.filter.py = models.filter.year;
      if (models.filter.quarter) this.filter.q = models.filter.quarter;

      this.filterQuery = PushRouter(this.filter);
    } catch (e) { }
  }

  // @Mutation
  // SetLoading(value: boolean) {
  //   this.loading = value;
  // }

  @Mutation
  SetPageNumber(PageNumber: number) {
    this.filter["p"] = PageNumber;
  }
  @Mutation
  SetPageSize(PageSize: number) {
    this.filter["p"] = 1;
    this.filter["ps"] = PageSize;
  }
  @Mutation
  SetClearSearch() {
    this.filter = new BaseFilter();
  }
  @Mutation
  SetSearchFilter(filter) {
    filter.p = 1;
    this.filter = filter;
  }

  @Action
  GetSearch(_filter) {
    this.context.commit("SetSearchFilter", _filter ? _filter : this.filter);
    return this.context.dispatch("PageIndex");
  }

  @Action
  ClearSearch() {
    this.context.commit("SetClearSearch");
    return this.context.dispatch("PageIndex");
  }

  @Action
  PageTo(PageNumber: number) {
    this.context.commit("SetPageNumber", PageNumber);
    return this.context.dispatch("PageIndex");
  }

  @Action
  ChangePageSize(Size: number) {
    this.context.commit("SetPageSize", Size);
    return this.context.dispatch("PageIndex");
  }

  @Mutation
  SetFilterQuery() {
    this.filterQuery = PushRouter(this.filter);
  }

  @Mutation
  SetPageQueryString() {
    SetStartPageQueryString(this.filter);
  }

  @Action
  Index(model) {
    console.log("Fun Action Index");

    this.context.commit("SetPageQueryString");
    return this.context.dispatch("PageIndex", model);
  }

  @Action({ rawError: true })
  PageIndex(model) {
    this.context.commit("SetFilterQuery");
    const { context, endPoint, indexUrl, filterQuery } = this;
    return context.dispatch("Get", {
      url: endPoint + indexUrl,
      filter: filterQuery,
      commitName: "SetModels",
    });
  }

  _lookupsFromServer = [];
  get lookupsFromServe() {
    return this._lookupsFromServer;
  }
  @Mutation
  SetLookupsFromServe(model) {
    this._lookupsFromServer = model;
  }
  @Action
  GetFromServer(url) {
    const { context, endPoint } = this;
    if (!url.includes("undefined") && !url.includes("=0") && !url.includes("NaN") && !url.includes("null"))
      return context.dispatch("Get", {
        url: endPoint + "/" + url,
        commitName: "SetLookupsFromServe",
      });
  }
}
