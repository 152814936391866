import { RouteRecordRaw } from "vue-router";

const branches: RouteRecordRaw = {
  path: "branches",
  component: () => import("@/views/branches/index.vue"),
  meta: {
    title: "الفروع",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-branches",
      component: () => import("@/views/branches/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "الفروع",
      },
    },
    {
      path: "add",
      name: "add-branch",
      component: () => import("@/views/branches/pages/add.vue"),
      meta: {
        pageId: 18010400,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "edit-branch",
      component: () => import("@/views/branches/pages/add.vue"),
      meta: {
        pageId: 18010400,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default branches;
