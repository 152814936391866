import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_n_date_picker = _resolveComponent("n-date-picker")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      required: $props.required,
      message: $props.message,
      trigger: ['focus'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_date_picker, {
        value: $setup.RangeDate,
        "onUpdate:value": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.RangeDate) = $event)),
          $setup.UpdateValue
        ],
        size: _ctx.formSetting.formSize,
        type: "datetimerange",
        clearable: "",
        disabled: $props.disabled,
        placeholder: $props.placeholder
      }, {
        "date-icon": _withCtx(() => [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen014.svg" })
        ]),
        _: 1
      }, 8, ["value", "size", "disabled", "placeholder", "onUpdate:value"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}