<template>
  <div>
    <h5 class="m-b-5 font-22">{{ rowId }}</h5>
  </div>
</template>
<script>
export default {
  props: {
    rowId: Number,
  },
};
</script>
