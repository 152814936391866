import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      type: 'any',
      required: $props.required,
      message: $props.message,
      trigger: ['blur', 'change'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_input, {
        clearable: "",
        value: $setup.MyModel,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyModel) = $event)),
        size: _ctx.formSetting.formSize,
        disabled: $props.disabled,
        placeholder: $props.placeholder,
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
        "allow-input": $setup.onlyAllowNumber
      }, null, 8, ["value", "size", "disabled", "placeholder", "allow-input"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}