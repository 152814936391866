
import { computed } from "vue";

export default {
  props: {
    value: [String, Number],
    options: null,
    label: null,
  },
  data() {
    return {
      SortData: [
        { value: 1, label: "Receipts" },
        { value: 2, label: "Expenses" },
        { value: 3, label: "BuyFixedAsset" },
        { value: 4, label: "SellFixedAsset" },
      ],
    };
  },
  setup(props, { emit }) {
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });

    return {
      MyValue,
    };
  },
};
