<template>
  <router-link v-if="to" :to="Cul(to)">
    {{ number }}{{ text }} {{ value }}
  </router-link>
  <a v-else class="text-primary">
    <span> {{ number }}{{ text }} {{ value }} </span>
  </a>
  <span v-if="description" class="text-muted fw-bold text-muted d-block fs-7">{{
    description
  }}</span>
</template>
<script>
export default {
  props: {
    text: {
      type: [String, Number],
      required: false,
    },
    number: {
      type: Number,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
};
</script>
