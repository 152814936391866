import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      type: 'any',
      required: $props.required,
      message: $props.message,
      trigger: ['input', 'blur'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_editor, {
        modelValue: $setup.MyValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
        "api-key": "wyy47pvqcho2nf6kivaczfl4kdvbcn8y5uyibh8xa35ddvyb",
        init: $setup.init,
        disabled: $props.disabled,
        onNodeChange: $setup.OpenModalUpdate,
        onKeyUp: $setup.OpenModalUpdate,
        onChange: $setup.OpenModalUpdate
      }, null, 8, ["modelValue", "init", "disabled", "onNodeChange", "onKeyUp", "onChange"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}