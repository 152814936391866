import { Module, Action, Mutation } from "vuex-module-decorators";
import Filter from "./models/filter";
import FormModule from "@/core/base/FormModule";

@Module({ namespaced: true })
export default class Transaction extends FormModule {
  override endPoint = "Transactions";
  override filter = new Filter();

  @Action
  override async AddOrEdit(model) {
    const { context, endPoint } = this;
    let resValue = null;
    await context.dispatch("Post", {
      url: endPoint + "/AddOrEdit",
      form: model,
      func: (res) => {
        resValue = res;
      },
    });

    return resValue;
  }

  @Action
  Approval(id) {
    const { context, endPoint } = this;
    return context.dispatch("Post", {
      url: `${endPoint}/Approval?id=${id}`,
      GetIndex: true,
    });
  }

  @Action
  RevokeApproval(id) {
    const { context, endPoint } = this;
    return context.dispatch("Post", {
      url: `${endPoint}/RevokeApproval?id=${id}`,
      GetIndex: true,
    });
  }
}
