import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"12px"} }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_archive_icon = _resolveComponent("archive-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_p = _resolveComponent("n-p")!
  const _component_n_upload_dragger = _resolveComponent("n-upload-dragger")!
  const _component_n_upload = _resolveComponent("n-upload")!
  const _component_n_modal = _resolveComponent("n-modal")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      required: $props.required,
      message: $props.message,
      trigger: ['focus'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_upload, {
        max: 1,
        "onUpdate:fileList": $setup.handleFileListChange,
        onChange: $setup.handleUploadChange,
        "list-type": "image-card"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_upload_dragger, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_n_icon, {
                  size: "48",
                  depth: 3
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_archive_icon)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_n_text, { style: {"font-size":"16px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(" Click or drag a file to this area to upload ")
                ]),
                _: 1
              }),
              _createVNode(_component_n_p, {
                depth: "3",
                style: {"margin":"8px 0 0 0"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Strictly prohibit from uploading sensitive information. For example, your bank card PIN or your credit card expiry date. ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onUpdate:fileList", "onChange"]),
      _createVNode(_component_n_modal, {
        show: $setup.showModal,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showModal) = $event)),
        preset: "card",
        style: {"width":"600px"},
        title: "A Cool Picture"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: $setup.previewImageUrl,
            style: {"width":"100%"}
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["show"]),
      _createTextVNode(" " + _toDisplayString($setup.showModal) + " - " + _toDisplayString($setup.previewImageUrl), 1)
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}