
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "wTimeTable",
  props: {
    model: {
      type: Object,
      required: true,
    },
    teacher: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const tableLabel = computed(
      () => `الجدولى الخاص بالفصل ${props.model?.classRoom?.name}`
    );

    const tableInfo = computed(
      () =>
        `العام الدراسى ${props.model?.classRoom?.yearName} | المرحلة ${props.model?.classRoom?.levelName} ${props.model?.classRoom?.gradeName} فصل ${props.model?.classRoom?.name}`
    );

    const GetSession = (day, sessionId) => {
      return props.model.timeTable.filter(function (v) {
        return v.dayId == day.dayId && v.sessionId == sessionId;
      })[0];
    };

    return { GetSession, tableInfo, tableLabel };
  },
});
