
import { useStore } from "vuex";
import { computed, ref } from "vue";
export default {
  name: "wManagementStructure",
  inheritAttrs: false,
  props: {
    multiple: {
      type: Boolean,
      required: false,
    },
    path: {
      type: String,
      default: "",
    },
    value: { type: [String, Number, Array] },
    options: {
      type: Array,
      default: () => [],
    },
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    max: {
      type: Number,
      required: false,
      default: 1,
    },

    placeholder: {
      type: String,
      required: false,
      default: (props) => "اختار " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => {
        if (props.label) {
          return "برجاء اختيار " + props.label + "  مطلوب";
        } else {
          return "برجاء الاختيار مطلوب";
        }
      },
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    filterable: {
      type: Boolean,
      required: false,
    },
    showArrow: {
      type: Boolean,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const Tree = computed(() => store.state.LookUps.managementStructure);

    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });

    return {
      Tree,
      MyValue,
    };
  },
};
