import { Action, VuexModule, Mutation } from "vuex-module-decorators";
import api from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import FileSaver from "file-saver";
import { messagesResponse, error, success } from "./systemAlert";
import { loadingBar } from "@/core/base/naive";

export default class BaseModule extends VuexModule {
  endPoint = "EndPoint Api";
  indexUrl = "";
  loading = false;
  IsPartialLoading = false;

  get GetLoading() {
    return this.loading;
  }
  get GetPartialLoading() {
    return this.IsPartialLoading;
  }

  @Mutation
  PartialLoading() {
    this.IsPartialLoading = true;
  }

  @Mutation
  SetLoading(value: boolean) {
    if (!this.IsPartialLoading) {
      this.loading = value;
      this.IsPartialLoading = false;
    }
    if (value) loadingBar.start();
    else loadingBar.finish();
  }

  swalFireMode = {
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    confirmButtonText: "Yes, delete it!",
    afterDeleteTitle: "Deleted!",
    afterDeleteText: "Your file has been deleted.",
  };
  @Action({ rawError: true })
  async SetResponseData(model) {
    console.log("SetResponseData =>", model);
    try {
      const res = model.response.data;
      if (res.isValid) {
        if (model.commitName != undefined) {
          this.context.commit(model.commitName, res.model);
        } else {
          if (model.func != undefined) {
            model.func(res);
          }
        }
        if (model.GetIndex) this.context.dispatch("PageIndex");
        this.context.commit("SetLoading", false);
      } else {
        if (model.func != undefined) {
          model.func(res);
        }
        this.context.commit("SetLoading", false);
      }
      messagesResponse(res.messages);
    } catch (ex) {
      console.log("exp ", ex);

      this.context.commit("SetLoading", false);
      loadingBar.error();
      error("حدث خطا فى الاتصال بالسيرفر");
      console.log("Server Error", model);
      return;
    }
  }

  @Action({ rawError: true })
  async Get(model) {
    console.log("Get =>", model);
    try {
      this.context.commit("SetLoading", true);
      model.response = await api.get(model.url, model.filter ?? "");
      await this.context.dispatch("SetResponseData", model);
    } catch (ex) {
      console.log("exp ", ex);
      this.context.commit("SetLoading", false);
      loadingBar.error();
      error("حدث خطا فى الاتصال بالسيرفر");
      return;
    }
  }

  @Action({ rawError: true })
  async Post(model) {
    console.log("Post =>", model);
    try {
      this.context.commit("SetLoading", true);
      model.response = await api.post(model.url, model.form);
      await this.context.dispatch("SetResponseData", model);
    } catch (ex) {
      this.context.commit("SetLoading", false);
      loadingBar.error();
      error("حدث خطا فى الاتصال بالسيرفر");
      return;
    }
  }

  @Action({ rawError: true })
  async Form(model) {
    try {
      this.context.commit("SetLoading", true);
      model.response = await api.form(model.url, model.form);
      await this.context.dispatch("SetResponseData", model);
    } catch (ex) {
      this.context.commit("SetLoading", false);
      loadingBar.error();
      console.log("Form error ==> ", ex);
      error("حدث خطا فى الاتصال بالسيرفر");
      return;
    }
  }

  @Action({ rawError: true })
  Delete(model) {
    return new Promise<void>((resolve, reject) => {
      Swal.fire({
        ...this.swalFireMode,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.context.commit("SetLoading", true);
          api
            .delete(model.url)
            .then(({ data: res }) => {
              if (res.isValid) {
                if (model.commitName != undefined)
                  this.context.commit(model.commitName, res.model);
                else {
                  if (model.func != undefined) {
                    model.func(res);
                  }
                }

                this.context.commit("SetLoading", false);
                Swal.fire(
                  this.swalFireMode.afterDeleteTitle,
                  this.swalFireMode.afterDeleteText,
                  "success"
                );
                resolve();
              } else {
                if (model.func != undefined) {
                  model.func(res);
                }
                this.context.commit("SetLoading", false);
                reject();
              }
              messagesResponse(res.messages);
            })
            .catch(({ response }) => {
              this.context.commit("SetLoading", false);
              loadingBar.error();
              error("حدث خطا فى الاتصال بالسيرفر");
              console.log(response);
              reject();
            });
        }
      });
    });
  }

  @Action({ rawError: true })
  Download(model) {
    console.log("Api Post == >", model);
    return new Promise<void>((resolve, reject) => {
      this.context.commit("SetLoading", true);
      api
        .download(model.url, model.form)
        .then(({ data: res }) => {
          if (model.func != undefined) {
            model.func(res);
          }
          FileSaver.saveAs(new Blob([res]), model.fileName);
          this.context.commit("SetLoading", false);
          success("تم تحميل الملف بنجاح");
          resolve();
          messagesResponse(res.messages);
        })
        .catch(({ response }) => {
          console.log("Noooooooooooooooooo");
          this.context.commit("SetLoading", false);
          loadingBar.error();
          error("حدث خطا فى الاتصال بالسيرفر");
          console.log(response);
          reject();
        });
    });
  }
}
