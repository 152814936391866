
import { PropType, ref } from "vue";
import type { UploadFileInfo } from "naive-ui";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "",
    },
    value: [String, Array] as PropType<null | string | [string, string]>,
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اكتب هنا " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => "مطلوب",
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const showModalRef = ref(false);
    const previewImageUrlRef = ref("");
    function handlePreview(file: UploadFileInfo) {
      console.log(file);
      const { url } = file;
      previewImageUrlRef.value = url as string;
      showModalRef.value = true;
    }

    function handleUploadChange(data: { fileList: UploadFileInfo[] }) {
      if (data.fileList.length > 0) {
        console.log(data.fileList[0]);
        let file = data.fileList[0];
        const { url } = file;
        previewImageUrlRef.value = url as string;
        showModalRef.value = true;
      }
      console.log(data.fileList);
    }

    function handleFileListChange() {
      console.log("Yes, file-list changed.");
    }

    return {
      handlePreview,
      showModal: showModalRef,
      previewImageUrl: previewImageUrlRef,
      handleUploadChange,
      handleFileListChange,
    };
  },
};
