import { Module, Action } from "vuex-module-decorators";

import PageModule from "@/core/base/PageModule";
import Filter from "./models/filter";

@Module({ namespaced: true })
export default class RevenuesAndExpenses extends PageModule {
  override endPoint = "Accounts";
  override indexUrl = "/RevenuesAndExpenses";
  override filter = new Filter();

  @Action
  PrintLedger(fileName) {
    const { context, endPoint } = this;
    //const Query = app.PushRouter(state.filter);
    return context.dispatch("Download", {
      url: endPoint + "/PrintLedger",
      form: fileName,
    });
  }
}
