<template>
  <PhotoGrid :items="images"></PhotoGrid>
</template>
<script>
import { Config } from "@/core/services/config";
import PhotoGrid from "@/components/media/components/lightbox.vue";
export default {
  components: { PhotoGrid },
  data() {
    return {
      images: [],
    };
  },
  mounted() {
    this.files.forEach((e) => {
      this.images.push(Config.POST_MEDIA + e.fileName);
    });
  },
  props: {
    files: {
      type: Array,
    },
  },
};
</script>
