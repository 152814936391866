import { createDiscreteApi, ConfigProviderProps, lightTheme } from "naive-ui";
import { computed } from "vue";

const configProviderPropsRef = computed<ConfigProviderProps>(() => ({
  theme: lightTheme,
}));
export const { message, notification, dialog, loadingBar } = createDiscreteApi(
  ["message", "dialog", "notification", "loadingBar"],
  {
    configProviderProps: configProviderPropsRef,
  }
);

export default {
  message,
  notification,
  dialog,
  loadingBar,
};
