import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`direction:${_ctx.Direction} !important ; height:100%;`)
  }, [
    _createVNode(_component_n_loading_bar_provider, null, {
      default: _withCtx(() => [
        _createVNode(_component_n_message_provider, { placement: "bottom-right" }, {
          default: _withCtx(() => [
            _createVNode(_component_n_notification_provider, {
              placement: "bottom-right",
              max: 5,
              scrollable: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_dialog_provider, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view),
                    _createVNode(_component_notifications, { position: "bottom left" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 4))
}