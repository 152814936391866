
export default {
  name: "wWarning",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    Show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
