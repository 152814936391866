class PostServices {
  constructor(files, mediayId) {
    this.files = files;
    this.mediayId = mediayId;
  }

  getTypeByEx() {
    console.log(this.files)
    if (this.files != null && this.files.length > 0) {
      let obj = this.files[0];
      return obj.fileName.substring(
        obj.fileName.indexOf("."),
        obj.fileName.length
      );
    }
    return "";
  }
  mediaTypeFilter() {
    let result = false;
    let ex = this.getTypeByEx().toLowerCase();
    switch (this.mediayId) {
      case 1:
        if (ex == ".jpg" || ex == ".jpeg") {
          result = true;
        }
        break;
      case 2:
        if (ex == ".mp4") {
          result = true;
        }
        break;
      case 3:
        if (ex == ".pdf") {
          result = true;
        }
        break;

      default:
        result = false;
        break;
    }
    return result;
  }
  mediaTypePostId() {
    let ex = this.getTypeByEx().toLowerCase();
    if (ex == ".jpg" || ex == ".jpeg") {
      return 1;
    }
    if (ex == ".pdf") {
      return 3;
    }
    if (ex == ".mp4") {
      return 2;
    }
  }
}
export default PostServices;
