import { Module, Action } from "vuex-module-decorators";

import PageModule from "@/core/base/PageModule";
import Filter from "./models/filter";
import { PushRouter } from "@/core/base/app";
import FormModule from "@/core/base/FormModule";

@Module({ namespaced: true })
export default class TrialBalance extends FormModule {
  override endPoint = "Accounts";
  override indexUrl = "/TrialBalance";
  override filter = new Filter();


  @Action
  PrintTrialBalance(fileName) {
    const { context, endPoint } = this;
    const Query = PushRouter(this.filter);
    return context.dispatch("Download", {
      url: endPoint + "/ExportTrialBalance",
      form: Query,
      fileName: fileName,
    });
  }
}
