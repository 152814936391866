
export default {
  name: "wEmpty",
  inheritAttrs: false,
  props: {
    svg: {
      type: String,
      default: "/media/icons/duotune/files/fil003.svg",
    },
    title: {
      type: String,
      default: "لا يوجد بيانات مسجلة",
    },
    large: {
      type: Boolean,
    },
    width: {
      type: [String, Number],
    },
  },
};
