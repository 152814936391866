
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "aDebentureDetails",
  props: {
    debentureId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const openDebenture = ref(false);

    onMounted(async () => {
      openDebenture.value = props.debentureId != 0;
      if (props.debentureId != 0) await GetItem(props.debentureId);
    });

    const PaymentMethodString = (id) => {
      // this.$enums.PaymentMethod.forEach((element) => {
      //   if ((element.value = id)) {
      //     return element.label;
      //   }
      // });
    };

    const DebentureTypesString = (id) => {
      // this.$enums.DebentureTypes.forEach((element) => {
      //   if ((element.value = id)) {
      //     return element.label;
      //   }
      // });
    };
    const loading = computed(() => store.getters[`Debentures/GetLoading`]);
    const model = computed(() => store.getters[`Debentures/GetModel`]);
    const GetItem = async (val) =>
      await store.dispatch(`Debentures/GetItem`, val);

    return {
      loading,
      model,
      openDebenture,
      DebentureTypesString,
      PaymentMethodString,
    };
  },
});
