<template>
  <span class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3">
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg src="/media/svg/icons/Communication/Write.svg"></inline-svg>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    to: String,
  },
};
</script>
