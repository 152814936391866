
import { PropType, computed } from "vue";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "",
    },
    value: [String, Array, Number] as PropType<
      null | string | [string, string, Number]
    >,
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اكتب هنا " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => "مطلوب",
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });

    return {
      MyValue,
    };
  },
};
