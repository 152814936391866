import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "card-title fw-bolder text-white align-items-start flex-column m-0"
}
const _hoisted_2 = { class: "card-label fw-bolder fs-3 mb-1 text-white" }
const _hoisted_3 = { class: "mt-1 fw-bold fs-7 text-white pt-1 pb-0" }
const _hoisted_4 = { class: "card-toolbar m-5" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "card-body py-6 row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('mb-5 col-md-' + $props.col)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['card CardContent card-xl-stretch mb-5 mb-xl-8 h-100', $props.mode])
    }, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.OpenBlock = !$setup.OpenBlock)),
        class: "card-header flex-start border-0 py-5 pointer"
      }, [
        ($props.svg)
          ? (_openBlock(), _createBlock(_component_inline_svg, {
              key: 0,
              src: $props.svg
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        ($props.icon)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: _normalizeClass($props.icon)
            }, null, 2))
          : _createCommentVNode("", true),
        ($props.label)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString($props.info), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "toolbar")
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default")
      ], 512), [
        [_vShow, $setup.OpenBlock]
      ])
    ], 2)
  ], 2))
}