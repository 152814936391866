
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "",
    },
    show: Boolean,
    bordered: {
      type: Boolean,
      required: false,
    },
  },
};
