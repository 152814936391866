
import { ar } from "date-fns/locale";
export default {
  name: "DateFormate",
  inheritAttrs: false,
  props: {
    name: {
      type: Object,
      required: true,
    },
    fullName: {
      type: String,
      required: false,
    },
    number: {
      type: [String, Number],
      required: false,
    },
  },
  setup() {
    return { ar };
  },
};
