
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "wTimeTableTd",
  props: {
    model: {
      type: Object,
      required: true,
    },
    teacher: {
      type: Boolean,
      default: false,
    },
    breakTime: {
      type: Boolean,
      default: false,
    },
    daysCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    return {};
  },
});
