import { RouteRecordRaw } from "vue-router";
const IncomeList: RouteRecordRaw = {
  path: "incomeList",
  component: () => import("@/views/accounts/index.vue"),
  meta: {
    title: "Accounts",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "incomeList",
      component: () => import("@/views/accounts/incomeList/pages/index.vue"),
      meta: {
        pageId: 17010300,
        role: "view",
        title: "IncomeList",
      },
    },
  ],
};

export default IncomeList;
