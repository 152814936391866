
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}
import { defineComponent, onMounted } from "vue";
import { loadScript } from "vue-plugin-load-script";
export default defineComponent({
  name: "jitsiComponent",
  props: {
    roomId: {
      type: String,
      required: true,
    },
    roomName: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: () => "ar",
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    let JitsiMeetExternalAPI;
    onMounted(async () => {
      loadScript("https://meet.jit.si/external_api.js")
        .then((ee) => {
          if (window.JitsiMeetExternalAPI) {
            JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
            emit("JitsiMeet", JitsiMeetExternalAPI);
            options.parentNode = document.querySelector("#jitsiContainer");
            emit("loadApi", new JitsiMeetExternalAPI("meet.jit.si/"+props.roomId, options));
          }
        })
        .catch((ex) => {
          console.log("Failed to fetch script", ex);
        });
    });
    let options: any = {
      roomName: props.roomName,
      displayName: false,
      width: "100%",
      avatarURL:"",
      height: window.innerHeight,
      parentNode: null,
      lang: props.lang,
      userInfo: props.userInfo,
      configOverwrite: {
        startWithAudioMuted: true,
        startSilent: true,
        enableWelcomePage: false,
        disableAddingBackgroundImages: true,
      },
      interfaceConfigOverwrite: { DISABLE_DOMINANT_SPEAKER_INDICATOR: true },
    };
  },
});
