const MENU = {
  General: "General",
  TextbookManagement: "Textbook management",
  MANAGEMENTANDDISTRIBUTION: "Management and distribution",
  Books: "Books",
  DASHBOARD: "DASHBOARD",
  Mail: "Mail",
  Chat: "Chat",
  BalanceSheet: "Balance Sheet",
  HumanResourceManagement: "Human Resource Management",
  Employeesandmanagement: "Employees And Management",
  EmployeesArchives: "Employees Archives",
  Teachers: "Teachers",
  TeachersArchives: "Teachers Archives",
  Ticket: "Technical support",
  TuitionFees: "Tuition Fees",
  TuitionFeesSettings: "Fees Settings",
  StudentAffairsAdministration: "Student Affairs Administration",
  Students: "Students",
  Busmanagement: "Bus management",
  Contentmanagement: "Content management",
  lines: "Lines",
  Subscription: "Subscription",
  SchoolBooks: " School Books",
  library: "Library",
  Booksections: "Book sections",
  Borrowbooks: "Borrow books",
  EducationalAdministration: "Educational Administration",
  EducationaLevel: "Educational Level",
  EducationalSubjects: "Educational Subjects",
  ClassSchedule: " Class schedule",
  Schedule: "Schedule",
  IncomeList: "Income List",
  SettingsSchedule: "Schedule settings",
  MaterialSettings: "Material settings",
  Teacherdistribution: "Teacher distribution",
  ClassroomorLevels: " Classroom",
  Classroom: " Classroom",
  ClassroomSettings: " Classroom settings",
  Visits: "Visits",
  Accounts: "Accounts",
  AccountsTree: "Accounts tree",
  Ledger: "Ledger",
  TrialBalance: "Trial Balance",
  IncomeAndExpenses: "Income and expenses",
  PhoneNumbers: "Phone numbers",
  CallLogs: "Call Logs",
  Budget: "Budget",
  Closingthefiscalyear: "Closing the fiscal year",
  FixedAssets: "Fixed assets",

  CurrentAssets: "Current Assets",
  CurrentLiabilities: "Current Liabilities",
  Ownership: "Ownership",

  FinancialCenterList: "Statement of financial position",

  TotalGainsAndLossesRetandy: "Total profit and loss",

  Assets: "Assets",
  AssetCategory: "Asset Category",
  AssetManagement: "Asset Management",
  BalanceAffixedAssets: "Balance of fixed assets",
  FixedAssetDebentures: "Fixed asset Debentures",
  Transaction: "Transactions",
  Debentures: "Debentures",
  PublicDebentures: "Public Debentures",
  PurchaseDate: "Purchase Date",
  TuitionFeesDistribution: "Tuition Fees Distribution",
  Quantity: "Quantity",
  UnitPrice: "Unit Price",
  ConsumptionDuration: "Consumption Duration",
  MonthlyValue: "Monthly Value",

  StudentMaterial: "Materials",

  FollowUp: "FollowUp",
  Settings: "Settings",
  Basicinformation: "Basic information",
  Schooldata: "School data",
  Educationalyear: "Educational year",
  Jobs: "Jobs",
  Subjects: "Subjects",
  Privilegesandresponsibilities: "privileges and responsibilities",
  Privileges: "Privileges",
  Administration: "Administration",
  GeneralSettings: "General Settings",
  Filestoragelocations: "File storage locations",
  Screensettings: "Screen settings",
  System: "System",
  Bugs: "Bugs ",
  Abouttherelease: "About the release ",
  Developer: "Developer",
  School: "School",
  Addedrequests: "Added requests",
  Administrativeregion: "Reception",
  Assetclassification: "Asset classification",

  PAGES: "Pages",
  FEATURES: "Features",
  APPS: "Apps",
  Reports: "Reports and templates",
  TeachersStatistics: "Teachers statistics",
  StudentsStatistics: "Students statistics",
  EmployeesStatistics: "Employees statistics",

  Invoices: "Invoices",
  Pricing: "Pricing",
  Subscriptions: "Subscriptions",
  SubscriptionsAndInvoices: "Subscriptions And Invoices",
  Upgrade: "Upgrade",
};
export default MENU;
