
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "PageItem",
  props: {
    modelName: String,
    itemId: Number,
  },
  setup(props, { emit }) {
    const store = useStore();
    const ModelName = ref(props.modelName);
    const root = ModelName.value;
    const route = useRoute();

    onMounted(async () => {
      emit("onMountedBefore");
      await GetItem();
    });

    const loading = computed(() => store.getters[`${root}/GetLoading`]);
    const model = computed(() => store.getters[`${root}/GetModel`]);

    const GetItem = async () =>
      await store.dispatch(`${root}/GetItem`, props.itemId ?? route.params.Id);

    return {
      loading,
      model,
    };
  },
});
