import { Module, Action } from "vuex-module-decorators";
import Filter from "./models/filter";
import FormModule from "@/core/base/FormModule";
import { PushRouter } from "@/core/base/app";

@Module({ namespaced: true })
export default class Ledger extends FormModule {
  override endPoint = "Accounts";
  override indexUrl = "/Ledger";
  override filter = new Filter();

  @Action
  GetAccountDetials(model) {
    const { context, endPoint, filterQuery } = this;
    return context.dispatch("Get", {
      url:
        endPoint +
        `/Ledger?aid=${model.aid}${model.df ? "&df=" + model.df : ""}`,
      filter: filterQuery,
      commitName: "SetModel",
    });
  }

  @Action
  PrintLedger(fileName) {
    const { context, endPoint } = this;
    const Query = PushRouter(this.filter);
    return context.dispatch("Download", {
      url: endPoint + "/ExportLedger",
      form: Query,
      fileName: fileName,
    });
  }
}
