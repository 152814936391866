import { RouteRecordRaw } from "vue-router";

const Closingthefiscalyear: RouteRecordRaw = {
  path: "closingthefiscalyear",
  component: () => import("@/views/accounts/index.vue"),
  meta: {
    title: "closingthefiscalyear"
  },
  children: [
    {
      path: "",
      name: "closingthefiscalyear",
      component: () =>
        import("@/views/accounts/closingthefiscalyear/pages/index.vue"),
      meta: {
        pageId: 17010700,
        role: "view",
        title: "closingthefiscalyear"
      },
    },
  ],
};

export default Closingthefiscalyear;
