
import { ref, watch, onMounted, computed } from "vue";
import Editor from "@tinymce/tinymce-vue";
import { CreateMenu } from "./plugins";
export default {
  name: "wInput",
  components: {
    editor: Editor,
  },
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "ElementId",
    },
    value: [String],
    rule: [Object, Array],
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اكتب هنا " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => "مطلوب",
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    maxlength: {
      type: Number,
      required: false,
      default: 128,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    showCount: {
      type: Boolean,
      required: false,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    width: {
      type: String,
      required: false,
      default: "100%",
    },
    mini: {
      type: Boolean,
      required: false,
    },
    word: {
      type: Boolean,
      required: false,
    },
    tiny: {
      type: Boolean,
      required: false,
    },
    menu: {
      type: Array,
      required: false,
    },
  },
  setup(props, { emit }) {
    const OpenModalUpdate = () => {
      emit("update:value", props.value);
    };

    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });

    // watch(
    //   () => props.value,
    //   (first, second) => {
    //     console.log("Change", MyEditorValue);
    //     emit("update:value", MyEditorValue);
    //   }
    // );
    // watch(props.value, () => {
    //   console.log("Change", MyEditorValue);
    //   emit("update:value", MyEditorValue);
    // });

    const useDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const isSmallScreen = window.matchMedia("(max-width: 1023.5px)").matches;

    const MyToolBar = ref(
      "bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | fullscreen  preview save print | insertfile image   template  | ltr rtl"
    );

    const Plugins = ref(
      "  preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount  charmap   emoticons"
    );

    const MyHeight = ref(props.height.toString());

    if (props.mini) {
      MyToolBar.value =
        "  bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor removeformat | ltr rtl";
      MyHeight.value = "50";
    }
    if (props.word) {
      (MyToolBar.value =
        "mybutton  undo redo | menu1 menu2 menu3 menu4 menu5 menu6 menu7 menu8 menu9 menu10 | blocks fontfamily fontsize | bold italic underline forecolor backcolor | align   checklist bullist numlist | table | preview | ltr rtl | removeformat | indent outdent | pagebreak lineheight | fullscreen    print | insertfile image   template "),
        (MyHeight.value = "800");
    }

    if (props.tiny) {
      (MyToolBar.value = "bold italic underline forecolor"),
        (MyHeight.value = "150");
      Plugins.value = "directionality";
    }

    var MenuItem: any = ref({});

    var init = {
      selector: "textarea",
      menu: MenuItem.value,
      directionality: "rtl",
      language: "ar",
      min_height: MyHeight.value,
      height: MyHeight.value,
      branding: false,
      width: props.width,
      autoresize: true,
      plugins: Plugins.value,

      toolbar: MyToolBar.value,
      toolbar_sticky: !(props.tiny || props.mini),
      toolbar_sticky_offset: isSmallScreen ? 172 : 178,
      autosave_ask_before_unload: true,
      toolbar_mode: "sliding",
      skin: useDarkMode ? "oxide-dark" : "oxide",
      //content_css: useDarkMode ? "dark" : "default",
      content_css: "/assets/editor.css",
      font_css: ["/assets/editor.css"],
      toolbar_location: props.tiny ? "bottom" : "top",
      menubar:
        props.tiny || props.mini
          ? false
          : "  menu1 menu2 menu3 menu4 menu5 menu6 menu7 menu8 menu9 menu10 ",
      statusbar: !(props.tiny || props.mini),
      content_style: props.word
        ? `
    body {
        background: #fff;
    }

    @media (min-width: 840px) {
        html {
            background: #eceef4;
            min-height: 100%;
            padding: .5rem;
        }

        body {
            background-color: #fff;
            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
            box-sizing: border-box;
            margin: 1rem auto 0;
            max-width: 820px;
            min-height: calc(100vh - 1rem);
            padding: 4rem 6rem 6rem 6rem;
        }
    }
    `
        : "",
      custom_elements: "span",
      // Enable Spell Checker Pro and specify the default and available languages
      // https://www.tiny.cloud/docs/tinymce/6/introduction-to-tiny-spellchecker/
      spellchecker_active: true,
      spellchecker_language: "en_US",
      spellchecker_languages:
        "English (United States)=en_US,English (United Kingdom)=en_GB,Danish=da,French=fr,German=de,Italian=it,Polish=pl,Spanish=es,Swedish=sv",
      theme_advanced_fonts:
        "Arial=arial,helvetica,sans-serif;Courier New=courier new",
      style_formats: [
        {
          title: "Open Sans",
          inline: "span",
          styles: { "font-family": "Open Sans" },
        },
        { title: "Arial", inline: "span", styles: { "font-family": "arial" } },
      ],
      setup: function (editor) {
        CreateMenu(editor, props.menu, MenuItem);
      },
    };

    return {
      init,
      OpenModalUpdate,
      MyValue,
    };
  },
};
