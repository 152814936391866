import { RouteRecordRaw } from "vue-router";

const companyInfo: RouteRecordRaw = {
  path: "companyInfo",
  component: () => import("@/views/public/index.vue"),
  meta: {
    title: "بيانات الشركة",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "companyInfo",
      component: () => import("@/views/public/companyInfo/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "بيانات الشركة",
      },
    },
    {
      path: "Edit",
      props: true,
      name: "Edit-companyInfo",
      component: () => import("@/views/public/companyInfo/pages/edit.vue"),
      meta: {
        pageId: 18010400,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default companyInfo;
