import { RouteRecordRaw } from "vue-router";

const Budget: RouteRecordRaw = {
  path: "budget",
  component: () => import("@/views/accounts/index.vue"),
  meta: {
    title: "Budget"
  },
  children: [
    {
      path: "",
      name: "budget",
      component: () => import("@/views/accounts/budget/pages/index.vue"),
      meta: {
        pageId: 17010600,
        role: "view",
        title: "Budget"
      },
    },
  ],
};

export default Budget;
