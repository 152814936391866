import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./core/plugins/i18n";
import globalMixin from "./mixins";

import { initVeeValidate } from "@/core/plugins/vee-validate";
import enums from "./enums";
const app = createApp(App);
app.config.globalProperties.$enums = enums;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $enums: typeof enums;
  }
}

app.use(i18n);
app.use(router);
initVeeValidate();

import store from "./store";

import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import Notifications from "@kyvg/vue3-notification";

import "@/core/plugins/prismjs";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import Components from "./core/registerGlobalComponent";
import Directives from "./core/directives/register";
import timeago from "vue-timeago3";
import VueVideoPlayer from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import naive from "naive-ui";
import JitsiMeeting from "@jitsi/vue-sdk";
import { DraggablePlugin } from "@braks/revue-draggable";

app.use(naive);
app.use(JitsiMeeting);
app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(ElementPlus);
app.mixin(globalMixin);
app.use(timeago);
app.use(VueVideoPlayer);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
app.use(Notifications);

app.use(DraggablePlugin);

Components.register(app);
Directives.register(app);

app.mount("#app");
