import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import Identity from "@/store/modules/Identity";
import LookUps from "@/store/modules/LookUps";
import AssetCategory from "@/store/accounts/assetCategory/assetCategory";
import Assets from "@/store/accounts/assets/assets";
import AssetsBalance from "@/store/accounts/assetsBalance/assetsBalance";
import AccountsTree from "@/store/accounts/accountsTree/accountsTree";
import BalanceSheet from "@/store/accounts/balanceSheet/balanceSheet";
import Budget from "@/store/accounts/budget/budget";
import ClosingTheFiscalYear from "@/store/accounts/closingthefiscalyear/closingthefiscalyear";
import DebentureAssets from "@/store/accounts/debentureAssets/debentureAssets";
import Debentures from "@/store/accounts/debentures/debentures";
import FinancialCenterList from "@/store/accounts/financialCenterList/financialCenterList";
import IncomeList from "@/store/accounts/incomeList/incomeList";
import Ledger from "@/store/accounts/ledger/ledger";
import RevenuesAndExpenses from "@/store/accounts/revenuesAndExpenses/revenuesAndExpenses";
import Transaction from "@/store/accounts/transactions/transactions";
import TrialBalance from "@/store/accounts/trialBalance/trialBalance";
import EmailSetting from "@/store/public/emailSetting";
import Reports from "@/store/public/reports/reports";
import ReportTemplates from "@/store/public/reports/reportTemplates";
import Roles from "@/store/public/roles/roles";

import Customers from "@/store/customers/customers";
import Employees from "@/store/employees/employees";
import Countries from "@/store/public/countries";
import Cities from "@/store/public/cities";
import Companies from "@/store/companies/companies";
import Jobs from "@/store/public/jobs";
import Categories from "@/store/public/categories";

import Products from "@/store/public/products";
import Branches from "@/store/branches";
import Additions from "@/store/public/additions";
import CompanyInfo from "@/store/companies/companyInfo";
import CustomersStatistics from "@/store/statistics/customersStatistics";
import ProductsStatistics from "@/store/statistics/productsStatistics";
import BranchesSales from "@/store/statistics/branchesSales";
import PurchasesReport from "@/store/statistics/purchasesReport";
import TaxReport from "@/store/statistics/taxReport";
import TopSellingReport from "@/store/statistics/topSellingReport";
import EmployeeSales from "@/store/statistics/employeeSales";

config.rawError = true;

const store = createStore({
  modules: {
    AssetsBalance,
    Assets,
    AssetCategory,
    Debentures,
    DebentureAssets,
    ClosingTheFiscalYear,
    Budget,
    BalanceSheet,
    TrialBalance,
    Transaction,
    RevenuesAndExpenses,
    Ledger,
    IncomeList,
    FinancialCenterList,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    LookUps,
    Identity,
    AccountsTree,
    Reports,
    ReportTemplates,
    Roles,
    EmailSetting,
    Customers,
    Employees,
    Countries,
    Cities,
    Companies,
    Jobs,
    Categories,
    Additions,
    Products,
    Branches,
    CompanyInfo,
    CustomersStatistics,
    ProductsStatistics,
    BranchesSales,
    PurchasesReport,
    TaxReport,
    TopSellingReport,
    EmployeeSales
  },
});

export default store;
