import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VSwatches = _resolveComponent("VSwatches")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    rule: {
      required: $props.required,
      message: $props.message,
      trigger: ['input', 'blur'],
    },
    label: $props.label,
    class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
    path: $props.path,
    "show-label": $props.ShowLabel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VSwatches, {
        modelValue: $setup.MyValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
        disabled: $props.disabled,
        placeholder: $props.placeholder,
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
        "show-fallback": !$props.blackAndWhite,
        "fallback-input-type": "color",
        "popover-x": "left",
        swatches: $setup.swatches
      }, null, 8, ["modelValue", "disabled", "placeholder", "show-fallback", "swatches"])
    ]),
    _: 1
  }, 8, ["rule", "label", "class", "path", "show-label"]))
}