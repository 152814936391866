import { RouteRecordRaw } from "vue-router";
const Roles: RouteRecordRaw = {
  path: "roles",
  component: () => import("@/views/public/index.vue"),
  meta: {
    title: "Reports",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "roles",
      component: () => import("@/views/public/system/roles/pages/index.vue"),
      meta: {
        pageId: 18020100,
        role: "view",
        title: "Privileges",
      },
    },
    {
      path: "Add",
      name: "Add New roles",
      component: () => import("@/views/public/system/roles/pages/add.vue"),
      meta: {
        pageId: 18020100,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "Edit/:Id",
      name: "Edit roles",
      component: () => import("@/views/public/system/roles/pages/add.vue"),
      meta: {
        pageId: 18020100,
        role: "edit",
        title: "Edit",
      },
    },
    {
      path: ":Id",
      name: "roles Screen",
      component: () =>
        import("@/views/public/system/roles/pages/roleScreen.vue"),
      meta: {
        pageId: 18020100,
        role: "view",
        title: "Viewdata",
      },
    },
  ],
};

export default Roles;
