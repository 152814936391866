<template>
  <div
    :class="`btn btn-light-${color} btn-sm pulse pulse-${color} ms-3`"
    icon="fa-layer-plus"
    v-if="IsPrint()"
  >
    <i class="fa fa-print"></i>
    {{ $t("print") }}
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "طباعة",
    },
    color: {
      type: String,
      required: false,
      default: "success",
    },
  },
};
</script>
