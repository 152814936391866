const old_en = {
  messages: {
    "validation.messages.required": `This field is required.`,
    "validation.messages.email": `This field must be a valid email.`,
    "validation.attributes.email": `Email Address`,
  },
  isExport: "Export",
  isImport: "Import",
  docsAndComponents: "docsAndComponents",
  insuranceNumber: "insurance Number",
  hasInsurances: "has Insurances",
  qualification: "qualification",
  qualificationYear: "qualification Year",
  hiringDate: "Hiring Date",
  Job: "Job",
  CareerHistory: "Career History",
  Suspensions: "Suspensions",
  Workingchildren: "Working children",
  ReportsandForms: "Reports and Forms",
  UserandPermissions: "User and Permissions",
  Viewdata: "View data",
  Siblings: "Siblings",
  units: "Units",
  ManagementAndDistribution: "Management and distribution",
  StudentDistribution: "Student distribution",

  ministerialCode: "ministerial Code",
  level: "level",
  grade: "grade",
  classroom: "classroom",

  storeCategories: "storeCategories",
  SubscribeNewsletter: "Subscribe To the newsletter",
  SubscribeMsg: "Subscribe to receive the coolest cuts on your email",
  customersServices: "customers-services",
  HelpAndInstructions: "Help And Instructions",
  Privacy: "Privacy",
  Listbrands: "brands List",
  ContactUs: "Contact Us",
  maintenanceRequest: "maintenance request",
  ComplaintsAndProposals: "Complaints And Proposals",
  vacanciesJobs: "vacancies Jobs",
  FestivalTerms: "Festival Terms",
  SiteMap: "Site Map",
  TodayOffers: "Today's Offers",
  Stores: "Store",
  MaintenanceRequest: "Maintenance Request",
  Catalogue: "Catalogue",
  WelcomeVisitor: "Welcome Visitor",
  Please: "Please",
  OR: "OR",
  questions: "Questions",
  Quizzes: "Quizzes",
  CorrectQuizzes: "Correct",
  quizzesManagement: "Quizzes Management",

  Budget: "Budget",
  NetValue: "Net Value",

  FixedAssets: "Fixed assets",
  CurrentAssets: "Current Assets",

  CurrentLiabilities: "Current Liabilities",
  Ownership: "Ownership",

  //FinancialCenterList: "Statement of financial position",

  TotalGainsAndLossesRetandy: "Total profit and loss",

  Scheduling: "Scheduling",

  QuizStartDate: "Quiz Start Date",

  Toorder: "To Order",
  FromAccount: "From Account",
  ToAccount: "To Account",
  PaymentMethod: "Payment Method",
  AttachFile: "Attach File",

  Transfare: "Transfare",
  Cash: "Cash",
  Check: "Check",
  Network: "Network",

  GeneralVoucher: "General Voucher",
  GeneralReceiptVoucher: "General Receipt Voucher",

  VoucherNumber: "Voucher NO.",

  Amount: "Amount",

  BondType: "Bond Type",

  BuyFixedAsset: "Buy Fixed Asset",
  SellFixedAsset: "Sell Fixed Asset",
  Receipts: "Receipts",
  Expenses: "Expenses",
  Refund: "Refund",
  PenaltyPayment: "Penalty Payment",
  ForeignCurrency: "Foreign Currency",
  FixedAssetTransactions: "Fixed Asset Transactions",

  PaySafely: "Pay Safely",
  withSadadPayementSystem: "with Sadad Payement System",
  HomeDelivery: "Home Delivery",
  ShoppingCart: "Shopping Cart",
  AddEmailMsg: "Add Email Here",
  AddPhoneMsg: "Add Phone Here",
  subscription: "subscription",
  SortFor: "SortFor",

  Sorting: "Sorting",

  Loading: "Loading",
  Discount: "Discount",
  Default: "Default",
  PriceHighToLow: "Price: High To Low",
  PriceLowToHigh: "Price: Low To High",
  MostViewed: "Most Viewed",
  SpecialOffers: "Special Offers",
  UserName: "User Name",
  Password: "Password",
  Entry: "Entry",
  RememberMe: "Save The Password ?",
  RegisterNow: "Register Now",
  NoAccount: "No Account",
  EnterUserName: "Enter User Name",
  EnterPassword: "Enter Password",
  OutOfStock: "Out Of Stock",
  currency: "EGP",
  AddToCart: "Add To Cart",
  Compare: "Compare",
  AddToFavorites: "Add To Favorites",
  PleaseTypeSomething: "Please type something",
  PhoneNumber: "Phone Number",
  ConfirmPassword: "Confirm Password",
  Department: "Department | Departments",
  Price: "Price",
  PriceFrom: "Price From",
  To: "To",
  Pay: "Pay",
  ContinueShopping: "Continue Shopping",
  UnitPrice: "Unit Price",
  ProductDetails: "Product Details",
  empAndAdmin: "Employee and administration",
  personalData: "Personal data",
  employeePrivileges: "Employee privileges",
  employeeRating: "Employee Rating",
  contactData: "Contact Data",
  Employeenumber: "Employee number",
  Name: "Arabic Name",
  NameEn: "Engilsh Name",
  sex: "Sex",
  Religion: "Religion",
  NationalID: "National ID",
  Nationality: "Nationality",
  DateofBirth: "Date of Birth",
  BirthGovernorate: "Birth Governorate",
  phoneNumber: "Phone number",
  Email: "E-mail",
  Accountlogindata: "Account login data",
  username: "Username",
  ResetPassword: "Reset Password",
  privilegesavailable: "privileges available",
  toCancelPrivileges:
    "Please click on the cancel icon to delete the privileges",
  toAddPrivileges: "Please click on the add icon to add the privileges",
  employee: "Employee",
  data: "Data",
  add: "Add",
  print: "Print",
  back: "Back",
  employeeData: "Employee Data",
  password: "Password",
  cancel: "Cancel",
  save: "Save",
  createLoginInfo: "Create Login information",
  empPhoto: "Empolyaa photo ",
  father: "Father",
  Grandpa: "Grandpa",
  FirstName: "First Name",
  LastName: "Last Name",

  father_en: "father En",
  Grandpa_en: "Grandpa En",
  FirstName_en: "First Name en",
  LastName_en: "Last Name en",

  ViewAll: "View all",
  ProductsFound: "Products found",
  Muslim: "Muslim",
  Christian: "Christian",
  Male: "Male",
  Female: "Female",
  City: "City",
  Area: "Area",
  address: "Address",
  ProductName: "Product Name",
  TOTAL: "TOTAL",
  ProceedToCheckout: "Proceed to checkout",
  Subtotal: "Subtotal",
  Quicklinks: "Quick links",
  AboutUs: "About Us",
  TermsAndCondition: "Terms And Condition",
  ReturnPolicy: "ReturnPolicy",
  Login: "Login",
  Register: "Register",
  Model: "Model",
  BestSellerBrands: "Best Seller Brands",
  Brand: "Brand",
  Color: "Color",
  quantity: "quantity",
  Description: "Description",
  Specification: "Attributes",
  whatAreYouLookingFor: "what are you looking for?",
  logout: "logout",
  Welcome: "Welcome",
  BanatEg: "Banat Eg",
  BuyNow: "Buy Now",
  IAcceptTheLicenseAndTerms: "I accept the license and terms",
  Brands: "Brands",
  BrandsOffers: "Brands Offers",
  NationalIdPlace:
    "The national number of the card and the computer birth certificate",
  chat: "chat",
  Search: "Search",
  EditThis: "Edit",
  AddNew: "Add New",
  teacherPhoto: "Teacher Photo",
  Numberofpages: "Number of pages",
  for: "For",
  GuardianName: "Guardian Name",
  ParentEnglishname: "Parent's name is English",
  relationship: "Relationship",
  nationalguardian: "The national number of the guardian",
  GroundPhoneNumber: "Ground Phone Number",
  Parentmobilenumber: "Parent's mobile number",
  MothernameEnglish: "Mother's name is English",
  Mothername: "Mother's name ",
  nationalnumbermother: "The national number of the mother",
  Groundphonemother: "Ground phone number for the mother",
  MobilenumberMother: "Mobile number of the mother",
  Studentphoto: "Student photo",
  Mother: "Mother",
  Brother: "Brother",
  Sister: "Sister",
  Grandma: "Grandma",
  Student: "Student",
  Teacher: "Teacher",
  Employee: "Employee",
  NationalNumberGuardian: "National number of guardian",
  Neighborhood: "Neighborhood",
  StudentInformation: "Student Information",
  Teachernumber: "Teacher number",
  Studentnumber: "Student number",
  Academicyearandmaterials: "Academic year and materials",
  TeacherRating: "Teacher Rating",
  TeacherData: "Teacher Information",
  Guardianandmother: "Guardian and mother",
  StudentRating: "Student Rating",
  Studentdebts: "Student debts",
  Printstudentreports: "Print student reports",
  Clickupload: "Click here to upload the image",
  approval: "Approval",
  Cancelapproval: "Cancel approval",

  YearName: "Year Name",
  DistributionDate: "Distribution Date",
  DistributionStatus: "Distribution Status",
  DistributionEmployeeName: "Distribution Employee",
  DeleteEmployeeName: "Delete Employee",
  DeleteDate: "Delete Date",

  Status: "Status",

  CloseDate: "Close Date",
  CloseStatus: "Close Status",
  CloseEmployeeName: "Close Employee Name",
  DeleteCloseEmployeeName: "Cancel Employee Name",
  DeleteCloseDate: "Cancel Date",

  Jobname: "Job name",
  ItemName: "Name",
  StatementAccount: "Statement / account",
  Firstbalance: "First balance",
  Accountbalance: "Account balance",
  Closingbalance: "Closing balance",
  Debit: "Debit",
  Creditor: "Creditor",
  AllTransaction: "All transaction",

  TransactionNo: "Transaction No",

  TransactionType: "Transaction Type",

  Account: "Account",
  Accountnumber: "Account number",
  Type: "Type",
  Levels: "Levels",
  Educationallevelsofstudyclasses: "Educational levels of study classes",
  deActivate: "deActivate",
  Movementsduringtheperiod: "Movements during the period",
  ShortNameArabic: "Short Name Arabic",
  ShortNameEnglish: "Short Name English",
  SubjectName: "Subject Name",
  Total: "Total",
  numberOfUsers: "Users number",
  All: "All",
  View: "View",
  Edit: "Edit",
  Add: "Add",
  Delete: "Delete",
  Statistics: "Statistics",
  Educationallevels: "Educational levels",
  Activatingtheeducationallevelsoftheschool:
    "Activating the educational levels of the school",
  AddLevel: "Add Level",
  DeleteLevel: "Delete Level",
  Note: "Note",
  bookPhoto: "Book Photo",
  Author: "Author",
  Category: "Category",
  publishingHouse: "publishing House",
  ItemCode: "Item Code",
  ItemCount: "Item Count",
  CreationDate: "Creation Date",
  Book: "Book",
  CategoryName: "Category Name",
  AuthorName: "Author Name",

  PublishingHouses: "Publishing Houses",
  ReportsName: "Reports Name",
  ReportsHeaderAr: "Arabic Title",
  ReportsHeaderEn: "English Title",
  ReportSort: "Sort",
  Screen: "Screen",
  TRANSLATOR: {
    SELECT: "Select your language",
  },

  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
};
export default old_en;
