import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "element" }
const _hoisted_2 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['material', $props.small ? 's' : 'm']),
    style: _normalizeStyle(['background-color:' + $props.backColor + ';', 'color:' + $props.foreColor + ';'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString($props.small ? $props.ShortName : $props.Name), 1)
    ])
  ], 6))
}