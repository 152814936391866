import { RouteRecordRaw } from "vue-router";
const Transactions: RouteRecordRaw = {
  path: "transactions",
  component: () => import("@/views/accounts/index.vue"),
  meta: {
    title: "Transaction",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "Transactions",
      component: () => import("@/views/accounts/transactions/pages/index.vue"),
      meta: {
        pageId: 17030000,
        role: "view",
        title: "Transaction",
      },
    },

    {
      path: "add",
      name: "Add New Transaction",
      component: () => import("@/views/accounts/transactions/pages/add.vue"),
      meta: {
        pageId: 17030000,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "view/:Id",
      name: "view Transaction",
      component: () => import("@/views/accounts/transactions/pages/view.vue"),
      meta: {
        pageId: 17030000,
        role: "view",
        title: "AddNew",
      },
    },

 
    {
      path: "editForGroup/:id",
      name: "edit New Transaction",
      component: () => import("@/views/accounts/transactions/pages/add.vue"),
      meta: {
        pageId: 17030000,
        role: "add",
        title: "AddNew",
      },
    },
    {
      path: "edit/:Id",
      name: "edit Transaction",
      component: () => import("@/views/accounts/transactions/pages/add.vue"),
      meta: {
        pageId: 17030000,
        role: "edit",
        title: "Edit",
      },
    },
  ],
};

export default Transactions;
