import { RouteRecordRaw } from "vue-router";
const DebentureAssets: RouteRecordRaw = {
  path: "debentureAssets",
  component: () => import("@/views/accounts/index.vue"),

  meta: {
    title: "Debentures",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "debentureAssets",
      component: () =>
        import("@/views/accounts/debentureAssets/pages/index.vue"),
      meta: {
        pageId: 17040200,
        role: "view",
        title: "FixedassetDebentures",
      },
    },
    {
      path: "Add/:Id",
      name: "Add New debentureAssets",
      component: () => import("@/views/accounts/debentureAssets/pages/add.vue"),
      meta: {
        pageId: 17040200,
        role: "add",
        title: "  AddNew",
      },
    },
    {
      path: "Edit/:Id",
      props: true,
      name: "Edit DebentureAssets",
      component: () => import("@/views/accounts/debentureAssets/pages/add.vue"),
      meta: {
        pageId: 17040200,
        role: "edit",
        title: "Edit",
      },
    },

    {
      path: "view/:Id",
      props: true,
      name: "debentureAssets view",
      component: () =>
        import("@/views/accounts/debentureAssets/pages/view.vue"),
      meta: {
        pageId: 17040200,
        role: "view",
        title: "FixedassetDebentures",
      },
    },
  ],
};

export default DebentureAssets;
