import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-size-h5 text-primary pe-3" }
const _hoisted_2 = { class: "cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_crumb_name = _resolveComponent("l-crumb-name")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString($props.model.accountNumber), 1),
    _createVNode(_component_el_popover, {
      placement: "top-end",
      trigger: "hover"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString($props.model.accountName), 1)
      ]),
      default: _withCtx(() => [
        ($props.model.fullName)
          ? (_openBlock(), _createBlock(_component_l_crumb_name, {
              key: 0,
              fullName: $props.model.fullName,
              accountName: $props.model.accountName
            }, null, 8, ["fullName", "accountName"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}