import { Module, Action } from "vuex-module-decorators";
import PageModule from "@/core/base/PageModule";
import Filter from "./models/filter";
import { error } from "@/core/base/systemAlert";

@Module({ namespaced: true })
export default class ClosingTheFiscalYear extends PageModule {
  override endPoint = "FinancialClosure";
  override indexUrl = "/CloseOfFinancialYear";
  override filter = new Filter();

  @Action
  Close(year) {
    if (Number(year.value) === 0) {
      return error("برجاء ادخال سنة مناسبة");
    }
    const { context, endPoint } = this;
    return context.dispatch("Post", {
      url: `${endPoint}/Close?Year=${year.value}`,
      GetIndex: true,
    });
  }

  @Action
  Reverse(closeOfFinancialYearId) {
    if (Number(closeOfFinancialYearId) === 0) {
      return error("برجاء ادخال سنة مناسبة");
    }
    const { context, endPoint } = this;
    return context.dispatch("Post", {
      url: `${endPoint}/Reverse?CloseOfFinancialYearId=${closeOfFinancialYearId}`,
      GetIndex: true,
    });
  }
}
