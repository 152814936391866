
export default {
  props: {
    to: String,
    NoRule: {
      type: Boolean,
      default: () => false,
    },
  },
};
