
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "aAccountDetails",
  components: {
    Item: defineAsyncComponent(
      () => import("@/views/accounts/accountStatement/partial/_item.vue")
    ),
  },
  props: {
    openDrawer: {
      type: Boolean,
    },
    aid: {
      type: Number,
    },
    df: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    let open = ref(props.openDrawer);
    onMounted(async () => {
      await GetItem();
    });

    const loading = computed(() => store.getters[`Ledger/GetLoading`]);
    const models = computed(() => store.getters[`Ledger/GetModel`]);
    const GetItem = async () => {
      if (props.aid != undefined && props.aid != 0)
        await store.dispatch(`Ledger/GetAccountDetials`, {
          df: props.df,
          aid: props.aid,
        });
    };
    return { models, loading, open };
  },
});
