
import { defineComponent, ref, computed } from "vue";
import { message } from "@/core/base/naive";
import type { UploadCustomRequestOptions } from "naive-ui";
import * as tus from "tus-js-client";
import JwtService from "@/core/services/JwtService";
export default defineComponent({
  props: {
    value: String,
    path: String,
  },
  setup(props, { emit }) {
    let UploadFileName = ref("");
    let UploadedImage = ref(false);
    const ImagePath = computed(() => {
      if (UploadedImage.value) {
        return `https://localhost:44363/up/CloudStorage/${props.value}`;
      } else {
        return `${props.path}/${props.value}`;
      }
    });

    const customRequest = ({
      file,
      onFinish,
      onError,
      onProgress,
    }: UploadCustomRequestOptions) => {
      console.log("File d", file.name, file.type);
      var upload = new tus.Upload(file.file as File, {
        endpoint: "https://localhost:44363/api/Cloud/upload",
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          name: file.name.toString(),
          contentType: file.type || "application/octet-stream",
          type: file.type?.split("/")[0] || "",
        },
        headers: {
          Authorization: "bearer " + JwtService.get(),
          extension: file.name.split(".").pop() || "",
          FileType: file.type ?? "",
        },
        onError: function (error) {
          message.error("ملف غير مقبول");
          //console.log("Error Upload", error.message);
          onError();
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          onProgress({ percent: Math.ceil(parseInt(percentage)) });
        },
        onSuccess: function () {
          UploadFileName.value =
            upload.url?.split("/").pop() +
            "." +
            upload.file["name"].split(".").pop();
          onFinish();
          emit("update:value", UploadFileName);
          UploadedImage.value = true;
        },
      });

      upload.findPreviousUploads().then(function (previousUploads) {
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }
        upload.start();
      });
    };
    const DeleteImage = () => {
      emit("update:value", null);
      UploadFileName.value = "";
    };
    return {
      customRequest,
      UploadFileName,
      DeleteImage,
      ImagePath,
    };
  },
});
