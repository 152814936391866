
import { defineComponent, ref } from "vue";
import { useLoadingBar } from "naive-ui";
export default defineComponent({
  components: {},
  setup() {
    const loadingBar = useLoadingBar();
    loadingBar.start();
    return {
      loadingBarTargetRef: ref<undefined | HTMLElement>(undefined),
    };
  },
});
