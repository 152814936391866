import { RouteRecordRaw } from "vue-router";
import Products from "@/views/reports/products/router";
import Customers from "@/views/reports/customers/router";
import BranchesSales from "@/views/reports/branchesSales/router";
import PurchasesReport from "@/views/reports/purchasesReport/router";
import TaxReport from "@/views/reports/taxReport/router";
import TopSellingReport from "@/views/reports/topSellingReport/router";
import EmployeeSales from "@/views/reports/employeeSales/router";
import AccountClosuresReport from "@/views/reports/accountClosuresReport/router";

const Public: RouteRecordRaw = {
  path: "Reports",
  component: () => import("@/views/reports/index.vue"),
  children: [
    Products,
    Customers,
    BranchesSales,
    PurchasesReport,
    TaxReport,
    TopSellingReport,
    EmployeeSales,
    AccountClosuresReport,
  ],
  meta: {
    title: "لوحة التحكم",
    module: "Reports",
    svgIcon: "/media/icons/duotune/general/gen019.svg",
  },
};
export default Public;
