
import { defineComponent, ref } from "vue";
import LanguageService from "@/core/services/language";

export default defineComponent({
  emits: ["Print", "toggleDrawer"],
  name: "SidebarPrint",
  props: {
    model: {
      type: Array,
      required: true,
    },
    drawer: {
      type: Boolean,
      default: false,
    },
    modelName: String,
  },
  setup(props, { emit }) {
    const search = ref("");
    const isOpen = ref(props.drawer);
    const listOfReports: any = ref([]);

    const onSearch = () => {
      listOfReports.value = [];
      props.model.forEach((item: any) => {
        if (item.name.includes(search.value)) {
          listOfReports.value.push(item);
        }
      });
    };
    const handleOpen = () => {
      listOfReports.value = props.model;
      isOpen.value = props.drawer;
    };

    const onPrintReport = async (model) => {
      emit("Print", model);
    };
    const toggleDrawer = () => {
      emit("toggleDrawer", !props.drawer);
    };
    let Direction = LanguageService.get() == "en" ? "ltr" : "rtl";

    return {
      onPrintReport,
      handleOpen,
      search,
      listOfReports,
      onSearch,
      toggleDrawer,
      isOpen,
      Direction,
    };
  },
});
