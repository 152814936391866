import { Module } from "vuex-module-decorators";

import FormModule from "@/core/base/FormModule";
import Filter from "./models/filter";

@Module({ namespaced: true })
export default class DebentureAssets extends FormModule {
  override endPoint = "Debentures";
  override indexUrl = "/Assets";
  override filter = new Filter();
}
