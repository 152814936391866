import router from "@/router";

export const CleanQueryString = (source: object): object | null => {
  if (source) {
    const NewObj = JSON.parse(JSON.stringify(source));
    Object.keys(NewObj).forEach((key) => {
      if (
        NewObj[key] === undefined ||
        NewObj[key] == "" ||
        (key == "p" && parseInt(NewObj[key]) == 1)
      ) {
        delete NewObj[key];
      }
    });
    return NewObj;
  } else {
    return source;
  }
};

export const SetModelFilterFromQueryString = (
  Filter: object,
  QueryString: string
): object | null => {
  const NewObj = JSON.parse(JSON.stringify(QueryString));
  Object.keys(NewObj).forEach((key) => {
    if (key == "p" || key == "s") {
      Filter[key] = parseInt(NewObj[key]);
    } else {
      Filter[key] = NewObj[key];
    }
  });
  return Filter;
};

export const FixQueryString = (source: object): object => {
  if (source) {
    const NewObj = JSON.parse(JSON.stringify(source));
    Object.keys(NewObj).forEach((key) => {
      if (
        NewObj[key] === undefined ||
        NewObj[key] == "" ||
        key == "ti" ||
        (key == "p" && parseInt(NewObj[key]) == 1) ||
        (key == "ps" && parseInt(NewObj[key]) == 24)
      ) {
        delete NewObj[key];
      }
    });
    return NewObj;
  } else {
    return source;
  }
};

export const FixFilter = (source: object, remove: string | null): object => {
  if (source) {
    const NewObj = JSON.parse(JSON.stringify(source));
    Object.keys(NewObj).forEach((key) => {
      if (
        NewObj[key] === undefined ||
        NewObj[key] == "" ||
        (key == "p" && NewObj[key] == 1) ||
        remove == key
      ) {
        delete NewObj[key];
      }
    });
    return NewObj;
  } else {
    return source;
  }
};

export const AddToObject = (source: object, add: string | null): object => {
  if (source) {
    const Obj = FixFilter(source, null);
    if (add) {
      const NewObj = JSON.parse(JSON.stringify(add));
      Object.keys(NewObj).forEach((key) => {
        Obj[key] = NewObj[key];
      });
      return Obj;
    } else {
      return source;
    }
  } else {
    return source;
  }
};

export const PushRouter = (filter: object): object => {
  const f = AddToObject(filter, null);
  const Query = FixQueryString(f);

  router
    .push({
      path: router.currentRoute.value.path,
      query: { ...Query },
    })
    .catch((err) => err);
  return Query;
};

export const SetStartPageQueryString = (filter: object): object | null => {
  const Query = router.currentRoute.value.query;
  Object.keys(Query).forEach((key) => {
    filter[key] = Query[key];
  });
  return filter;
};

// export const AlertDelete = (  msg,
//     _title = "هل انت متاكد من الحذف",
//     _confirmButtonText = "نعم , تاكيد الحذف",
//     _confirmButtonColor = "#d33"): object | null => {
//     return Swal.fire({
//         title: _title,
//         text: msg,
//         icon: "warning",
//         showCancelButton: true,
//         cancelButtonColor: "#3085d6",
//         cancelButtonText: "لا الغاء الامر",
//         confirmButtonColor: _confirmButtonColor,
//         confirmButtonText: _confirmButtonText,
//       });

// }

// export const AlertconfirmDeleted = (msg : string)  => {
//     return Swal.fire("تم الحذف", msg, "success");
// }

export default {
  FixFilter,
  AddToObject,
  SetModelFilterFromQueryString,
  FixQueryString,
  CleanQueryString,
  PushRouter,
  SetStartPageQueryString,
  // AlertDelete,
  // AlertconfirmDeleted,
};
