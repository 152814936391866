
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "PageItemView",
  props: {
    modelName: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const ModelName = ref(props.modelName);
    const root = ModelName.value;

    const loading = computed(() => store.getters[`${root}/GetLoading`]);
    const model = computed(() => store.getters[`${root}/GetModel`]);

    return {
      loading,
      model,
    };
  },
});
