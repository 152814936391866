<template>
  <button type="button" :class="['btn btn-sm   ms-3', 'btn-light-' + color]">
    <inline-svg v-if="svg" :src="svg" />
    <i v-if="icon" :class="icon"></i>
    {{ text }}
  </button>
</template>
<script>
export default {
  props: {
    to: String,
    icon: String,
    svg: String,
    text: String,
    color: { default: "primary", type: String },
  },
};
</script>
