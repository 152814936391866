<template>
  <video-player
    :src="url"
    class="video-player-box"
    controls
    :loop="true"
    :volume="0.6"
  >
  </video-player>
</template>

<script>
import { Config } from "@/core/services/config";

export default {
  data() {
    return {
      url: Config.POST_MEDIA + this.file.fileName,
    };
  },

  props: {
    file: {
      type: Object,
    },
  },
};
</script>
<style>
.video-js[tabindex="-1"] {
  width: auto;
  height: 360px;
}
</style>
