import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_breadcrumb_item = _resolveComponent("n-breadcrumb-item")!
  const _component_n_breadcrumb = _resolveComponent("n-breadcrumb")!

  return ($props.fullName)
    ? (_openBlock(), _createBlock(_component_n_breadcrumb, {
        key: 0,
        class: "mr-0"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fullName.split(' / '), (i, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              i
                ? (_openBlock(), _createBlock(_component_n_breadcrumb_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(i), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          _createVNode(_component_n_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.accountName), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}