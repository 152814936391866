import { Module, Action } from "vuex-module-decorators";
import PageModule from "@/core/base/PageModule";
import Filter from "./models/filter";
import { PushRouter } from "@/core/base/app";
import FormModule from "@/core/base/FormModule";

@Module({ namespaced: true })
export default class IncomeList extends FormModule {
  override endPoint = "Accounts";
  override indexUrl = "/IncomeList";
  override filter = new Filter();

  @Action
  PrintIncomeList(fileName) {
    const { context, endPoint } = this;
    const Query = PushRouter(this.filter);
    return context.dispatch("Download", {
      url: endPoint + "/ExportIncomeList",
      form: Query,
      fileName: fileName,
    });
  }
}
