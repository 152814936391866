import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_container = _resolveComponent("page-container")!
  const _component_w_form = _resolveComponent("w-form")!

  return (_ctx.dynamicForm)
    ? (_openBlock(), _createBlock(_component_w_form, {
        key: 0,
        onOnSubmitPageForm: _ctx.onSubmitForm,
        label: _ctx.label,
        backTo: _ctx.backTo,
        backAction: _ctx.backAction,
        form: _ctx.model,
        dynamicForm: _ctx.dynamicForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_page_container, { loading: _ctx.loading }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {
                model: _ctx.model,
                loading: _ctx.loading
              })
            ]),
            _: 3
          }, 8, ["loading"])
        ]),
        _: 3
      }, 8, ["onOnSubmitPageForm", "label", "backTo", "backAction", "form", "dynamicForm"]))
    : (_openBlock(), _createBlock(_component_w_form, {
        key: 1,
        onOnSubmitPageForm: _ctx.onSubmitForm,
        label: _ctx.label,
        backTo: _ctx.backTo,
        backAction: _ctx.backAction,
        form: _ctx.model
      }, {
        default: _withCtx(() => [
          _createVNode(_component_page_container, { loading: _ctx.loading }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {
                model: _ctx.model,
                loading: _ctx.loading
              })
            ]),
            _: 3
          }, 8, ["loading"])
        ]),
        _: 3
      }, 8, ["onOnSubmitPageForm", "label", "backTo", "backAction", "form"]))
}