<template>
  <span v-if="!value" class="badge badge-light-success text-justify">مفعل</span>
  <span v-else class="badge badge-light-danger text-justify">غير مفعل </span>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>
