<template>
  <div class="form-group row mb-3">
    <label class="col-xl-3 col-lg-3 col-form-label">{{ title }}</label>
    <div class="col-lg-9 col-xl-9">
      <div class="input-group input-group-lg input-group-solid">
        <div v-if="icon" class="input-group-prepend">
          <span class="input-group-text">
            <i class="la la-at"></i>
          </span>
        </div>
        <div
          v-if="trueTitle == null"
          class="form-control form-control-lg form-control-solid"
        >
          <div v-html="value" style="min-width: 150px"></div>
          {{ number }}
        </div>
        <div v-else class="form-control form-control-lg form-control-solid">
          {{ bool == true ? trueTitle : falseTitle }}
        </div>
      </div>
      <span v-if="info" class="form-text text-muted"> {{ info }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    info: String,
    icon: String,
    value: [String, Number],
    number: Number,
    bool: Boolean,
    trueTitle: String,
    falseTitle: String,
  },
};
</script>
