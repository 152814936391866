import Filter from "./models/filter";
import FormModule from "@/core/base/FormModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module({ namespaced: true })
export default class Reports extends FormModule {
  override endPoint = "Reports";
  override filter = new Filter();
  reportFileds = null;

  get GetReportFileds() {
    return this.reportFileds;
  }
  @Action
  override async AddOrEdit(model) {
    const { context, endPoint } = this;
    let resValue = null;
    await context.dispatch("Post", {
      url: endPoint + "/AddOrEdit",
      form: model,
      func: (res) => {
        resValue = res;
      },
    });
    return resValue;
  }

  @Mutation
  SetReportItems(model) {
    this.reportFileds = model.availableFields;
  }

  @Action
  async AddReportFromTemplate(id: number) {
    const { context, endPoint } = this;
    await context.dispatch("Post", {
      url: endPoint + "/AddReportFromTemplate?Id=" + id,
      func: (res) => {
        context.dispatch("Index");
      },
    });
  }
  @Action
  GetNewReports(id) {
    const { context, endPoint } = this;
    return context.dispatch("Get", {
      url: endPoint + "/GetNewReports?" + `ScreenId=${id}`,
      commitName: "SetReportItems",
    });
  }
}
