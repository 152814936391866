import { RouteRecordRaw } from "vue-router";
const RevenuesAndExpenses: RouteRecordRaw = {
  path: "revenuesAndExpenses",
  component: () => import("@/views/accounts/index.vue"),
  meta: {
    title: "IncomeAndExpenses",
  },
  children: [
    {
      path: "",
      name: "revenuesAndExpenses",
      component: () =>
        import("@/views/accounts/revenuesAndExpenses/pages/index.vue"),
      meta: {
        pageId: 17010400,
        role: "view",
        title: "IncomeAndExpenses",
      },
    },
  ],
};

export default RevenuesAndExpenses;
