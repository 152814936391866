export default class BaseFilter {
  //pageSize
  ps = 24;
  // SortOrderById
  s = 0;
  //pageNumber
  p = 1;

  //InActive
  iac = false;
  //IsDeleted
  isd = false;
}
