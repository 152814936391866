import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_switch = _resolveComponent("n-switch")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return ($props.ShowLabel)
    ? (_openBlock(), _createBlock(_component_n_form_item, {
        key: 0,
        label: $props.label,
        class: _normalizeClass('col-md-' + $props.col + '  mb-' + $props.mb),
        path: $props.path,
        "show-label": $props.ShowLabel,
        "label-placement": "top",
        "require-mark-placement": "right-hanging"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_switch, {
            "rail-style": $setup.railStyle,
            "on-update:value": "onUpdate",
            round: false,
            value: $setup.MyValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
            disabled: $props.disabled,
            size: _ctx.formSetting.formSize,
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $props.value)))
          }, null, 8, ["rail-style", "value", "disabled", "size"])
        ]),
        _: 1
      }, 8, ["label", "class", "path", "show-label"]))
    : (_openBlock(), _createBlock(_component_n_switch, {
        key: 1,
        "rail-style": $setup.railStyle,
        "on-update:value": "onUpdate",
        round: false,
        value: $setup.MyValue,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.MyValue) = $event)),
        disabled: $props.disabled,
        size: _ctx.formSetting.formSize,
        onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('change', $props.value)))
      }, {
        checked: _withCtx(() => [
          _createTextVNode(" غير مفعل ")
        ]),
        unchecked: _withCtx(() => [
          _createTextVNode(" مفعل")
        ]),
        _: 1
      }, 8, ["rail-style", "value", "disabled", "size"]))
}