<template>
  <div class="navi-item mb-2">
    <router-link :to="Cul(To)" class="navi-link py-4">
      <span class="navi-icon mr-2">
        <span class="svg-icon">
          <inline-svg :src="Icon"></inline-svg>
        </span>
      </span>
      <span class="navi-text font-size-lg">{{ Title }} </span>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    To: null,
    Icon: null,
    Title: null,
  },
};
</script>
