<template>
  <div>
    <a-fromRowLable
      v-if="value == 1"
      :title="title"
      value="مسلم"
    ></a-fromRowLable>
    <a-fromRowLable v-else :title="title" value="مسيحي"></a-fromRowLable>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    value: String,
  },
};
</script>
