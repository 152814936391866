import { RouteRecordRaw } from "vue-router";

import Auth from "@/views/auth/router";

import AccountsManagement from "@/views/accounts/router";

import Public from "@/views/public/router";
import Customer from "@/views/customers/router";
import Employee from "@/views/employees/router";
import Companies from "@/views/companies/router";
import Branches from "@/views/branches/router";
import Reports from "@/views/reports/router";

const HR: RouteRecordRaw = {
  path: "",
  component: () => import("@/views/shared/index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/layout/Layout.vue"),
      children: [
        {
          path: "",
          alias: "dashboard",
          name: "dashboard",
          meta: {
            title: "DASHBOARD",
          },
          component: () => import("@/views/Dashboard.vue"),
        },
        AccountsManagement,
        Public,
        Reports,
        Customer,
        Employee,
        Companies,
        Branches,
      ],
    },
    Auth,
    {
      path: "404",
      name: "404",
      component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
  ],
};

export default HR;
