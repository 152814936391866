
import { computed } from "vue";
export default {
  name: "wInput",
  emit: ["onUpdate"],
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "",
    },
    value: Boolean,
    label: {
      type: String,
      required: false,
      default: null,
    },
    placement: {
      type: String,
      required: false,
      default: "top",
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const onUpdate = () => {
      emit("onUpdate");
    };
    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });
    return { onUpdate, MyValue };
  },
};
