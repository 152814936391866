<template>
  <div-item :label="label" po :class="'col-' + col">
    <el-date-picker
      v-model="modelValue"
      type="daterange"
      unlink-panels
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      format="YYYY/MM/DD"
      ::size="formSetting.formSize"
      value-format="YYYY/MM/DD"
    />
  </div-item>
</template>
<script>
export default {
  props: {
    from: null,
    to: null,
    label: {
      default: "التاريخ",
      type: String,
    },
    placeholder: String,
    col: {
      type: [String, Number],
      required: false,
      default: "12",
    },
  },
  computed: {
    modelValue: {
      get: function () {
        let end = this.to;
        if (!end) {
          end = new Date();
        }
        let start = this.from;
        if (!start) {
          start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        }
        return [start, end];
      },
      set: function (value) {
        this.$emit("update:from", value[0]);
        this.$emit("update:to", value[1]);
      },
    },
  },
  methods: {
    // OnChange: function (value) {
    //   console.log("modelValue update ", value);
    //   this.$emit("update:modelValue", value);
    // },
  },
};
</script>
