
import { defineComponent, computed, ref } from "vue";
import paginate from "@/core/helpers/paginate";

export default defineComponent({
  name: "PageHeader",
  props: {
    Pager: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    printModel: {
      type: Array,
      required: false,
    },
    btnAddLable: {
      type: String,
      required: false,
    },
    disablePager: {
      type: Boolean,
      required: false,
    },
    ModalNew: {
      type: Boolean,
      required: false,
    },
    btnAddTo: {
      type: String,
      required: false,
    },
  },
  emits: ["PageTo", "ChangePageSize", "OnOpenModal"],
  setup(props, { emit }) {
    const PageTo = (value) => emit("PageTo", value);
    const SetPageSize = (value) => emit("ChangePageSize", value);
    const OpenModal = () => {
      emit("OnOpenModal", true);
    };

    const paginateModel = computed(() => {
      return paginate(
        props.Pager.totalCount,
        props.Pager.pageNumber,
        props.Pager.pageSize,
        3
      );
    });

    const options = [
      {
        value: "12",
        label: "12",
      },
      {
        value: "24",
        label: "24",
      },
      {
        value: "50",
        label: "50",
      },
      {
        value: "100",
        label: "100",
      },
      {
        value: "150",
        label: "150",
      },
      {
        value: "200",
        label: "200",
      },
    ];
    return { PageTo, SetPageSize, OpenModal, paginateModel, options };
  },
});
