<template>
  <router-link
    :to="Cul(to)"
    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
  >
    <span class="svg-icon svg-icon-3">
      <inline-svg src="/media/icons/duotune/general/gen019.svg" />
    </span>
  </router-link>
</template>
<script>
export default {
  props: {
    to: String,
  },
};
</script>
