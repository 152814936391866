
export default {
  name: "AccountViewName",
  inheritAttrs: false,
  props: {
    fullName: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
  },
};
