import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_button = _resolveComponent("w-button")!

  return (_openBlock(), _createBlock(_component_w_button, {
    icon: "fa-solid fa-layer-plus",
    text: $props.text,
    to: $props.to
  }, null, 8, ["text", "to"]))
}