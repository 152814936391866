import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "InputSuggest" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_list_item = _resolveComponent("n-list-item")!
  const _component_n_list = _resolveComponent("n-list")!
  const _component_n_scrollbar = _resolveComponent("n-scrollbar")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('InputSuggestArea col-md-' + $props.col + '  mb-' + $props.mb)
  }, [
    _createVNode(_component_n_form_item, {
      rule: {
        required: $props.required,
        message: $props.message,
        trigger: ['input', 'blur'],
      },
      label: $props.label,
      path: $props.path,
      "show-label": $props.ShowLabel,
      "require-mark-placement": "right-hanging",
      "show-require-mark": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_n_input, {
          clearable: "",
          value: $setup.MyValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.MyValue) = $event)),
          size: "medium",
          disabled: $props.disabled,
          placeholder: $props.placeholder,
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
          ref: $props.path,
          "on-focus": $setup.Onfocus,
          "on-blur": $setup.Onblur
        }, null, 8, ["value", "disabled", "placeholder", "on-focus", "on-blur"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_n_scrollbar, { style: {"max-height":"220px"} }, {
            default: _withCtx(() => [
              _createElementVNode("h1", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.SelectItem && $setup.SelectItem(...args)))
              }, "sdfdsf"),
              _withDirectives(_createVNode(_component_n_list, {
                hoverable: "",
                clickable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i, index) => {
                    return _createVNode(_component_n_list_item, { key: index }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          onClick: ($event: any) => ($setup.SelectItem(i))
                        }, " Lorem ipsum dolor sit amet, " + _toDisplayString(i), 9, _hoisted_2)
                      ]),
                      _: 2
                    }, 1024)
                  }), 64))
                ]),
                _: 1
              }, 512), [
                [_vShow, $setup.show]
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["rule", "label", "path", "show-label"])
  ], 2))
}