
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    model: Object,
    foreColor: {
      type: String,
      default: "black",
    },
    backColor: {
      type: String,
      default: "#eae2b7",
    },
    Name: String,
    ShortName: String,
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    small: {
      type: Boolean,
    },
  },
};
