
import { PropType, ref, computed } from "vue";
export default {
  name: "wInput",
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      default: "",
    },
    value: [String, Array, Number] as PropType<
      null | string | [string, string]
    >,
    rule: [Object, Array],
    Options: {
      type: [Object, Array],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    ShowLabel: {
      type: Boolean,
      required: false,
      default: (props) => props.label != null,
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => "اكتب هنا " + props.label,
    },
    message: {
      type: String,
      required: false,
      default: (props) => "مطلوب",
    },
    filter: {
      type: Boolean,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: (props) => (props.filter ? "3" : "12"),
    },
    mb: {
      type: [String, Number],
      required: false,
      default: "3",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    let listOptions = ref(props.Options);

    const MyValue = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit("update:value", newValue);
      },
    });
    console.log("listOptions", listOptions.value);
    let options = computed(() =>
      listOptions.value?.filter((i) => i.label.includes(MyValue.value))
    );

    const onSelect = (value) =>
      emit("selected", listOptions.value.filter((i) => i.value == value)[0]);

    return {
      MyValue,
      options,
      onSelect,
    };
  },
};
