import { RouteRecordRaw } from "vue-router";

const customers: RouteRecordRaw = {
  path: "customers",
  component: () => import("@/views/reports/index.vue"),
  meta: {
    title: "تقرير العملاء",
  },
  children: [
    {
      path: "",
      alias: "index",
      name: "index-reports-customers",
      component: () => import("@/views/reports/customers/pages/index.vue"),
      meta: {
        pageId: 18010400,
        role: "view",
        title: "تقرير العملاء",
      },
    },
  ],
};

export default customers;
