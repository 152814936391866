import { RouteRecordRaw } from "vue-router";
import AccountStatement from "@/views/accounts/accountStatement/router";
import AccountsTree from "@/views/accounts/tree/router";
import AssetCategory from "@/views/accounts/assets/assetCategory/router";
import AssetCategoryView from "@/views/accounts/assets/assets/router";
import AssetsBalance from "@/views/accounts/assets/assetsBalance/router";
import BalanceSheet from "@/views/accounts/balanceSheet/router";
import Budget from "@/views/accounts/budget/router";
import ClosingTheFiscalYear from "@/views/accounts/closingthefiscalyear/router";
import DebentureAssets from "@/views/accounts/debentureAssets/router";
import Debentures from "@/views/accounts/debentures/router";
import FinancialcenterList from "@/views/accounts/financialCenterList/router";
import IncomeList from "@/views/accounts/incomeList/router";
import RevenuesAndExpenses from "@/views/accounts/revenuesAndExpenses/router";
import TrialBalance from "@/views/accounts/trialBalance/router";
import Transaction from "@/views/accounts/transactions/router";

//AccountsManagement - إدارة الحسابات
const AccountsManagement: RouteRecordRaw = {
  path: "a",
  component: () => import("@/views/accounts/index.vue"),
  children: [
    AccountStatement,
    AccountsTree,
    AssetCategory,
    AssetCategoryView,
    AssetsBalance,
    BalanceSheet,
    Budget,
    ClosingTheFiscalYear,
    DebentureAssets,
    Debentures,
    FinancialcenterList,
    IncomeList,
    RevenuesAndExpenses,
    TrialBalance,
    Transaction,
  ],
  meta: {
    title: "Accounts",
    module: "a",
    svgIcon: "/media/icons/duotune/finance/fin005.svg",
  },
};
export default AccountsManagement;
