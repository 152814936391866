<template>
  <div
    class="card card-custom card-sticky mb-5"
    style="position: relative"
    sticky-offset="{top:115, bottom: 30}"
    sticky-side="both"
    on-stick="onStick"
    sticky-z-index="20"
  >
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          {{ Title }}
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link
          :to="Cul(BackTo)"
          v-if="BackTo"
          class="btn btn-light-primary font-weight-bolder m-2"
        >
          <i class="fad fa-arrow-alt-left mr-4"></i>{{ Back }}
        </router-link>
        <!-- <el-button @click="onSubmit" class="btn btn-primary ml-4" v-if="!hide">
          <i class="fad fa-save mr-4"></i> {{ SaveForm }}
        </el-button> -->
        <el-button type="primary" native-type="submit">{{
          SaveForm
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    SaveForm: {
      type: String,
      default: () => "حفظ",
    },
    Title: {
      type: String,
      default: () => "العنوان",
    },
    Back: {
      type: String,
      default: () => "رجوع",
    },
    hide: {
      type: Boolean,
      default: () => false,
    },
    BackTo: {
      type: String,
    },
  },
  // methods: {
  //   onSubmit() {
  //     this.$emit("onSubmit");
  //   },
  // },
};
</script>
