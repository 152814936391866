
export default {
  name: "EmptyRow",
  props: {
    model: {
      type: Array,
      required: true,
    },
    colspan: {
      type: [String, Number],
      required: false,
      default: "1",
    },
  },
};
