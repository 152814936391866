import { RouteRecordRaw } from "vue-router";

const Auth: RouteRecordRaw = {
  path: "",
  component: () => import("@/views/auth/Layout.vue"),
  children: [
    {
      path: "",
      name: "SignInLayout",
      component: () => import("@/views/auth/SignInLayout.vue"),
      children: [
        {
          path: "signin",
          name: "signin",
          component: () => import("@/views/auth/pages/SignIn.vue"),
        },
        {
          path: "signup",
          name: "signup",
          component: () => import("@/views/auth/pages/signup.vue"),
        },
        {
          path: "sign-up",
          name: "sign-up",
          component: () => import("@/views/auth/pages/sign-up.vue"),
        },
        {
          path: "password-reset",
          name: "password-reset",
          component: () => import("@/views/auth/pages/password-reset.vue"),
        },
        {
          path: "CheckRegister",
          name: "CheckRegister",
          component: () => import("@/views/auth/pages/SignIn.vue"),
        },
      ],
    },
    {
      path: "",
      name: "SubscriptionLayout",
      component: () => import("@/views/auth/SubscriptionLayout.vue"),
      children: [
        {
          path: "subscribe",
          name: "subscriptionss",
          component: () => import("@/views/auth/pages/subscribe.vue"),
        },
      ],
    },

    // {
    //   path: "/addSchool",
    //   name: "RequestAddSchool",
    //   component: () => import("@/views/auth/pages/addSchool.vue"),
    // },
  ],
};
export default Auth;
