import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import FormModule from "@/core/base/FormModule";

@Module({ namespaced: true })
export default class AccountsTree extends FormModule {
  override endPoint = "AccountsTree";

  @Action
  TreeRepair() {
    const { context, endPoint } = this;
    return context.dispatch("Post", {
      url: endPoint + "/TreeRepair",
      commitName: "SetModel",
    });
  }
}
