
import { ref } from "vue";
export default {
  name: "ContentCard",
  inheritAttrs: false,

  props: {
    mode: String,
    description: String,
    label: {
      type: String,
      required: false,
      default: "",
    },
    info: {
      type: String,
      required: false,
      default: "",
    },
    svg: {
      type: String,
      required: false,
      default: "/media/icons/duotune/abstract/abs045.svg",
    },
    icon: {
      type: String,
      required: false,
    },
    col: {
      type: [String, Number],
      required: false,
      default: "12",
    },
  },
  setup() {
    let OpenBlock = ref(true);

    return { OpenBlock };
  },
};
