<template>
  <div :class="['card  wave   mb-10 row', 'card-' + color, 'wave-' + color]">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="mr-6">
          <span :class="['svg-icon svg-icon-4x', 'svg-icon-' + iconColor]">
            <inline-svg
              class="menu-icon"
              :src="`/media/svg/${svg}`"
            ></inline-svg>
          </span>
        </div>
        <div class="d-contents">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isDeleted: Boolean,
    color: {
      type: String,
      default: "primary",
    },
    iconColor: {
      type: String,
      default: "success",
    },
    svg: null,
  },
};
</script>
