<template>
  <slot></slot>
  <h1>PageLoading</h1>
  <q-inner-loading
    :showing="visible"
    label="Please wait..."
    label-class="text-teal"
    label-style="font-size: 1.1em"
  />
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageLoading",
  props: {
    visible: Boolean,
  },
});
</script>
