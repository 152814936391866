
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import LanguageService from "@/core/services/language";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const i18n = useI18n();
    i18n.locale.value = LanguageService.get();

    let Direction = LanguageService.get() == "en" ? "ltr" : "rtl";

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      LanguageService.SetDirection();
      // store.dispatch("LookUps/ChangeLanguage", LanguageService.get());
      nextTick(() => {
        initializeComponents();
      });
    });
    return {
      Direction,
    };
  },
});
