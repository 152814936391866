<template>
  <div>
    <q-select
      filled
      v-model="modelInput"
      :label="label"
      :options="Myoptions"
      @filter="filterFn"
      :option-value="optionValue"
      :option-label="optionlabel"
      options-selected-class="text-deep-orange"
    >
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          <q-item-section>
            <q-item-label v-if="scope.opt.value" v-html="scope.opt.value" />
            <q-item-label v-if="scope.opt.name" v-html="scope.opt.value" />

            <q-item-label v-if="scope.opt.value" caption>{{
              scope.opt.value
            }}</q-item-label>
            <q-item-label v-if="scope.opt.name" caption>{{
              scope.opt.name
            }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>
<script>
export default {
  created() {
    this.Myoptions = this.options;
  },
  data() {
    return {
      Myoptions: null,
    };
  },
  methods: {
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.Myoptions = this.options;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.Myoptions = this.options.filter(
          (v) => v.value.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
  computed: {
    modelInput: {
      get: function () {
        return this.options.filter((v) => v[this.optionValue] == this.value)[0];
      },
      set: function (v) {
        this.$emit("input", v ? v[this.optionValue] : 0);
      },
    },
  },
  props: {
    value: Number,
    options: null,
    label: null,
    optionValue: {
      default: "id",
      type: String,
    },
    optionlabel: {
      default: "value",
      type: String,
    },
  },
};
</script>
